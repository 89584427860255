import Select from "react-select";
import React from "react";


export const ContractType = ({
                                 contactTypeData,
                                 selectedContractType,
                                 handleChangeContractType
                             })=>{

    return(
        <div className="resumeupdatealjob Ajlefthead work-authorizaljob">
            <h2>Contract Type</h2>

            <Select
                id="Contact_Type"
                name="term-contract"
                options={contactTypeData}
                value={selectedContractType}
                onChange={handleChangeContractType}
                placeholder="Contract Type"
                isSearchable
                required
                isClearable={true}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        padding: '4px 5px',
                        border: '1px solid rgba(46, 46, 46, 0.5)',
                        boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                        borderRadius: '8px'
                    }),
                }}
            />
        </div>
    )
}