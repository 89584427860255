import React, {useState} from "react";
import './assets/css/header.css';
import '../src/assets/css/login.css';
import styles from '../src/assets/css/LoadingComponent.module.css';

import logoImage from '../src/assets/logo4.svg';
import emailImage from '../src/assets/emailplaceholder.svg';
import passwordImage from '../src/assets/passwordplaceholder.svg';
import watchPasswordImage from '../src/assets/watchpassword.svg';
import backgroundImage from '../src/assets/landingimages.jpg'; // Import your background image

import {useNavigate} from 'react-router-dom';
import axios from "axios";
import BASE_URL from "./config";
import { BsArrowRight } from 'react-icons/bs';
import { useDispatch } from "react-redux";
import {addUser} from "./store/slices/UserSlice";
import {useMediaQuery} from "react-responsive";
import {SignInHeader} from "./components/Header/SignInHeader";
import {IoEye, IoEyeOffOutline, IoEyeOutline} from "react-icons/io5";
import {ErrorHandlingComponent} from "./ErrorHandlingComponent";

function Login() {
    const invaidCredDivStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

    };
    const navigate = useNavigate();
    const dispatch=useDispatch();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })


    const signupClick = () => {
        navigate('/Request_For_Subscription');
    };
    const dashboard = () => {
        navigate('/employer_dashboard');
    }
    const forgotPasswordClick = () => {
        navigate('/forgot_password');
    };

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [error, setError] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fetchError, setFetchError] = useState(false);
    const [fetchErrorMessage, setFetchErrorMessage] = useState(false);

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const passwordVisible = () => {
        console.log(isPasswordVisible)
        setIsPasswordVisible(!isPasswordVisible);
    }
    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            handleSignIn();
        }
    };
    const handleSignIn = (e) => {
        if (e) {
            e.preventDefault(); // check if event exists before calling preventDefault
        }
        // e.preventDefault();
        setLoading(true);
        setIsPasswordVisible(false);
        setError([]);
        const payload = {
            email: email,
            password: password,
            userable_type: 'App\\Models\\Employer'
        };

        axios.post(`${BASE_URL}/login`, payload)
            .then(async response => {
                setLoading(false);
                if (response.data.status == 200) {
                    localStorage.clear();
                    const employerId = response.data.user.userable_id;
                    const userable_type = response.data.user.userable_type;
                    const userId = response.data.user.id;
                    localStorage.setItem('employerId', employerId);
                    localStorage.setItem('userId', userId)
                    localStorage.setItem('userable_type', userable_type);
                    localStorage.setItem('access_token', response.data.access_token)
                    if(rememberMe){
                    const expiryDate = new Date();
                    expiryDate.setDate(expiryDate.getDate() + 30); // Expires in 30 days
                    localStorage.setItem('expiry_date', expiryDate.toISOString());
                    }
                    else{
                        const expiryDate = new Date();
                        expiryDate.setDate(expiryDate.getDate() + 1); // Expires in 1 day
                        localStorage.setItem('expiry_date', expiryDate.toISOString());
                    }
                    console.log(response.data.user, 'user')
                    // dispatch(addUser(response.data.user));
                    await dispatch(addUser(response.data.user)); // Wait for user data to be added

                    navigate("/employer_dashboard");
                } else {
                    // alert(response.data.msg)
                    setError(response.data);

                }
                // Do something with the response
            })
            .catch(error => {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
                setError(error.response.data);
            });
    }
    const handleLogoClick = () => {
        navigate("/");

    }
    const [rememberMe, setRememberMe] = useState(false);

    const handleCheckboxChange = () => {
        setRememberMe(!rememberMe);
    };
    return (

        <div>
            <div className="nav bg-white">
                <div className="container-fluid">
                    <div className="row navpad">
                        <div className="d-flex justify-content-between">
                            <div className="col-lg-4 logosearch">
                                <a href="javascript:void(0)" >
                                    <img onClick={handleLogoClick} src={logoImage} alt="Logo"/>
                                </a>
                            </div>
                            <div className="col-lg-8 d-flex justify-content-end align-items-center">
                                {isTabletOrMobile?(<SignInHeader text="SIGN UP" handlerClick={signupClick} />)
                                    :(
                                        <div className="postbtn">
                                            <button type="submit" onClick={signupClick}>
                                                SIGN UP
                                            </button>
                                        </div>
                                    )}

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mainbg">
                <div className="form">
                    <div className="formdata">
                        <h2>Employer Sign In</h2>
                        <p>Please provide your login information</p>


                        <div className="emailinfo">
                            <img src={emailImage} alt=""/>
                            <input type="text" placeholder="Email" value={email}
                                   onChange={e => setEmail(e.target.value)} onKeyPress={handleKeyPress}/>
                        </div>
                        {error.email && <span className="text-danger">{error.email}</span>}
                        <div className="emailinfo passwordinfo">
                            <img src={passwordImage} alt=""/>
                            <input type={isPasswordVisible ? "text" : "password"} placeholder="Password"
                                   value={password} onChange={e => setPassword(e.target.value)} onKeyPress={handleKeyPress}/>

                            {/*<img src={watchPasswordImage} alt="" onClick={passwordVisible}/>*/}
                            <div className="eye-icon-container" onClick={passwordVisible}>
                                {isPasswordVisible ? (
                                    <IoEyeOutline className="eye-icon" size={24} />
                                ) : (
                                    <IoEyeOffOutline className="eye-icon" size={24} />
                                )}
                            </div>

                        </div>
                        {error.password && <span className="text-danger">{error.password}</span>}
                        <div className="formforget">
                            <div>
                                <input
                                    type="checkbox"
                                    id="remember"
                                    name="remember"
                                    checked={rememberMe}
                                    onChange={handleCheckboxChange}
                                />
                                <label className="checkbox-label">Remember me on this device for 30 days</label>
                                <br />
                            </div>

                            <div>
                                <br/>
                                <p >Having trouble in singing in, please click on Forgot password</p>

                            </div>
                            <p>
                                <a href="javascript:void(0)"  onClick={forgotPasswordClick}> Forgot Password?</a>
                            </p>
                        </div>
                        <div style={invaidCredDivStyle}>
                            <span className="text-danger">{error.msg}</span>
                        </div>
                        <div className="formbtn">

                            <button onClick={handleSignIn}>Login
                                {loading && (
                                    <div className={styles.loadingOverlay}>
                                        <div className={styles.loadingSpinner}></div>
                                    </div>
                                )}
                            </button>

                            <p>
                                Do not have an account? Please<span>
                      <a href="javascript:void(0)"  onClick={signupClick}> Sign Up </a>{" "}
                    </span>{" "}
                                here!
                            </p>
                            <p><span><a href="javascript:void(0)"  onClick={()=>navigate('/jobseeker_login')} style={{ cursor: "pointer" }}> Job Seeker Login <BsArrowRight/></a>{" "}</span></p>
                        </div>
                    </div>
                </div>
            </div>
            {fetchError &&  <ErrorHandlingComponent error={fetchErrorMessage} />}

        </div>

    );
}

export default Login;
