import React, {useEffect, useRef, useState} from "react";
import Helper from "../../Helper";
import {BsThreeDots} from "react-icons/bs";
import {Button, Modal} from "react-bootstrap";

export const JobAd = ({
                          ad,
                          handlePreviewJob,
                          handleEditJobClick,
                          handleDeleteJobClick,
                          handleConfirmDeleteJob,
                          isDeleteModalOpen,
                          handleCloseModal,
                          setOpenDropdownIndex})=> {
    const [showDropdown, setShowDropdown] = useState(false);
    const containerRef = useRef(null);
    const employerId = localStorage.getItem("employerId");

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener("click", handleDocumentClick);

        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, []);
    const handleOptionClick = () => {
        setShowDropdown((prevShowDropdown) => !prevShowDropdown);
        setOpenDropdownIndex(false)
    };

    const handleCompanyImg = (job)=>{

        let user = job?.employer?.users.filter(user=> user.userable_id === parseInt(employerId))
        let img = Helper.profile_log(user[0])
        return img;
    }


    return (
        <div className="activejobv1">

            {handleCompanyImg(ad)}
            <div className="ed-right">
                <div className="activejobtxtv1" onClick={() => handlePreviewJob(ad.id)}>
                    <h6>{ad?.job_title}</h6>
                    <p>{ad?.location}</p>

                    {/* Rest of your code */}
                </div>
                <div ref={containerRef} className="js_container_div">

                    <div className="dropdown">

                        <BsThreeDots className="basic_text" size={24} style={{  cursor: 'pointer' }}  onClick={handleOptionClick}></BsThreeDots>

                        <div
                            className={`dropdown-menu ${showDropdown ? "show" : ""}`}
                            // style={{ minWidth: "120px" , left:"-70px" }} // Adjust the width as needed
                            style={{ minWidth: "120px",    position: "absolute", right: "-10px" }} // Adjust the width as needed

                        >
                            <li
                                className="dropdown-item dropdown-link"
                                onClick={() => handleEditJobClick(ad.id)}
                            >
                                Edit
                            </li>
                            <li
                                className="dropdown-item dropdown-link"
                                onClick={() => handleDeleteJobClick(ad.id)}
                            >
                                Delete
                            </li>
                        </div>
                    </div>

                </div>
            </div>
            <Modal show={isDeleteModalOpen} onHide={()=>handleCloseModal()} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Job</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure to delete this job!</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>handleCloseModal()}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={() =>handleConfirmDeleteJob()}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
