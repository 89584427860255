import React, { useEffect, useRef, useState } from 'react';

function HighlightedIframe({ src, wordsToHighlight }) {
    const iframeRef = useRef(null);
    const [iframeLoaded, setIframeLoaded] = useState(false);

    useEffect(() => {
        const handleIframeLoad = () => {
            const iframeDocument = iframeRef.current.contentDocument;

            if (iframeDocument) {
                const highlightClass = 'highlighted-text';

                // Create a style element for highlighting
                const style = iframeDocument.createElement('style');
                style.type = 'text/css';
                style.innerHTML = `.${highlightClass} { background-color: yellow; }`;
                iframeDocument.head.appendChild(style);


                const highlightWords = () => {
                    wordsToHighlight.forEach((searchTerm) => {
                        const regex = new RegExp(`\\b${searchTerm}\\b`, 'gi');
                        const walker = iframeDocument.createTreeWalker(
                            iframeDocument.body,
                            NodeFilter.SHOW_TEXT
                        );
                        console.log(walker)
                        console.log('searchTerm:', searchTerm);

                        let node;
                        try {
                            while ((node = walker.nextNode())) {
                                const text = node.nodeValue;
                                console.log('text:', text);

                                const matches = text.match(regex);
                                console.log('matches:', matches);

                                if (matches) {
                                    const highlightedText = text.replace(
                                        regex,
                                        (match) =>
                                            `<span class="${highlightClass}">${match}</span>`
                                    );

                                    if (highlightedText !== text) {
                                        const span = iframeDocument.createElement('span');
                                        span.innerHTML = highlightedText;
                                        node.parentNode.replaceChild(span, node.parentNode);
                                    }
                                }
                            }
                        } catch (error) {
                            console.error('Error in while loop:', error);
                        }
                    });

                    setIframeLoaded(true);
                };

// ...

                // Call the highlighting function after the iframe content has loaded
                highlightWords();
            } else {
                console.error('iframeDocument is not available');
            }
        };

        if (iframeRef.current && !iframeLoaded) {
            iframeRef.current.addEventListener('load', handleIframeLoad);
        }

        return () => {
            if (iframeRef.current) {
                iframeRef.current.removeEventListener('load', handleIframeLoad);
            }
        };
    }, [wordsToHighlight, iframeLoaded]);

    return <iframe ref={iframeRef} src={src} width="100%" height="600px" title="Embedded Document" />;
}

export default HighlightedIframe;
