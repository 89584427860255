import React from "react";

export const ResumeAnalytics = ({
                employer
            })=>{

    return(
        <div className=" analyticsv1">
            <div className="resumev1">
                <h2>Resumes</h2>
                {/* <p><a href="javascript:void(0)""javascript:void(0)""">View All</a></p> */}
            </div>
            <div className="dashjobiconv1 ">
                <p> No of Views Purchased</p>
                <p>  {employer?.employer_data?.subscription?.package?.views} </p>
            </div>

            <div className="dashjobiconv1 ">
                <p>Remaining Views</p>
                <p> {employer?.employer_data?.subscription?.remaining_views} </p>
            </div>

            <div className="dashjobiconv1 ">
                <p>Favorite Resumes </p>
                <p> {employer?.employer_data?.favoriteResumesCount} </p>
            </div>

        </div>
    )
}