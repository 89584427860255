import React from 'react';
import PageHeader from "./PageHeader";
import Footer from "./Footer";
import '../src/assets/css/staticpages.css'; // Import the CSS file
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import colors from "./colors";
import { MdDescription } from "react-icons/md";
import JobSeekerHeader from "./JobSeekerHeader";
import EmployerHeader from "./EmployerHeader";
import logoImage from "./assets/logo4.svg";
import {ScreenLink, TabScreen} from "./components/LandingPageHeader/LandingPageLink";

const TermsAndConditions = () => {
    const navigate = useNavigate();
    const userableType = localStorage.getItem('userable_type');

    return (
        <>

            {userableType === 'App\\Models\\JobSeeker' ? (
                <JobSeekerHeader />
            ) : userableType === 'App\\Models\\Employer' ? (
                <EmployerHeader />
            ) : (
                // Add default header or any other logic based on your requirements
                <PageHeader />
            )}
            <div className="sp-about-us-content">
                <div className="sp-background-style">
                    <div className="AJbackbtn">
                        <button onClick={() => navigate('/')}><BsArrowLeft /> Back</button>
                    </div>
                    <h1>
                        <MdDescription  className="mb-1" style={{ color: colors.primaryColor }} />  Terms of Use (“User Agreement”)
                    </h1>
                    <div className="content-container">
                        <div className="sp-text-content">
                            <p>

                                <strong>Important</strong><br />
                                These terms and conditions ("Terms") apply to all users of this website and its associated services (the "Site"). By
                                using the Site you will be deemed to agree to these Terms without qualification. If you do not agree to be bound
                                by these Terms, please do not use or continue to use the Site.
                            </p>

                            <p>
                                <strong>Headings</strong><br />
                                The headings used in this User Agreement are included for convenience only and will not limit or otherwise affect
                                the terms and conditions of this User Agreement.
                            </p>

                            <p>
                                <strong>Access</strong><br />
                                You understand that Rezmaze may for security and technical reasons at any time in its sole discretion and without
                                prior notice to you, prohibit or restrict access to the Site or any part thereof.
                            </p>

                            <p>
                                <strong>No Advice</strong><br />
                                You agree that Rezmaze provides no advice or endorsement of any kind and nor do Rezmaze give any advice or
                                offer any opinion with regard to the value or suitability of any particular transaction or course of action. The
                                availability of information through the Site does not constitute a recommendation by Rezmaze to enter into any
                                transaction or follow any course of action. Any decision that you make must be based solely on your own
                                evaluation of your circumstances and objectives.
                            </p>

                            <p>
                                <strong>Information and warranties</strong><br />
                                Rezmaze and its information contributors and associated service providers make no representations or warranties
                                whatsoever, including without limitation, as to the availability, accuracy, completeness, currency or reliability or
                                suitability for any purpose of the information, related graphics and/or services made available via the Site and
                                expressly disclaim (to the maximum extent permitted in law) all liability for any damage or loss resulting from your
                                use of, or reliance on the Site or the information provided via the Site.
                            </p>

                            <p>
                                The information in this website may contain technical inaccuracies or typographical errors. Rezmaze recommends
                                that you independently verify the accuracy, currency or reliability of any information made available via the Service
                                and upon which you intend to rely.
                            </p>

                            <p>
                                The site services and information are provided on an "as is" basis. Rezmaze make no warranty that the Site will be
                                free from viruses or any kind of malicious code whatsoever. Neither Rezmaze nor any of its directors, employees
                                or other representatives shall be liable for any loss or damage which may arise from the use of any information
                                contained on this website.
                            </p>

                            <p>
                                <strong>Intellectual Property</strong><br />
                                You acknowledge that Rezmaze is the proprietor or the authorized user of the copyright, trade marks and all other
                                intellectual property rights with all rights reserved, unless otherwise indicated (IP Rights) in the software, text,
                                graphics and other material (the "Content") displayed or available through the Site. Unauthorized use of the
                                Content is strictly prohibited and may infringe the IP Rights in the Content. Subject to these Terms, Rezmaze
                                grants to you a non-exclusive and non-transferable single/multiple user(employer) license to use the Content for
                                your own private and non-commercial purposes. No content may be reproduced, published or transmitted in any
                                manner without the prior written consent of Rezmaze. In particular and without limitation, you may not without
                                prior written permission from Rezmaze:
                            </p>

                            <ul>
                                <li>archive the Content or any part of it;</li>
                                <li>form a database whether electronically or otherwise by systematically downloading or storing all or any of the Content;</li>
                                <li>redistribute or republish the Content; or</li>
                                <li>link to the Site, including (without limitation) linking in any way which frames the Site, replicates the Site, or
                                    misleads any person regarding any association with the Site or your association with Rezmaze, whether
                                    implicitly or expressly.</li>
                            </ul>

                            <p>
                                <strong>Linked sites</strong><br />
                                No link appearing in the Content and no linked site is endorsed by Rezmaze and no responsibility is taken for
                                material on any linked site. You agree that by accessing any linked site you are doing so at your own risk and you
                                agree to fully indemnify Rezmaze against any liability that Rezmaze may incur arising out of your accessing any
                                linked site.
                            </p>

                            <p>
                                <strong>Technical Difficulties</strong><br />
                                You acknowledge that your use of the Site and associated services may be subject to interruption, delay or
                                unavailability. Due to the nature of the Internet and mobile phone communications, Rezmaze and its service
                                providers do not make any warranty that the Site or services will be error free, without interruption or delay or
                                free from defects in design or engineering.
                            </p>

                            <p>
                                <strong>Liability</strong><br />
                                To the maximum extent permitted in law you specifically agree to indemnify and hold Rezmaze harmless from any
                                and all claims relating to your use of the Site on behalf of yourself or another person, or its use by any person on
                                your behalf. For the purposes of this paragraph Site means and includes any linked site.
                            </p>

                            <p>
                                Except as set out in the final sentence of this paragraph, Rezmaze will be under no liability to you whatsoever
                                whether in contract, tort, (including negligence), breach of statutory duty, restitution or otherwise for any injury,
                                death, damage or direct, indirect or consequential loss (all three of which terms include, without limitation, pure
                                economic loss, loss of profits, loss of business, depletion of goodwill and like loss) howsoever caused arising out of
                                or in connection with the use of this website or the use, accessing, downloading or relying on any information or
                                other materials contained in this website, including, without limitation, as a result of any computer virus. These
                                Terms do not exclude its liability (if any) to you for personal injury or death resulting from Rezmaze' negligence,
                                for fraud or for any matter which it would be illegal for Rezmaze to exclude or to attempt to exclude its liability.
                            </p>

                            <p>
                                <strong>Severability</strong><br />
                                The illegality, invalidity or unenforceability of any part of the Terms will not affect the legality, validity or
                                enforceability of the remainder.
                            </p>

                            <p>
                                <strong>Variations</strong><br />
                                Rezmaze may vary these Terms from time to time. By continuing to use the Site after a variation has been posted
                                on the Site or after you have been notified of a variation you will be deemed to agree to the varied Terms.
                            </p>

                            <p>
                                <strong>Termination</strong><br />
                                Rezmaze may immediately terminate this agreement without liability if it reasonably believes you are in breach of
                                these Terms or wishes to withdraw the Site or any part of it.
                            </p>

                            <p>
                                <strong>Governing Law</strong><br />
                                These Terms and your use of the Site will be governed by and construed in accordance with the laws of the State
                                of New Jersey, USA
                            </p>

                            <p>
                                <strong>ATTORNEYS FEES.</strong> If Rezmaze or its affiliates take any action to enforce this User
                                Agreement, such parties will be entitled to recover from you, and you agree to pay, all
                                reasonable and necessary attorneys fees and any cost of litigation, in addition to any
                                other relief, at law or in equity, to which such parties may be entitled.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default TermsAndConditions;
