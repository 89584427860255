import Helper from "../../Helper";
import React, {useState, useMemo} from "react";
import {FaUserEdit} from "react-icons/fa";
import {AddSubscription} from "../Modals/AddSubscription";
import axios from "axios";
import {BASE_URL} from "../../config";
import {useNavigate} from "react-router-dom";
import styles from "../../assets/css/LoadingComponent.module.css";
import tableStyle from "../../assets/css/tablestyle.module.css"
import {Search} from "../DataTable";
import {Pagination} from "../DataTable";
import {ToastContainer, toast} from 'react-toastify';
import {TbUserCancel} from "react-icons/tb";
import colors from "../../colors";
// import styles from "./assets/css/LoadingComponent.module.css";

export const RequestTable =({qouteList, fetchQoute, fetchSubscription})=>{

    const [showSubModal, setShowSubModal] = useState(false)
    const [loading, setLoading] = useState(false);
    const [totalItem, setTotalItem] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const ITEMS_PER_P = 10;

    const [formData, setFormData] = useState({
        full_name:'',
        company_name:'',
        company_address:'',
        email: '',
        phone_number:'',
        num_of_views: '',
        num_of_jobs:'',
        num_of_users:'',
        us_based: '',
        location:'',
        start_date:'',
        end_date:'',
        package:{
            name:'Default Package',
            description:'',
            price:0.0,
            feature:'',
            package_id:'',
            most_popular: false,
            active: true
        }
    })

    const [errors, setErrors] = useState({
        full_name:'',
        company_name:'',
        company_address:'',
        email: '',
        phone_number:'',
        num_of_views: '',
        num_of_jobs:'',
        num_of_users:'',
        start_date:'',
        end_date:'',
        package_name:'',
        package_description:'',
        package_price:'',
        package_feature:'',
    })
    const [qId, setQid] = useState(0)
    const navigate = useNavigate();
    const token = localStorage.getItem("access_token");
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const ShowSubscriptionModal = (data)=>{
         setQid(data?.id)
        setFormData((prevData) => ({
            ...prevData,
            full_name: data?.full_name,
            company_name: data?.company_name,
            company_address: data?.company_address,
            email: data?.email,
            phone_number: data?.phone_number,
            num_of_views: data?.num_of_views,
            num_of_jobs: data?.num_of_jobs,
            num_of_users: data?.num_of_users,
            us_based: data?.us_based,
            location: data?.location,
            package:{
                name:data?.package?.name,
                description:data?.package?.description,
                price:data?.package?.price,
                feature:data?.package?.features,
                package_id:data?.package?.id,
                most_popular: false,
                active: true
            }
        }));
        setShowSubModal(true)
    }

    const closeSubscriptionModal = ()=>{
        setShowSubModal(false)
    }

    const list = useMemo(() => {
        let qoutes = qouteList;

        if (search) {

            qoutes = qoutes.filter(
                (qoute) =>
                    String(qoute.full_name)
                        .toLowerCase()
                        .includes(String(search).toLowerCase()) ||
                    String(qoute.company_name)
                        .toLowerCase()
                        .includes(String(search).toLowerCase()) ||
                    String(qoute.email)
                        .toLowerCase()
                        .includes(String(search).toLowerCase()) ||
                    String(qoute.phone_number)
                        .toLowerCase()
                        .includes(String(search).toLowerCase()) ||
                    String(qoute.num_of_views)
                        .toLowerCase()
                        .includes(String(search).toLowerCase()) ||
                    String(qoute.num_of_users)
                        .toLowerCase()
                        .includes(String(search).toLowerCase()) ||
                    String(qoute.num_of_jobs)
                        .toLowerCase()
                        .includes(String(search).toLowerCase()) ||
                    String(qoute.us_based)
                        .toLowerCase()
                        .includes(String(search).toLowerCase()) ||
                    String(qoute.company_address)
                        .toLowerCase()
                        .includes(String(search).toLowerCase()) ||
                    String(qoute.location)
                        .toLowerCase()
                        .includes(String(search).toLowerCase()) ||
                    String(qoute.status)
                        .toLowerCase()
                        .includes(String(search).toLowerCase()) ||
                    String(Helper.changeDateFormat(qoute.created_at))
                        .toLowerCase()
                        .includes(String(search).toLowerCase())
            );
        }
        setTotalItem(qoutes.length);
        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.pickorder === "asc" ? 1 : -1;
            qoutes = qoutes.sort(
                (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }
        //Current Page slice
        return qoutes.slice(
            (currentPage - 1) * ITEMS_PER_P,
            (currentPage - 1) * ITEMS_PER_P + ITEMS_PER_P
        );
    }, [qouteList, currentPage, search, sorting]);


    const handleAddSubscription = ()=>{
        console.log(formData, 'form')
        // return false;
        let check = Helper.AddSubscriptionValidation(formData, errors, setErrors)
        if (check) {
            return false;
        }
        setLoading(true);

        axios.post(BASE_URL +"/create-subscription",
            formData,
            {headers})
            .then((res)=>{
                if(res?.data?.status === 200){
                    closeSubscriptionModal();
                    setLoading(false);
                    setFormData({
                        full_name: '',
                        company_name: '',
                        company_address: '',
                        email: '',
                        phone_number: '',
                        num_of_views: '',
                        num_of_jobs: '',
                        num_of_users: '',
                        us_based: '',
                        location: '',
                        start_date: '',
                        end_date: '',
                        package: {
                            name:'Default Package',
                            description:'',
                            price:0.0,
                            feature: '',
                            most_popular: false,
                            active: true,
                        },
                    });

                    let payload={
                        status: 'approved'
                    }

                    const url = BASE_URL +'/quote-requests/'+qId+'/status';
                    axios
                        .put(url, payload, { headers })
                        .then((response) => {
                            // Handle the API response if needed.
                            // console.log(response.data);
                            setLoading(false);
                            fetchQoute()
                            fetchSubscription()
                        })
                        .catch((error) => {
                            setLoading(false);
                            // Handle errors if any.
                            console.error("Error calling API:", error);
                        });

                }else if(res?.data.api_status == 401){
                    navigate("/admin-login")
                }
            })
            .catch(err=>{
                setErrors({...errors, email: err?.response?.data?.email}); // Clear the error for the title field
                console.log(err?.response?.data?.email[0])
                toast.error(err?.response?.data?.email[0], {
                    position: toast.POSITION.TOP_CENTER,
                });
                console.log(err)
                setLoading(false);

            })
        console.log(formData, 'formData')
    }

    const CancelSubscription = (data)=>{
        let payload={
            status: 'rejected'
        }

        const url = BASE_URL +'/quote-requests/'+data?.id+'/status';
        axios
            .put(url, payload, { headers })
            .then((response) => {
                // Handle the API response if needed.
                // console.log(response.data);
                setLoading(false);
                fetchQoute()
                fetchSubscription()
            })
            .catch((error) => {
                setLoading(false);
                // Handle errors if any.
                console.error("Error calling API:", error);
            });
    }

    return(
        <>
            {loading && (
                <div className={styles.loadingOverlay}>
                    <div className={styles.loadingSpinner}></div>
                </div>
            )}

            <Search
                onSearch={(value) => {
                    setSearch(value);
                    setCurrentPage(1);
                }}
            />

        <div className="table-responsive my-3">
            <table className={tableStyle.table1}>
                <thead>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Company Name</th>
                    <th>Email</th>
                    <th>Phone number</th>
                    <th>Number of views</th>
                    <th>Number of users</th>
                    <th>Number of jobs</th>
                    <th>USA Based</th>
                    <th>Address</th>
                    <th>Location</th>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Action</th>

                </tr>
                </thead>
                <tbody>
                {
                    list.map((data, index)=>(
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data?.full_name}</td>
                            <td>{data?.company_name}</td>
                            <td>{data?.email}</td>
                            <td>{data?.phone_number}</td>
                            <td>{data?.num_of_views}</td>
                            <td>{data?.num_of_users}</td>
                            <td>{data?.num_of_jobs}</td>
                            <td className='text-capitalize'>{data?.us_based}</td>
                            <td>{data?.company_address}</td>
                            <td>{data?.location}</td>
                            <td className='text-capitalize'>{data?.status}</td>
                            <td>{Helper.changeDateFormat(data?.created_at)}</td>
                            <td>
                                <FaUserEdit role='button'
                                            title='Approve Request'
                                            style={{color:colors.thirdColor,marginRight:'5px'}}
                                            onClick={()=>{ShowSubscriptionModal(data)}}>
                                </FaUserEdit>
                                {data?.status!='rejected' &&(
                                <TbUserCancel
                                    role='button'
                                    title='Reject Request'
                                    onClick={()=>{CancelSubscription(data)}}
                                />
                                )}

                            </td>

                        </tr>
                    ))
                }
                </tbody>
            </table>
            {list.length === 0 &&(<p className='text-danger text-center'>No Request Found!</p>)}
        </div>
            <Pagination
                total={totalItem}
                itemsPerPage={ITEMS_PER_P}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
                searching= {search}
            />

          <AddSubscription
              showSubModal={showSubModal}
              closeSubscriptionModal={closeSubscriptionModal}
              formData={formData}
              setFormData={setFormData}
              errors={errors}
              setErrors={setErrors}
              handleAddSubscription={handleAddSubscription}

          />
            <ToastContainer />

        </>
    )
}
