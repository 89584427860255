import React,{useState} from "react";
import {BsSliders2, BsThreeDots} from "react-icons/bs";
import {SiSimpleanalytics} from "react-icons/si";
import {Button, Form, Modal} from "react-bootstrap";
import {AiOutlineClose} from "react-icons/ai";
import avatar from "../../assets/profile5.png";
import {STORAGE_URL} from "../../config";
import Helper from "../../Helper";
import {CustomDivider} from "./SideBarContent/Divider";
import {ActiveForJob} from "./SeekerSideBarContent/ActiveForJob";
import {AppliedJobs} from "./SeekerSideBarContent/AppliedJobs";
import {FavoriteJobs} from "./SeekerSideBarContent/FavoriteJobs";
import {JobAnalytics} from "./SeekerSideBarContent/JobAnalytics";
import {MyCircle} from "./SeekerSideBarContent/MyCircle";
import {SeekerInfo} from "./SeekerSideBarContent/SeekerInfo";
import {SeekerResume} from "./SeekerSideBarContent/SeekerResume";
import {CustomModalStyle} from "../CustomModalStyle";






export const JobSeekerSiderBar = ({
                                      jobSeeker,
                                      createUpdateProfile,
                                      handleProfileClick,
                                      handleEditResumeClick,
                                      showResumeDropdown,
                                      containerRef,
                                      handleResumeClick,
                                      createResume,
                                      handleConfirmDeleteResume,
                                      handleResumeCloseModal,
                                      isResumeModalOpen,
                                      handleDeleteResumeClick,
                                      handleResumeOptionClick,
                                      handleAppliedFavJobClick,
                                      handleCompanyImg,
                                      viewAllAppliedJobsHandler,
                                      handleConfirmDeleteJob,
                                      showFavJobDropdown,
                                      openFavJobDropdownIndex,
                                      handleJobOptionClick,
                                      viewAllResumeHandler,
                                      handleToggle,
                                      isActiveForJobs,
                                      user_data,
                                      handleFollowFollowing

                                 })=>{

    const [showAnalytics, setShowAnalytics] = useState(false)
    const [showMoreInfo, setShowMoreInfo] = useState(false)


    const showMoreInfoModal=()=>{
        setShowMoreInfo(true)
    }
    const showAnalyticModal=()=>{
        setShowAnalytics(true)
    }
    const closeMoreInfo = ()=>{
        setShowMoreInfo(false)
    }

    const closeAnalytics = ()=>{
        setShowAnalytics(false)
    }

    return(
        <>
            <div className="emp_sidebar mb-4">
                <div className="d-flex justify-content-end gap-4">
                    <SiSimpleanalytics
                        size={24}
                        role="button"
                        title="Analytics"
                        onClick={showAnalyticModal}
                    ></SiSimpleanalytics>
                    <BsSliders2
                        size={24}
                        role="button"
                        title="More Information"
                        onClick={showMoreInfoModal}
                    ></BsSliders2>

                </div>

            </div>

            {/* More Info Modal*/}
            <Modal show={showMoreInfo} onHide={closeMoreInfo} className="modal right fade sidebar_content">
                <Modal.Header>
                    <Modal.Title className="text-uppercase">User Detail</Modal.Title>
                    <AiOutlineClose className="basic_text" size={24} role="button" onClick={closeMoreInfo}></AiOutlineClose>
                </Modal.Header>
                <Modal.Body className="modal-body-scrollable">
                    <div className="p-1">
                      <SeekerInfo
                          jobSeeker={jobSeeker}
                          handleProfileClick={handleProfileClick}
                          createUpdateProfile={createUpdateProfile}
                      />
                        <CustomDivider />

                        <SeekerResume
                            jobSeeker={jobSeeker}
                            handleConfirmDeleteResume={handleConfirmDeleteResume}
                            handleResumeCloseModal={handleResumeCloseModal}
                            isResumeModalOpen={isResumeModalOpen}
                            handleDeleteResumeClick={handleDeleteResumeClick}
                            handleEditResumeClick={handleEditResumeClick}
                            showResumeDropdown={showResumeDropdown}
                            handleResumeOptionClick={handleResumeOptionClick}
                            containerRef={containerRef}
                            handleResumeClick={handleResumeClick}
                            createResume={createResume}
                        />
                        <CustomDivider />
                        <AppliedJobs
                            jobSeeker={jobSeeker}
                            handleAppliedFavJobClick={handleAppliedFavJobClick}
                            handleCompanyImg={handleCompanyImg}
                            viewAllAppliedJobsHandler={viewAllAppliedJobsHandler}
                        />
                        <CustomDivider />
                        <FavoriteJobs
                            jobSeeker={jobSeeker}
                            handleConfirmDeleteJob={handleConfirmDeleteJob}
                            showFavJobDropdown={showFavJobDropdown}
                            openFavJobDropdownIndex={openFavJobDropdownIndex}
                            handleJobOptionClick={handleJobOptionClick}
                            handleAppliedFavJobClick={handleAppliedFavJobClick}
                            handleCompanyImg={handleCompanyImg}
                            viewAllResumeHandler={viewAllResumeHandler}
                        />
                    </div>
                </Modal.Body>
            </Modal>

            {/*Analytics Modal*/}
            <Modal show={showAnalytics} onHide={closeAnalytics} className="modal right fade sidebar_content">
                <Modal.Header>
                    <Modal.Title className="text-uppercase">Analytics</Modal.Title>
                    <AiOutlineClose className="basic_text" size={24} role="button" onClick={closeAnalytics}></AiOutlineClose>
                </Modal.Header>
                <Modal.Body className="modal-body-scrollable">
                    <div className='p-1'>
                      <ActiveForJob
                             handleToggle={handleToggle}
                            isActiveForJobs={isActiveForJobs}
                            jobSeeker={jobSeeker}
                      />
                        <CustomDivider />
                       <MyCircle
                           handleFollowFollowing={handleFollowFollowing}
                           user_data={user_data}
                       />
                        <CustomDivider />
                     <JobAnalytics
                         jobSeeker={jobSeeker}
                     />
                    </div>
                </Modal.Body>
            </Modal>

            <CustomModalStyle />

        </>
    )
}