import React from "react";


export const Experience = ({
                               contactType,
                               filter,
                               handleExperienceChange
                           })=>{

    return (
        <div className="resumeupdatealjob work-authorizaljob alljobbox">
            <h2>Years of Experience</h2>

            {contactType && contactType?.experienceCount?.map((experience, index)=>(
                <div className="alljobfilter">
                    <div className="alljoblabel" key={index}>
                        <input type="checkbox"
                               value={experience?.id}
                               checked={
                                   filter.years_of_experience_id.some(id => id === experience.id)
                               }
                               onChange={()=>handleExperienceChange(experience.id)}
                               name={experience?.experience_range}
                               id={experience?.experience_range}/>
                        <label htmlFor={experience?.experience_range}>{experience?.experience_range}</label>

                    </div>
                    <p>{experience?.count}</p>
                </div>
            ))}


        </div>
    )
}