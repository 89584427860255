import React, {useEffect, useState} from "react";

import './assets/css/header.css';
import '../src/assets/css/styles.css'
import '../src/assets/css/postjob.css';
import '../src/assets/css/postjobpreview.css';
import EmployerHeader from "./EmployerHeader";
import axios from "axios";
import {BASE_URL, STORAGE_URL} from "./config";
import headerIcon2 from '../src/assets/headericone2.svg';
import {Modal, Button} from "react-bootstrap";
import {useLocation, useNavigate, Link} from "react-router-dom";
import {BsArrowLeft, BsHeart, BsHeartFill} from 'react-icons/bs';
import rectangelImage from "./assets/rectangel.png";
import favorite_heart from '../src/assets/redheart.png';
import styles from "./assets/css/LoadingComponent.module.css";
import JobSeekerHeader from "./JobSeekerHeader";
import {BsEye} from "react-icons/bs";
import Helper from "./Helper";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {log} from "video.js";
import {Follow} from "./components/UserFollow/Follow";
import colors from "./colors";
import { RiGhost2Fill } from "react-icons/ri";
import {ErrorHandlingComponent} from "./ErrorHandlingComponent";


function PostAJobPreview() {

    const userable_type = localStorage.getItem('userable_type');
    let headerComponent;
    if (userable_type === 'App\\Models\\Employer') {
        headerComponent = <EmployerHeader/>;
    } else {
        headerComponent = <JobSeekerHeader/>;
    }
    const [fetchError, setFetchError] = useState(false);
    const [fetchErrorMessage, setFetchErrorMessage] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [jobData, setJobData] = useState({})
    const [loading, setLoading] = useState(false);
    const [isconfirmApplyModalOpen, setisconfirmApplyModalOpen] = useState(false);
    const {jobId, from} = location.state || {};
    const allJobs = location?.state?.allJobs || false;
    const matched_keywords = location?.state?.matched_keywords || null;
    const matched_location = location?.state?.matched_location || null;
    const [favoriteResumes, setFavoriteResumes] = useState([]);
    let token = localStorage.getItem("access_token");
    const employerId = localStorage.getItem('employerId')
    const jobSeekerId = localStorage.getItem('jobSeekerId');
    let headers = {
        Authorization: `Bearer ${token}`,
    };
    const payload = {
        job_ad_id: jobId,
        job_seeker_id: jobSeekerId,
    };
    if (jobSeekerId != null) {
        axios
            .post(`${BASE_URL}/viewedJob`, payload, {headers: headers})
            .then((response) => {
                // Handle successful response
            })
            .catch((error) => {
                console.error("Error occurred:", error);
                // Handle error
            });
    }
    const getFavoriteResumes = () => {
        setLoading(true)
        const jobSeekerId = localStorage.getItem("jobSeekerId");
        if (token == null) {
            token = localStorage.getItem("access_token");
            headers = {
                Authorization: `Bearer ${token}`,
            };
        }
        axios.post(`${BASE_URL}/job_ad/get_favorite`, {job_seeker_id: jobSeekerId}, {
            headers: headers
        })
            .then((response) => {
                if (response?.data.status === 200) {
                    setFavoriteResumes(response?.data?.favorite_job);
                    setLoading(false)
                } else {
                    setFavoriteResumes([]);
                    setLoading(false)
                }

            })
            .catch((error) => {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
            });
    };
    const isFavorite = favoriteResumes && favoriteResumes.some((favResume) => favResume?.job_ad?.id === jobId);
    const fetchJobData = () => {
        setLoading(true);
        if (jobId !== undefined && jobId !== null) {
            axios.get(`${BASE_URL}/job_ad/${jobId}`, {
                headers
            })
                .then(response => {
                    if (response?.data?.status === 200) {
                        setJobData(response?.data?.jobPost);
                        setLoading(false);
                        console.log(response?.data?.jobPost);
                    }
                })
                .catch(error => {
                    setLoading(false)
                    setFetchError(true)
                    setFetchErrorMessage(error)
                });
        } else {
            setJobData(JSON.parse(localStorage.getItem('jobData')));
            console.log(JSON.parse(localStorage.getItem('jobData')));
            setLoading(false);
        }

    };
    useEffect(() => {
        getFavoriteResumes();
        fetchJobData();
    }, []);

    const handleFavorite = async (jobId, isFavorite) => {
        setLoading(true);

        const jobSeekerId = localStorage.getItem("jobSeekerId");
        const url = isFavorite
            ? `${BASE_URL}/fav_job/${jobId}` // Use the correct endpoint for deleting favorite resumes
            : `${BASE_URL}/job_ad/favorite`;
        const method = isFavorite ? 'DELETE' : 'POST';
        const payload = {
            job_ad_id: jobId,
            job_seeker_id: parseInt(jobSeekerId)
        };
        if (token == null) {
            token = localStorage.getItem("access_token");
            headers = {
                Authorization: `Bearer ${token}`,
            };
        }
        try {
            const response = await axios({
                method: method,
                url: url,
                headers: headers,
                data: payload  // Axios uses 'data' instead of 'body' for the request payload
            });

        } catch (error) {
            setLoading(false)
            setFetchError(true)
            setFetchErrorMessage(error)        }

        getFavoriteResumes();
    };
    const handleCloseButton = () => {


        // navigate('/post_a_job');

        if (jobId !== null && jobId !== undefined) {
            navigate(-1)

            // if(employerId!=null) {
            //
            //     if(from==="/alljobs"){
            //         const defaultfrom="/employer_dashboard";
            //         navigate('/alljobs', { state: { defaultfrom } });
            //     }else{
            //         navigate('/employer_dashboard')
            //
            //     }
            //
            // }
            // else {
            //
            //     if(from==="/job_seeker_dashboard"){
            //         navigate('/job_seeker_dashboard')
            //
            //     }
            //     else {
            //         navigate('/search_job')
            //     }
            // }
        } else {
            window.close();
        }


    }
    const handleCloseModal = () => {
        setisconfirmApplyModalOpen(false);
    }
    const handleConfirmApplyNow = () => {
        setisconfirmApplyModalOpen(true);
    };
    const handleApplyNow = () => {

        setLoading(true);
        // Make the API call
        axios.post(`${BASE_URL}/apply-for-job`, {
            job_ad_id: jobData?.id, // Provide the job_ad_id
            job_seeker_id: jobSeekerId,   // Replace with the actual job seeker's ID
        }, {
            headers: headers // Pass the headers object here
        })
            .then(response => {
                // console.log("Apply Now API response:", response.data);
                if (response.data.status === 200) {
                    toast.success('Successfully applied for the job!', {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setLoading(false)
                    setisconfirmApplyModalOpen(false);
                    fetchJobData();
                    // setTimeout(() => {
                    //     setLoading(false)
                    //     navigate('/job_seeker_dashboard');
                    // }, 2000);
                }

                // Handle success response if needed
            })
            .catch(error => {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
                // Handle error response if needed
            });
    };


    const handleFollowing = (job) => {

        let matchUser
        job?.employer?.users?.map(user => {
            if (user?.userable_id == job?.employer?.id) {
                console.log(user?.userable_id, job?.employer?.id)
                matchUser = user
            }
        })

        return (<Follow
            user={matchUser}
            setLoading={setLoading}
        />)

    }


    return (
        <div>
            {loading && (
                <div className={styles.loadingOverlay}>
                    <div className={styles.loadingSpinner}></div>
                </div>
            )}
            {/*---------------------------------------------------------------*/}
            {/*                         NAVBAR Start                          */}
            {/*---------------------------------------------------------------*/}
            {headerComponent}


            {/*---------------------------------------------------------------*/}
            {/*                         NAVBAR END                            */}
            {/*---------------------------------------------------------------*/}
            {/*---------------------------------------------------------------*/}
            {/*                        MAin Body Start                        */}
            {/*---------------------------------------------------------------*/}


            <div className="CRmainpreview">

                <div className="postpreviewcontainer">
                    <hr className="custom-hr"/>

                    {/*------------------------- Section 1 -------------------- */}
                    <div className="CRallsection">
                        <div className="CRgeneralpreview CRdatepreview">
                            <div className="row ">
                                {/*{from && */}
                                <div className=" backbtn">
                                    {/*<button onClick={()=>navigate(from, { state: { jobId}})}> <BsArrowLeft  /> Back </button>*/}
                                    <button onClick={handleCloseButton}><BsArrowLeft/> Back</button>
                                </div>
                                {/*}*/}
                            </div>
                            <div className="row ">
                                <div className=" topjobrow d-flex justify-content-between gap-2" >


                                    <div className="RDheadtitle ">
                                        <h2>{jobId !== null && jobId !== undefined ? 'Job Detail' : 'Preview Job'}</h2>
                                    </div>
                                    {
                                    userable_type !== 'App\\Models\\Employer' ? (
                                        jobData?.job_applications?.some(application => application?.job_seeker_id == jobSeekerId) ? (
                                            <div className="apply-now-btn" style={{ "display": "inline-flex", "alignItems": "center" }}>
                                                <p className="already-applied-txt">Applied</p>
                                                {handleFollowing(jobData)}
                                                {/* Display user information here */}
                                            </div>
                                        ) : (
                                            <div className="apply-now-btn" style={{ "display": "inline-flex", "alignItems": "center" }}>
                                                <button className="btn" onClick={handleConfirmApplyNow}>Apply Now</button>
                                                {handleFollowing(jobData)}
                                                {/* Display user information here */}
                                                <ToastContainer />
                                            </div>
        )
    ) : null // Render nothing if userable_type is 'App\\Models\\Employer'
}

{/* 
                                    {
                                        userable_type !== 'App\\Models\\Employer' ? (
                                            jobData?.job_applications?.some(application => application?.job_seeker_id == jobSeekerId) ? (
                                                <p className="already-applied-txt">Applied</p>
                                                {handleFollowing(jobData)}

                                            ) : (
                                                <div className="apply-now-btn" style={{"display": "inline-flex", "align-items": "center"}}>
                                                    <button className="btn" onClick={handleConfirmApplyNow}>Apply Now
                                                    </button>
                                                    {handleFollowing(jobData)}
                                        
                                                    <ToastContainer/>
                                                </div>
                                            )
                                        ) : null // Render nothing if userable_type is 'App\\Models\\Employer'
                                    } */}

                                </div>
                                <Modal show={isconfirmApplyModalOpen} onHide={() => handleCloseModal()} centered>
                                    <Modal.Header>
                                        <Modal.Title>Confirm Application for Job</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>Are you sure you want to apply for this Job!</Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={() => handleCloseModal()}>
                                            Close
                                        </Button>
                                        <Button variant="danger" onClick={() => handleApplyNow()}>
                                            Confirm
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                <div style={{position: "relative"}}>
                                    <img
                                        src={rectangelImage}
                                        alt="Image Line"
                                        className="rectangel-image2"
                                    />
                                    <hr className="hr-style"/>
                                </div>
                                <div className="CRjobpreview jobcont d-flex flex-wrap justify-content-between">
                                <div className="d-flex align-items-center CRjobid">
                                <label>Job ID<span className="CRlabelcol">:</span></label>
                                 <p>{jobData?.code}</p>
                                </div>

                                {/* {jobData?.employer?.users && jobData.employer.users.map (user => {
                                if (jobData?.employer?.id === user?.userable_id) {
                                    if (user?.current_employee !== null &&
                                        user?.current_employee !== "") {
                                        return (
                                          
                                                <div className="d-flex wrap align-items-center CRjobid">
                                                    <label>Company Name<span className="CRlabelcol">:&nbsp;</span></label>
                                                    <span>{user?.current_employee}</span>
                                                </div>
                                              
                                        )
                                    }

                                }

                                })} */}



                                    <div className="d-flex wrap align-items-center justify-content-end CRjobid">
                                        <label>Posted On<span className="CRlabelcol">:</span></label>
                                        <p>
                                            {Helper.changeDateFormat(new Date(jobData.job_creation_date))}
                                            {/* {new Date(jobData.job_creation_date).toLocaleDateString('en-US', {
                                            month: '2-digit',
                                            day: '2-digit',
                                            year: 'numeric'
                                        })} */}
                                        </p>
                                    </div>

                                </div>
                            </div>


                            <div className="row  JPhead">
                                <div className="col-md-12 d-flex flex-wrap align-items-start justify-space-between">
                                    <div className="col-md-8"> 
                                        
                                            {/* Add your image element here */}
                                            {/*<img src={profileImage} alt="Profile" style={{marginRight: '10px'}}/>*/}
                                                {/*{console.log(jobData, jobId)}*/}

                                                {jobData?.employer?.users && jobData.employer.users.map (user => {
                                                    if (jobData?.employer?.id === user?.userable_id) {
                                                        if (user?.current_employee !== null &&
                                                            user?.current_employee !== "") {
                                                            return (
                                                            
                                                                    <div className="d-flex gap-2 wrap align-items-center CRjobid rounded-circle">
                                                                        {/* <label>Company Name<span className="CRlabelcol">:&nbsp;</span></label> */}
                                                                        {/*<span><img className="profile-image" src={STORAGE_URL+user?.current_employee_logo} ></img></span>*/}
                                                                        <span>{ Helper?.current_user_log(user)}</span>
                                                                        <h2 className="JSname" dangerouslySetInnerHTML={{__html: Helper.highlightJobTitle(user?.current_employee, matched_keywords)}}/>
                                                                    </div>
                                                                
                                                            )
                                                        }

                                                    }

                                                    })}
                                             
                                                
                                                <h3  className="m-0 py-1" dangerouslySetInnerHTML={{__html: Helper.highlightJobTitle(jobData?.job_title, matched_keywords)}}/>


                                                <div className="d-flex gap-2">
                                                    {jobId !== null && jobId !== undefined && (<> <label>Last
                                                        Updated<span className="CRlabelcol">:</span></label>
                                                        <p className="m-0">{Helper.changeDateFormat(new Date(jobData?.updated_at))}
                                                            {/* {new Date(jobData?.updated_at).toLocaleDateString('en-US')} */}
                                                        </p></>)}

                                                        </div>
                                                        <div className="d-flex gap-2">
                                                    <label>Job Location<span className="CRlabelcol">:</span></label>
                                                    <p className="m-0" dangerouslySetInnerHTML={{__html: Helper.highlightLocation(jobData?.location, matched_location)}}></p>
                                                    </div>
                                                    {/*<h2>{jobData?.job_title}</h2>*/}
                                                    {/*<h3 dangerouslySetInnerHTML={{ __html: highlightLocation(jobData.location, matched_location) }} />*/}
                                                
                                                {/* <h2>{jobData?.job_title}</h2> */}

                                                {/* <h3>{jobData?.location}</h3> */}
                                                
                                    </div>
                                    <div className="col-md-4 d-flex justify-content-end gap-2">


                                        {userable_type !== 'App\\Models\\Employer' && jobData.viewed_jobs && jobData.viewed_jobs.length > 0 && (
                                            <BsEye size={24} style={{color: 'white'}}/>
                                        )}
                                        {/* {userable_type !== 'App\\Models\\Employer' && (
                                            handleFollowing(jobData)
                                        )} */}


                                        {
                                            userable_type !== 'App\\Models\\Employer' &&
                                            (
                                                isFavorite ?
                                                    (<BsHeartFill title="Unfavorite"
                                                                  onClick={() => handleFavorite(jobId, isFavorite)}
                                                                  size={24}
                                                                  role="button"
                                                                  style={{color: '#e54545'}}
                                                    />)
                                                    : (<BsHeart title="favorite"
                                                                onClick={() => handleFavorite(jobId, isFavorite)}
                                                                size={24}
                                                                role="button"
                                                                style={{color: '#fff'}}/>
                                                    )
                                                // <img
                                                //     onClick={() => handleFavorite(jobId, isFavorite)}
                                                //     src={isFavorite ? favorite_heart : headerIcon2}
                                                //     className={isFavorite ? 'favorite-icon favorite' : 'favorite-icon'}
                                                // />
                                            )}

                                    </div>
                                </div>

                            </div>


                        </div>


                        <div style={{position: 'relative'}}>

                            <img
                                src={rectangelImage}
                                alt="Image Line"
                                className="rectangel-image"
                            />
                            <hr className="hr-style"/>
                        </div>


                        {/*-------------------------Contact Details Section 2 -------------------- */}

                        <div className="CRgeneralpreview CRjobdescpreview ">
                            <div className="CRjobpreview-screen">
                                <div className="row">
                                    <h4>Job Details</h4>
                                </div>
                            </div>
                            {/*Job section 2 */}
                            <div className="CRjobpreview-screen">
                                <div className="row">
                                    <div className="col-lg-12  CRjobpreview  ">

                                        <label>Job Type<span className="CRlabelcol">:&nbsp;</span></label>
                                        <span>{jobData?.job_type === 'Any' ? 'Any (FT, Contract, 1099)' : jobData?.job_type}</span>

                                    </div>
                                </div>
                                {/* For Localstorage Case */}
                                {jobData?.contract_type_label && jobData?.job_type === "Contract" && (
                                    <div className="CRjobpreview-screen">
                                        <div className="row">
                                            <div className="col-lg-12  CRjobpreview  ">
                                                <label>Contract Type<span className="CRlabelcol">:&nbsp;</span></label>
                                                <span>
                                                  {jobData?.contract_type_label.includes('Contract with us')
                                                      ? jobData?.contract_type_label.replace('Contract with us', `Contract with ${jobData?.employer?.users[0]?.current_employee}`)
                                                      : jobData?.contract_type_label}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* For Database Case */}
                                {jobData?.contract_type_id !== undefined && jobData?.contract_type_id !== 0 && jobData?.job_type === "Contract" && !jobData?.contract_type_label && (
                                    <div className="CRjobpreview-screen">
                                        <div className="row">
                                            <div className="col-lg-12  CRjobpreview  ">
                                                <label>Contract Type<span className="CRlabelcol">:&nbsp;</span></label>
                                                <span>
                                                  {jobData?.contract_type?.employment_type.includes('Contract with us')
                                                      ? jobData?.contract_type?.employment_type.replace('Contract with us', `Contract with ${jobData?.employer?.users[0]?.current_employee}`)
                                                      : jobData?.contract_type?.employment_type}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}

                               

                                {/*For Localstorage Case */}
                                {jobData?.work_authorization_label && jobData?.work_authorization_label.length > 0 && (

                                    <div className="CRjobpreview-screen">
                                        <div className="row">

                                            <div className="col-lg-12  CRjobpreview  ">
                                                <label>Work Authorization<span
                                                    className="CRlabelcol">:&nbsp;</span></label>
                                                <span>{jobData?.work_authorization_label}</span>


                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/*For Database Case */}
                                {jobData?.work_authorizations && (
                                    <div className="CRjobpreview-screen">
                                        <div className="row">
                                            <div className="col-lg-12 CRjobpreview">
                                                <label>Work Authorization<span
                                                    className="CRlabelcol">:&nbsp;</span></label>
                                                <span>
                    {jobData.work_authorizations.map((workAuth, index) => (
                        <span key={index}>
                            {workAuth.work_authorization}
                            {index < jobData.work_authorizations.length - 1 ? ', ' : ''}
                        </span>
                    ))}
                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/*{jobData?.work_authorization_id && !jobData?.work_authorization_label &&(*/}
                                {/*    <div className="CRjobpreview-screen">*/}
                                {/*        <div className="row">*/}

                                {/*            <div className="col-lg-12  CRjobpreview  ">*/}
                                {/*                <label>Work Authorization<span className="CRlabelcol">:&nbsp;</span></label>*/}
                                {/*                <span>{jobData?.work_authorization?.work_authorization}</span>*/}


                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*)}*/}

                            </div>


                            <div className="CRjobpreview-screen">
                                <div className="row">
                                    <div className="col-lg-12  CRjobpreview  ">

                                        <label>Interview Mode<span className="CRlabelcol">:&nbsp;</span></label>
                                        <span>{jobData?.interview}</span>

                                    </div>
                                </div>
                            </div>


                            {/*Job section 4 */}
                            {jobData?.no_of_rounds_interview && (
                                <div className="CRjobpreview-screen">
                                    <div className="row">
                                        <div className="col-lg-12  CRjobpreview  ">

                                            <label>Interview Rounds<span className="CRlabelcol">:&nbsp;</span></label>
                                            <span>{jobData?.no_of_rounds_interview}</span>

                                        </div>
                                    </div>
                                </div>
                            )}
                            {jobData?.job_mode && (
                                <div className="CRjobpreview-screen">
                                    <div className="row">
                                        <div className="col-lg-12  CRjobpreview  ">

                                            <label>Job Mode<span className="CRlabelcol">:&nbsp;</span></label>
                                            <span>{jobData?.job_mode}</span>

                                        </div>
                                    </div>
                                </div>
                            )}
                            {/*Job section 5 */}
                            {jobData?.compensation_rate && (
                                <div className="CRjobpreview-screen">
                                    <div className="row">
                                        <div className="col-lg-12  CRjobpreview  ">

                                            <label>Compensation/Salary<span
                                                className="CRlabelcol">:&nbsp;</span></label>
                                            <>{jobData?.compensation === 'Annual' ? (
                                                <span>${jobData?.compensation_rate}/year</span>
                                            ) : (
                                                <span>${jobData?.compensation_rate}/hour</span>
                                            )}</>


                                        </div>
                                    </div>
                                </div>
                            )}

                            {jobData?.compensation === 'Annual' ? (
                                <>
                                    <div className="CRjobpreview-screen">
                                        <div className="row">
                                            <div className="col-lg-12  CRjobpreview">

                                                <label>Bonus<span className="CRlabelcol">:&nbsp;</span></label>
                                                <span>{jobData.bonus}</span>

                                            </div>
                                        </div>
                                    </div>


                                    {jobData?.bonus === 'Yes' ? (

                                        <div className="CRjobpreview-screen">
                                            <div className="row">
                                                <div className="col-lg-12  CRjobpreview">

                                                    <label>Bonus Percentage<span
                                                        className="CRlabelcol">:&nbsp;</span></label>
                                                    <span>{jobData?.bonus_percentage ? jobData.bonus_percentage + '%' : ''}</span>

                                                </div>
                                            </div>
                                        </div>

                                    ) : ''}

                                </>

                            ) : null}


                        </div>

                        <div style={{position: 'relative'}}>

                            <img
                                src={rectangelImage}
                                alt="Image Line"
                                className="rectangel-image"
                            />
                            <hr className="hr-style"/>
                        </div>

                        <div className="CRgeneralpreview CRjobdescpreview ">

                            <div className="CRjobpreview-screen">
                                <div className="row">
                                    <h4>Description</h4>
                                </div>
                            </div>
                            <div className="CRjobpreview-screen">
                                <div className="row">
                                    <div className="col-lg-12  CRjobpreview">

                                        {/*<label>Job Description<span className="CRlabelcol">:&nbsp;</span></label>*/}
                                        {/*<span><div className="job-descdetail" dangerouslySetInnerHTML={{ __html: jobData?.description }} /></span>*/}
                                        <span
                                            dangerouslySetInnerHTML={{__html: Helper.highlightJobTitle(jobData?.description, matched_keywords)}}/>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{position: 'relative'}}>

                            <img
                                src={rectangelImage}
                                alt="Image Line"
                                className="rectangel-image"
                            />
                            <hr className="hr-style"/>
                        </div>

                        <div className="CRgeneralpreview CRjobdescpreview ">

                            <div className="CRjobpreview-screen">
                                <div className="row">
                                    <h4>Skill Details</h4>
                                </div>
                            </div>
                            {jobData.skills && jobData.skills.length > 0 && (

                                <div className="CRjobpreview-screen">
                                    <div className="row">
                                        <div className="col-lg-12  CRjobpreview">

                                            <label>Skills<span className="CRlabelcol">:&nbsp;</span></label>
                                            {/*<span style={{ color: '#003580' }} dangerouslySetInnerHTML={{ __html: highlightSkills(jobData?.required_skills, matched_keywords) }} />*/}
                                            {jobId !== null && jobId !== undefined ?
                                                (<span style={{color: colors.primaryColor}}
                                                       dangerouslySetInnerHTML={{
                                                           __html: Helper.highlightSkills(jobData?.skills?.map((skill) => skill?.skillset?.name).join(", "),
                                                               matched_keywords)
                                                       }}/>)
                                                : (<span style={{color: colors.primaryColor}}
                                                         dangerouslySetInnerHTML={{
                                                             __html: Helper.highlightSkills(jobData?.skills?.map((skill) => skill?.name).join(", "),
                                                                 matched_keywords)
                                                         }}/>)}

                                        </div>
                                    </div>
                                </div>
                            )}


                            {jobData?.years_of_experience_label && (
                                <div className="CRjobpreview-screen">
                                    <div className="row">
                                        <div className="col-lg-12  CRjobpreview">

                                            <label>Experience<span className="CRlabelcol">:&nbsp;</span></label>
                                            <span>{jobData?.years_of_experience_label}</span>

                                        </div>
                                    </div>
                                </div>
                            )}
                            {jobData?.years_of_experience_id && !jobData?.years_of_experience_label && (
                                <div className="CRjobpreview-screen">
                                    <div className="row">
                                        <div className="col-lg-12  CRjobpreview">

                                            <label>Experience<span className="CRlabelcol">:&nbsp;</span></label>
                                            <span>{jobData?.years_of_experience?.experience}</span>

                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="CRjobpreview-screen">
                                <div className="row">
                                    <div className="col-lg-12  CRjobpreview">

                                        <label>Certification Required<span className="CRlabelcol">:&nbsp;</span></label>
                                        <span>{jobData?.required_certification}</span>

                                    </div>
                                </div>
                            </div>
                            {jobData?.certification_type && (
                                <div className="CRjobpreview-screen">
                                    <div className="row">
                                        <div className="col-lg-12  CRjobpreview">

                                            <label>Certification Type<span className="CRlabelcol">:&nbsp;</span></label>
                                            <span>{jobData?.certification_type}</span>

                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>

                        <div style={{position: 'relative'}}>

                            <img
                                src={rectangelImage}
                                alt="Image Line"
                                className="rectangel-image"
                            />
                            <hr className="hr-style"/>
                        </div>

                        <div className="job-btn">
                            <button onClick={handleCloseButton}>Close</button>

                            <div className="job-post">
                                {/*<button>Save and Post Later</button>*/}
                                {/*<button> Post Now</button>*/}

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {fetchError &&  <ErrorHandlingComponent error={fetchErrorMessage} />}

        </div>
    );
}

export default PostAJobPreview;