import React,{useState} from "react";
import {AiFillFilter} from "react-icons/ai";
import {Modal} from "react-bootstrap";
import {AiOutlineClose} from "react-icons/ai";
import {CustomModalStyle} from "../CustomModalStyle";
import {HiMiniInformationCircle} from "react-icons/hi2";
import styles from "../../assets/css/ProfileView.module.css";
import profile_detail_icon from "../../assets/detail_pv_icon.svg";
import headerIcone1 from "../../assets/headericone1.svg";
import {STORAGE_URL} from "../../config";
import avatarImage from "../../assets/profile5.png";
import {LuUsers} from "react-icons/lu";

import {CustomDivider} from "./SideBarContent/Divider";
import colors from "../../colors";
import { FaHouseUser } from "react-icons/fa";
import { GiSkills } from "react-icons/gi";
import { SiCompilerexplorer } from "react-icons/si";
import { FaUserGraduate } from "react-icons/fa6";
import {ImLocation2, ImTicket} from "react-icons/im";

export const MyProfieSideBar = ({
                                    userData,
                                    firstThreeFollowers,
                                    followers,
                                    showAllFollowers,
                                    handleFollowFollowing,
                                    handleUserClick,
                                    followings,
                                    firstThreeFollowings

                               })=>{

    const [showFilter, setShowFilter] = useState(false)



    const showDetailModal=()=>{
        setShowFilter(true)
    }

    const closeDetailModal = ()=>{
        setShowFilter(false)
    }
    const generateNonUsLocation = () => {
        const country =   userData?.userable.country.name || '';
        const state =  userData?.userable.state || '';
        const city =  userData?.userable.city || '';
        const locationParts = [country];
        if (state) {
            locationParts.push(state);
        }
        if (city) {
            locationParts.push(city);
        }

        return locationParts.join(', ');
    };

    return(
        <>
            <div className="emp_sidebar" style={{width: '100%'}}>
                <div className="d-flex justify-content-end gap-4">
                    <HiMiniInformationCircle
                        size={24}
                        role="button"
                        title="Detail"
                        style={{color:colors.primaryColor}}
                        onClick={showDetailModal}
                    ></HiMiniInformationCircle>

                </div>

            </div>


            {/*Filter Modal*/}
            <Modal show={showFilter} onHide={closeDetailModal} className="modal right fade sidebar_content">
                <Modal.Header>
                    <Modal.Title className="text-uppercase">Details</Modal.Title>
                    <AiOutlineClose className="basic_text" size={24} role="button" onClick={closeDetailModal}></AiOutlineClose>
                </Modal.Header>
                <Modal.Body className="modal-body-scrollable">
                    <div className='p-1'>
                        <div className={styles.pv_left_below}>

                            <div className={styles.pv_left_detail_desc}>

                                {userData?.about_me &&(
                                    <div className={styles.pv_left_detail_content1_section}>

                                        <p>About: <span>{userData?.about_me}</span></p>
                                    </div>
                                )}

                                <div className={styles.pv_left_detail_content1_section}>
                                    <ImLocation2  size={18} />
                                    <p>Location: <span>{userData?.userable?.us_based === 'yes' ?userData?.userable?.location : generateNonUsLocation() }</span></p>
                                </div>

                                {userData?.current_employee &&(
                                    <div className={styles.pv_left_detail_content1_section}>
                                        <FaHouseUser />
                                        <p>Current Job: <span>{userData?.current_employee}</span></p>
                                    </div>
                                )}
                                {/*{userData?.skills.length > 0 && (*/}
                                {/*    <div className={styles.pv_left_detail_content1_section}>*/}
                                {/*        <GiSkills />*/}
                                {/*        <p>*/}
                                {/*            Skill Set:&nbsp;*/}
                                {/*            {userData?.skills.map((skill, index) => (*/}
                                {/*                <span key={skill.id} className={styles.pv_blue_content}>*/}
                                {/*                        #{skill.name}*/}
                                {/*                    {index < userData.skills.length - 1 && ", "}*/}
                                {/*                      </span>*/}
                                {/*            ))}*/}
                                {/*        </p>*/}
                                {/*    </div>*/}
                                {/*)}*/}

                                {userData?.experience_details.length > 0 && (
                                    <div className={styles.pv_left_detail_content1_section}>
                                        <SiCompilerexplorer size={18} />
                                        <p>
                                            Experience Details:&nbsp;
                                            {userData?.experience_details.map((experience_detail, index) => (
                                                <span key={experience_detail.id} className={styles.pv_blue_content}>
                                            {experience_detail.job_title} at {experience_detail.company_name} ({experience_detail.start_year} - {experience_detail.end_year})
                                                    {index < userData.experience_details.length - 1 && ", "}
                                                 </span>
                                            ))}
                                        </p>
                                    </div>
                                )}

                                {userData?.education_details.length > 0 && (
                                    <div className={styles.pv_left_detail_content1_section}>
                                        <FaUserGraduate size={18} />
                                        <p>
                                            Education Details:&nbsp;
                                            {userData?.education_details.map((education_detail, index) => (
                                                <span key={education_detail.id} className={styles.pv_blue_content}>
                                                             {education_detail.degree_name} at {education_detail.institute_name}, Graduated in {education_detail.year_of_graduation}
                                                    {index < userData.education_details.length - 1 && ", "}
                                                         </span>
                                            ))}
                                        </p>
                                    </div>
                                )}

                                {userData?.hobbies.length > 0 && (
                                    <div className={styles.pv_left_detail_content1_section}>
                                        <ImTicket size={18} />
                                        <p>
                                            Hobbies:&nbsp;
                                            {userData?.hobbies.map((hobbie, index) => (
                                                <span key={hobbie.id} className={styles.pv_blue_content}>
                                                    {hobbie.name}
                                                    {index < userData.hobbies.length - 1 && ", "}
                                                  </span>
                                            ))}
                                        </p>
                                    </div>
                                )}

                            </div>

                            {/* follower of user*/}
                            <div className="container">
                                <CustomDivider />
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex gap-2 align-items-center mb-2">
                                            {/*<img src={headerIcone1} alt="follower icon" />*/}
                                            <LuUsers size={24} className={styles.follower_heading}></LuUsers>
                                            <h4 className={`${styles.follower_heading} mb-0`}>Followers</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex flex-wrap gap-2">
                                    {firstThreeFollowers.map((follower) => (
                                        <div key={follower.id} style={{width: '48%'}}> {/* Add margin-bottom (mb-3) */}
                                            <div className="card">
                                                <img
                                                    src={`${STORAGE_URL}${follower.image || avatarImage}`}
                                                    alt={`${follower.firstname}'s Image`}
                                                    className="card-img-top custom-image"
                                                />
                                                <div className="card-body">
                                                    <h5 className="card-title custom-title-size" role="button" onClick={() =>{handleUserClick(follower.id)}}>
                                                        {follower.firstname} {follower.lastname}
                                                    </h5>
                                                    <p className="card-text">{follower.current_employee}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    {followers.length > 3 && !showAllFollowers && (
                                        <div style={{width: '48%'}}>
                                            <div className="card">
                                                <img
                                                    src={avatarImage}
                                                    alt='View all'
                                                    className="card-img-top custom-image"
                                                />
                                                <div className="card-body">
                                                    <h5 className="card-title custom-title-size" role="button" onClick={handleFollowFollowing}>
                                                        View All ({followers.length - 3} more)
                                                    </h5>
                                                    <p className="card-text">view</p>

                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {firstThreeFollowers.length == 0 && (<p>Your followers will be displayed here.</p>)}
                                </div>

                            </div>

                            {/*Following of user*/}
                            <div className="container">
                                <CustomDivider />
                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex gap-2 align-items-center mb-2">
                                            {/*<img src={headerIcone1} alt="follower icon" />*/}
                                            <LuUsers size={24} className={styles.follower_heading}></LuUsers>
                                            <h4 className={`${styles.follower_heading} mb-0`}>Followings</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex flex-wrap gap-2">
                                    {firstThreeFollowings.map((following) => (
                                        <div key={following.id} style={{width: '48%'}}> {/* Add margin-bottom (mb-3) */}
                                            <div className="card">
                                                <img
                                                    src={`${STORAGE_URL}${following.image || avatarImage}`}
                                                    alt={`${following.firstname}'s Image`}
                                                    className="card-img-top custom-image"
                                                />
                                                <div className="card-body">
                                                    <h5 className="card-title custom-title-size" role="button" onClick={() =>{handleUserClick(following.id)}}>
                                                        {following.firstname} {following.lastname}
                                                    </h5>
                                                    <p className="card-text">{following.current_employee}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    {followings.length > 3 && (
                                        <div style={{width: '48%'}}>
                                            <div className="card">
                                                <img
                                                    src={avatarImage}
                                                    alt='View all'
                                                    className="card-img-top custom-image"
                                                />
                                                <div className="card-body">
                                                    <h5 className="card-title custom-title-size" role="button" onClick={handleFollowFollowing}>
                                                        View All ({followings.length - 3} more)
                                                    </h5>
                                                    <p className="card-text">view</p>

                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {firstThreeFollowings.length == 0 && (<p>Your followings will be displayed here.</p>)}
                                </div>

                            </div>



                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <CustomModalStyle />

        </>
    )
}