import React, { useEffect, useState, useRef } from "react";

import "./assets/css/header.css";
import "./assets/css/styles.css";
import "./assets/css/postjob.css";
import resumecv from "./assets/CV.pdf";
import "./assets/css/CreatResumepreview-v1.css";
import { useLocation, useNavigate,Link } from "react-router-dom";
import JobSeekerHeader from "./JobSeekerHeader";
import avatar from "../src/assets/profile5.png";
import { Button,Modal } from "react-bootstrap";
import favorite_heart from '../src/assets/redheart.png';
import {BsEye, BsHeart, BsHeartFill} from "react-icons/bs";
import headerIcon2 from '../src/assets/headericone2.svg';
import profileImage from "./assets/profile1.png";
import styles from "./assets/css/LoadingComponent.module.css";
import rectangelImage from "./assets/rectangel.png";
// import Modal from "react-modal";
import { BsArrowLeft } from 'react-icons/bs';
import { BiVideo } from "react-icons/bi";
import { HiOutlineClipboardDocumentCheck } from "react-icons/hi2";
import EmployerHeader from "./EmployerHeader";
import { STORAGE_URL } from "./config";
import BASE_URL from "./config";
import axios from "axios";
import {DocViewer, PdfViewer} from "./components/FileViewer";
import Helper from "./Helper";
import {Follow} from "./components/UserFollow/Follow";
import {useMediaQuery} from "react-responsive";
import colors from "./colors";
import {ErrorHandlingComponent} from "./ErrorHandlingComponent";
import {TiTickOutline} from "react-icons/ti";

function ResumeDetailsScreen() {
  const [fetchError, setFetchError] = useState(false);
  const [fetchErrorMessage, setFetchErrorMessage] = useState(false);
  const [resumeFile, setResumeFile] = useState("");
  const [pdfCheck, setPdfCheck] = useState(false)
  const [loadFailed, setLoadFailed] = useState(false);
  const [loadAttempts, setLoadAttempts] = useState(0);
  const navigate = useNavigate();
  const iframeRef = useRef(null);
  const userable_type = localStorage.getItem("userable_type");
  let headerComponent;
  if (userable_type === "App\\Models\\Employer") {
    headerComponent = <EmployerHeader />;
  } else {
    headerComponent = <JobSeekerHeader />;
  }
  const isMiniScreen = useMediaQuery({ query: '(max-width: 1253px)' })

  useEffect(()  => {
    if (loadAttempts < 3 && loadFailed) {
      setTimeout(() => {

        setLoadAttempts(loadAttempts + 1);
      }, 2000 * (loadAttempts + 1)); // Increase delay for each attempt
    }

  }, [loadAttempts, loadFailed]);

  const location = useLocation();
  const employerId = localStorage.getItem("employerId");
  let token = localStorage.getItem("access_token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  const { resumeId,from, matched_keywords, matched_location,jobId} = location.state || {};
  //   console.log(isActiveForJobs, "isActiveForJobs");
  const payload = {
    employer_id: employerId,
    resume_id: resumeId,
  };
  if (employerId != null) {
    axios
      .post(`${BASE_URL}/viewedResume`, payload, { headers: headers })
      .then((response) => {
        console.log("Data entered successfully:", response.data);
        // Handle successful response
      })
      .catch((error) => {
        setLoading(false)
        setFetchError(true)
        setFetchErrorMessage(error)        // Handle error
      });
    console.log(resumeId);
  }

  const getBadgeColor = (skillLevel) => {
    switch (skillLevel) {
      case "Expert":
        return "#E54545";
      case "Intermediate":
        return "#60BF9F";
      case "Beginner":
        return colors.primaryColor;
      default:
        return "badge-default";
    }
  };
  const [loading, setLoading] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [resume, setResume] = useState(null);
  const [favoriteResumes, setFavoriteResumes] = useState([]);
  const isFavorite = favoriteResumes && favoriteResumes?.some((favResume) => favResume?.resume?.id === resumeId);
    const getFavoriteResumes = () => {
        setLoading(true)
        const employerId = localStorage.getItem("employerId");
        if(token==null)
        {
            token = localStorage.getItem("access_token");
            headers = {
                Authorization: `Bearer ${token}`,
            };
        }
        axios.post(`${BASE_URL}/resumes/get_favorite`, {employer_id: employerId}, {
            headers:headers
        })
            .then((response) => {
                if(response?.data.status === 200){
                    setFavoriteResumes(response?.data?.favorite_resume);
                    setLoading(false)
                }
                else {
                    setFavoriteResumes([]);
                    setLoading(false)
                }

            })
            .catch((error) => {
              setLoading(false)
              setFetchError(true)
              setFetchErrorMessage(error)
            });
    };
  useEffect(() => {
    const fetchResume = async () => {
      try {
        const response = await fetch(`${BASE_URL}/resume/${resumeId}`, {
          headers,
        });
        if (!response.ok) {
          throw new Error("Failed to fetch resume details");
        }
        const resumeData = await response.json();
        // console.log(resumeData.resume);
        setResume(resumeData.resume);
        if (resumeData?.resume?.resume_availability_file_path) {
          let check = resumeData.resume.resume_availability_file_path.split(".")
          // console.log(check[check.length - 1] , 'check')
          if(check[check.length - 1] == 'pdf'){
            setPdfCheck(true)

          }
          setResumeFile(
                STORAGE_URL + resumeData?.resume?.resume_availability_file_path
          );

        }
      } catch (error) {
        console.error(error);
      }
    };
    getFavoriteResumes();
    fetchResume();
  }, []);
  const handleFavorite = async (resumeId, isFavorite) => {
    setLoading(true);
    const employerId = localStorage.getItem("employerId");
    const url = isFavorite
        ? `${BASE_URL}/fav_resumes/${resumeId}`
        : `${BASE_URL}/resumes/favorite`;
    const method = isFavorite ? 'DELETE' : 'POST';
    const payload = {
        employer_id: employerId,
        resume_id: resumeId
    };
if(token==null)
{
    token = localStorage.getItem("access_token");
    headers = {
        Authorization: `Bearer ${token}`,
    };
}
    try {
        const response = await axios({
            method: method,
            url: url,
            headers: headers,
            data: payload  // Axios uses 'data' instead of 'body' for the request payload
        });

        const data = response.data;
        // Handle the response data here
    } catch (error) {
      setLoading(false)
      setFetchError(true)
      setFetchErrorMessage(error)
    }

getFavoriteResumes();
};
  const handleCloseModal =()=>{
    setIsVideoModalOpen(false);
  };
  const handleResumeClick = () => {
    if (resume?.resume_availability_file_path !== "") {
      const resumeFilePath =
        STORAGE_URL + resume?.resume_availability_file_path;
      const newTab = window.open(resumeFilePath, "_blank");
      newTab.focus();
    }
  };



  return (
    <div>
      {loading && (
                <div className={styles.loadingOverlay}>
                    <div className={styles.loadingSpinner}></div>
                </div>
            )}
      {headerComponent}
      {/*---------------------------------------------------------------*/}
      {/*                         NAVBAR Start                          */}
      {/*---------------------------------------------------------------*/}
      <div className="CRmainpreview">
        <div className="container-fluid">
          <div className="row">
            <div
              className={`d-flex ${isMiniScreen?'flex-column':'flex-row'}  gap-3 ${
                !resumeFile ? "justify-content-center" : ""
              }`}
            >
              {/* --------------Resume Detail Preview ------------------ */}
              <div className={`${isMiniScreen?'col-lg-12':'col-lg-6'}`}>
                <div className="CRpostpreviewcontainer">
                  <hr className="custom-hr" />

                  {/*------------------------- Section 1 -------------------- */}
                  <div className="CRallsection">
                    <div className="CRgeneralpreview CRdatepreview">
                      <div className="row">
                       <div className=" backbtnRD">
                            {/*<button onClick={()=>navigate(from, { state: { jobId,from } })}> <BsArrowLeft  /> Back </button>*/}
                        <button onClick={()=>navigate(-1)}> <BsArrowLeft  /> Back </button>
                       </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">

                        <div className="topjobrow d-flex justify-content-between gap-2">
                          
                          <div className="RDheadtitle ">
                          <h2>Resume Detail</h2> 
                        </div>
                        {/* <div className=" d-flex justify-content-end gap-2">

                         <div className="Rdeyeicon">
                          {userable_type !== 'App\\Models\\JobSeeker' && resume?.viewed_resumes && resume?.viewed_resumes.length > 0 && (
                              <BsEye size={24} style={{ color: '#626262' }}/>
                          )}
                           </div>
                             
                             <div className="Rdhearticon">
                          {
                                userable_type !== 'App\\Models\\JobSeeker' && (<img
                                    onClick={() => handleFavorite(resumeId, isFavorite)}
                                    src={isFavorite ? favorite_heart : headerIcon2}
                                    className={isFavorite ? 'favorite-icon favorite' : 'favorite-icon'}
                                />)}
                                </div>

                        </div> */}
                          {(resume?.job_seeker?.active_for_jobs === "Yes" || resume?.job_seeker?.active_for_jobs === "yes") && (
                          
                              <div>
                                <label style={{ fontFamily:colors.fontFamily, background: colors.thirdColor, border: "1px solid ", color: "#fff", borderRadius: "4px", width: "130px", padding: "8px 8px" }}>
                                  Active For Job
                                </label>
                              </div>
                          )}

                        </div>
                        </div>
                    <div style={{ position: "relative" }}>
                      <img
                        src={rectangelImage}
                        alt="Image Line"
                        className="rectangel-image2"
                      />
                      <hr className="hr-style" />
                    </div>

                        <div className="d-flex justify-content-between CRjobpreview">
                          <div className="d-flex align-items-center CRjobid">
                            <label>
                              Posted On<span className="CRlabelcol">:</span>
                            </label>
                            <p>
                              {Helper.changeDateFormat(new Date(resume?.created_at))}
                            </p>
                          </div>
                          
                          <div className="d-flex align-items-center justify-content-end CRjobid">
                            <label>
                              Last Updated On<span className="CRlabelcol">:</span>
                            </label>
                            <p>{Helper.changeDateFormat(new Date(resume?.updated_at))}
                            </p>
                          </div>
                        </div>
                      </div>
                      
                      <div className="row align-items-center RP-head">
                        <div className="d-flex flex-row align-items-start user-content justify-content-between">
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {/* Add your image element here */}
                          <img className="profile-imageRP"
                        src={
                          resume?.job_seeker?.user?.image
                            ? `${STORAGE_URL}${resume.job_seeker.user.image}`
                            : avatar
                        }
                        alt="Profile"
                        style={{ marginRight: "10px" }}
                      />
                          <div className="d-flex gap-1 flex-column ">
                            {/*<h2>*/}
                            {/*  {resume?.first_name} {resume?.last_name}*/}
                            {/*</h2>*/}
                            {/*<p className="CR-job-title" style={{ marginTop: "-10px" }}*/}
                            {/*   dangerouslySetInnerHTML={{ __html: Helper.highlightJobTitle(resume?.preferred_job_title, matched_keywords) }}>*/}
                            {/*</p>*/}
                            <h2 dangerouslySetInnerHTML={{ __html: Helper.highlightJobTitle(`${resume?.first_name} ${resume?.last_name}`, matched_keywords) }}></h2>
                            <p className="CR-job-title" style={{ marginTop: "-10px" }} dangerouslySetInnerHTML={{ __html: Helper.highlightJobTitle(resume?.preferred_job_title, matched_keywords) }}></p>


                            {/*<div*/}
                            {/*  className="d-flex gap-2 flex-row align-items-center CRjobpreview"*/}
                            {/*  style={{ marginTop: "-8px" }}*/}
                            {/*>*/}
                            {/*  <label>*/}
                            {/*    Last Updated*/}
                            {/*    <span className="CRlabelcol">:</span>*/}
                            {/*  </label>*/}
                            {/*  <p>*/}
                            {/*    /!* {new Date(*/}
                            {/*      resume?.updated_at*/}
                            {/*    ).toLocaleDateString("en-US")} *!/*/}
                            {/*    {Helper.changeDateFormat(new Date(resume?.updated_at))}*/}
                            {/*  </p>*/}
                            {/*</div>*/}
                          </div>
                        </div>
                          {(resume?.job_seeker?.active_for_jobs ==="Yes" || resume?.job_seeker?.active_for_jobs ==="yes")&& (

                      // Icons Eye icon and favorite icone 
                        <div className="d-flex align-items-center gap-2">

                          {userable_type !== 'App\\Models\\JobSeeker' && resume?.viewed_resumes && resume?.viewed_resumes.length > 0 && (
                              <BsEye size={24} style={{ color: 'white' }}/>
                          )}

                          {userable_type !== 'App\\Models\\JobSeeker' && (
                              <Follow
                                  user={resume.job_seeker.user}
                                  setLoading={setLoading}
                              />
                          )}

                          {
                                userable_type !== 'App\\Models\\JobSeeker' && (
                                  isFavorite?
                                      (<BsHeartFill title="Un Favorite"
                                               onClick={() => handleFavorite(resumeId, isFavorite)}
                                               size={24}
                                               role="button"
                                               style={{ color: '#e54545'}}
                                  />)
                                  : (<BsHeart title="Favorite"
                                              onClick={() => handleFavorite(resumeId, isFavorite)}
                                              size={24}
                                              role="button"
                                              style={{  color: '#fff' }}/>
                                      )
                              )}

                        </div>
                        )}
                        </div>
                      </div>

                      {resume?.job_seeker?.user?.current_employee && (
                          <div className="row mt-3">

                                <div className="col-lg-12">
                                  <label>
                                    Current Job:{" "}
                                    <span className="CRlabelcol">&nbsp;</span>
                                  </label>
                                  <span>
                                    {resume?.job_seeker?.user?.current_employee}
                                  </span>
                                </div>
                          </div>
                        )}

                      <div className="row">
                        <div className="col-lg-12 CRjobpreview mt-3">
                          {resume?.work_authorization.work_authorization && (
                            <div className="col-lg-12">
                              <label>
                                Work Authorization:{" "}
                                <span className="CRlabelcol">&nbsp;</span>
                              </label>
                              <span>
                                {resume?.work_authorization.work_authorization}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="CRjobpreview-screen">
                        <div className="row">
                          <div className="col-lg-12 CRjobpreview">
                            {resume?.years_of_experiecne?.experience && (
                              <div className="col-lg-12">
                                <label>
                                  Years of Experience:{" "}
                                  <span className="CRlabelcol">&nbsp;</span>
                                </label>
                                <span>
                                  {resume?.years_of_experiecne.experience}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="CRjobpreview-screen">
                        <div className="row">
                          {/* {resume?.resume_availability_file_path && (
                                        <div className="col-lg-8 d-flex CRjobpreview">
                                            <div className="CRresumeicon">
                                                <HiOutlineClipboardDocumentCheck size={25}/>
                                                <p>
                                                    <button className="btn btn-secondary btn-sm me-2" style={{
                                                        color: colors.primaryColor,
                                                        border: "1px solid #003580",
                                                        background: "white",
                                                        fontWeight: "bold"
                                                    }} onClick={handleResumeClick}>
                                                        View Document Resume
                                                    </button>


                                                </p>
                                            </div>
                                        </div>
                                    )} */}
                          {resume?.resume_availability_video_file ? (
                              <div className="col-lg-4 d-flex CRjobpreview">
                              <div className="CRresumeicon">
                                {/*<BiVideo size={25} />*/}
                                <label>Video Resume: </label>
                                <p>
                                  <Button
                                    className="btn btn-primary btn-sm me-2"
                                    style={{
                                      color: colors.primaryColor,
                                      border: "1px solid #003580",
                                      background: "white",
                                      fontWeight: "bold",
                                      whiteSpace: "nowrap"
                                    }}
                                    onClick={() => setIsVideoModalOpen(true)}
                                  >
                                    View Video Resume
                                  </Button>
                                </p>
                                <Modal show={isVideoModalOpen} onHide={()=>handleCloseModal()} centered>
                                    <Modal.Header  >
                                        <Modal.Title>Video Resume</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                    <video
                                    src={
                                      STORAGE_URL +
                                      resume?.resume_availability_video_file
                                    }
                                    controls
                                    style={{
                                      width: "100%",
                                      height: "90%",
                                    }}
                                   />
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button 
                                          variant="danger"
                                          className="me-2"
                                          style={{ background: "#E54545" }}
                                          onClick={() => setIsVideoModalOpen(false)}>
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                              </div>
                            </div>
                          ) : (
                            <div className="col-lg-4 d-flex CRjobpreview">
                            <div className="CRresumeicon">
                            {/*<BiVideo size={25} />*/}
                              <label>Video Resume: </label>
                              <p>No</p>
                            </div>
                            </div>
                            )}                        </div>
                      </div>
                    </div>

                    <div style={{ position: "relative" }}>
                      <img
                        src={rectangelImage}
                        alt="Image Line"
                        className="rectangel-image"
                      />
                      <hr className="hr-style" />
                    </div>

                    {/*-------------------------Contact Details Section 2 -------------------- */}
                    <div className="CRgeneralpreview CRjobdescpreview ">
                      <div className="CRjobpreview-screen">
                        <div className="row">
                          <h4>Contact Details</h4>
                        </div>
                      </div>
                      {/*Job section 2 */}
                      <div className="CRjobpreview-screen">
                        <div className="row">
                          <div className="col-lg-12  CRjobpreview  ">
                            {resume?.address && (
                              <div className="col-lg-12 ">
                                <label>
                                  Address
                                  <span className="CRlabelcol">:&nbsp;</span>
                                </label>
                                <span>{resume?.address} </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="CRjobpreview-screen">
                        { (resume?.us_based==='Yes' || resume?.us_based==='yes')&&(
                            <div className="row">
                          <div className="col-lg-12  CRjobpreview  ">
                            {resume?.location && (
                              <div className="col-lg-12 ">
                                <label>
                                  Location
                                  <span className="CRlabelcol">:&nbsp;</span>
                                </label>

                                <span dangerouslySetInnerHTML={{ __html: Helper.highlightLocation(resume.location, matched_location) }}></span>

                              </div>
                            )}
                          </div>
                        </div>
                        )}
                        {(resume?.us_based==='No' || resume?.us_based==='no') &&(

                            <div className="row">
                          <div className="col-lg-12  CRjobpreview  ">
                            {resume?.country && (
                                <div className="col-lg-12 ">
                                  <label>
                                    Location
                                    <span className="CRlabelcol">:&nbsp;</span>
                                  </label>

                                  <span dangerouslySetInnerHTML={{ __html: Helper.highlightLocation(resume?.country?.name, matched_location) }}></span>

                                  {resume?.state && (
                                      <>
                                        <span>, </span>
                                        <span dangerouslySetInnerHTML={{ __html: Helper.highlightLocation(resume.state, matched_location) }}></span>
                                        {resume?.city && <span>, </span>}
                                      </>
                                  )}

                                  <span dangerouslySetInnerHTML={{ __html: Helper.highlightLocation(resume?.city, matched_location) }}></span>


                                </div>
                            )}
                          </div>
                        </div>
                        )}

                      </div>

                      {/*Job section 3 */}
                      <div className="CRjobpreview-screen">
                        <div className="row">
                          <div className="col-lg-12  CRjobpreview  ">
                            {resume?.mobile && (
                              <div className="col-lg-12">
                                <label>
                                  Mobile
                                  <span className="CRlabelcol">:&nbsp;</span>
                                </label>
                                <span>{resume?.mobile}</span>
                                {resume.preferred_number=="mobile" &&(
                                    <TiTickOutline  title="This is the preferred number" role="button" style={{marginLeft:"10px", color:colors.thirdColor}} size={24}/>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="CRjobpreview-screen">
                        <div className="row">
                          <div className="col-lg-12  CRjobpreview  ">
                            {resume?.home && (
                              <div className="col-lg-12 ">
                                <label>
                                  Home
                                  <span className="CRlabelcol">:&nbsp;</span>
                                </label>
                                <span>{resume?.home} </span>
                                {resume.preferred_number=="home" &&(
                                    <TiTickOutline  title="This is the preferred number" role="button" style={{marginLeft:"10px", color:colors.thirdColor}} size={24}/>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {/*Job section 4 */}
                      <div className="CRjobpreview-screen">
                        <div className="row">
                          <div className="col-lg-12  CRjobpreview  ">
                            {resume?.office && (
                              <div className="col-lg-6 ">
                                <label>
                                  Office
                                  <span className="CRlabelcol">:&nbsp;</span>
                                </label>
                                <span>{resume?.office}</span>
                                {resume.preferred_number=="office" &&(
                                    <TiTickOutline  title="This is the preferred number" role="button" style={{marginLeft:"10px", color:colors.thirdColor}} size={24}/>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="CRjobpreview-screen">
                        <div className="row">
                          <div className="col-lg-12  CRjobpreview  ">
                            {resume?.email && (
                                <div className="col-lg-12 ">
                                  <label>
                                    Alternative Email
                                    <span className="CRlabelcol">:&nbsp;</span>
                                  </label>
                                  <span>{resume?.email} </span>
                                </div>
                            )}
                          </div>
                        </div>
                      </div>

                    </div>

                    <div style={{ position: "relative" }}>
                      <img
                        src={rectangelImage}
                        alt="Image Line"
                        className="rectangel-image"
                      />
                      <hr className="hr-style" />
                    </div>
                    {/*------------------------- Location Preference Section 3 -------------------- */}
                    <div className="CRgeneralpreview CRjobdescpreview ">
                      <div className="CRjobpreview-screen">
                        <div className="row">
                          <h4>Location Preference</h4>
                        </div>
                      </div>
                      <div className="CRjobpreview-screen">
                        <div className="row">
                          <div className="col-lg-12  CRjobpreview  ">
                            <div className="col-lg-6 ">
                              <label>
                                Relocation
                                <span className="CRlabelcol">:&nbsp;</span>
                              </label>
                              <span>{resume?.relocation}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="CRjobpreview-screen">
                        {(resume?.relocation === "Yes" || resume?.relocation === "yes") &&
                          resume?.relocation_option === "US" && (

                            <div className="row">
                              {resume?.anywhere_in_us === 1 ? (
                                <>
                                  <div className="col-lg-6">
                                    <label>
                                      Preferred Location(s)
                                      <span className="CRlabelcol">
                                        :&nbsp;
                                      </span>
                                    </label>
                                    <span>Anywhere in US</span>
                                  </div>
                                </>
                              ) : (
                                <>
                                {resume.preferred_location1 !== '' || resume?.preferred_location2 !== '' || resume?.preferred_location3 !== '' ? (

                                        <div className="col-lg-6">
                                    <label>
                                      Preferred Location(s)
                                      <span className="CRlabelcol">
                                        :&nbsp;
                                      </span>
                                    </label>
                                  </div>
                                ) : null}


                                  <div className="col-lg-12 CRjobpreview">
                                    <div className="col-lg-12">
                                      <p>
                                        <br></br>
                                      </p>
                                      <ul className="list-group">
                                        {resume?.preferred_location1 && (
                                          <li className="list-group-item">
                                            {resume?.preferred_location1}
                                          </li>
                                        )}
                                        {resume?.preferred_location2 && (
                                          <li className="list-group-item">
                                            {resume?.preferred_location2}
                                          </li>
                                        )}
                                        {resume?.preferred_location3 && (
                                          <li className="list-group-item">
                                            {resume?.preferred_location3}
                                          </li>
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          )}

                        {(resume?.relocation === "Yes" || resume?.relocation === "yes") &&
                          resume?.relocation_option === "Local" && (
                            <div className="row">
                              {resume?.anywhere_in_country === 1 ? (
                                <>
                                  <div className="col-lg-6">
                                    <label>
                                      Preferred Location(s)
                                      <span className="CRlabelcol">
                                        :&nbsp;
                                      </span>
                                    </label>
                                    <span>Anywhere in Country</span>
                                  </div>
                                </>
                              ) : (
                                <>
                                {resume?.country_id !== '' && (resume?.state1 !== '' || resume?.city1 !== '' ||resume?.state2 !== '' || resume?.city2 !== ''||resume?.state3 !== '' || resume?.city3 !== '') ?(

                                        <div className="col-lg-6">
                                    <label>
                                      Preferred Location(s)
                                      <span className="CRlabelcol">
                                        :&nbsp;
                                      </span>
                                    </label>
                                  </div>
                                ) : null}

                                  <div className="col-lg-12 CRjobpreview">
                                    <div className="col-lg-12">
                                      <p>
                                        <br></br>
                                      </p>
                                      <ul className="list-group">
                                        {resume?.state1 && (
                                          <li className="list-group-item">
                                            {resume?.state1}
                                            {resume?.city1 &&
                                              `, ${resume?.city1}`}
                                          </li>
                                        )}
                                        {resume?.state2 && (
                                          <li className="list-group-item">
                                            {resume?.state2}
                                            {resume?.city2 &&
                                              `, ${resume?.city2}`}
                                          </li>
                                        )}
                                        {resume?.state3 && (
                                          <li className="list-group-item">
                                            {resume?.state3}
                                            {resume?.city3 &&
                                              `, ${resume?.city3}`}
                                          </li>
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                      </div>
                    </div>

                    <div style={{ position: "relative" }}>
                      <img
                        src={rectangelImage}
                        alt="Image Line"
                        className="rectangel-image"
                      />
                      <hr className="hr-style" />
                    </div>
                    {/*------------------------- Job Type Section 4 -------------------- */}
                    <div className="CRgeneralpreview CRjobdescpreview ">
                      <div className="CRjobpreview-screen">
                        <div className="row">
                          <h4>Job Type</h4>
                        </div>
                      </div>

                      <div className="CRjobpreview-screen">
                        <div className="row">
                          <div className="col-lg-12  CRjobpreview  ">
                            {resume?.preferred_job_type && (
                              <div className="col-lg-12 ">
                                <label>
                                  Preferred Job Type
                                  <span className="CRlabelcol">:&nbsp;</span>
                                </label>
                                {resume?.preferred_job_type === 'Any' ? 'Any (FT, Contract, 1099)' : resume?.preferred_job_type}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="CRjobpreview-screen">
                        <div className="row">
                          <div className="col-lg-12  CRjobpreview  ">
                            {resume?.preferred_job_type === "Contract" && (
                              <div className="col-lg-12 ">
                                <label>
                                  Contract Type
                                  <span className="CRlabelcol">:&nbsp;</span>
                                </label>
                                <span>{resume?.contract_type}</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      {resume?.preferred_job_type === "Contract" && (
                        <div className="CRjobpreview-screen">
                          <div className="row">
                            <div className="col-lg-12  CRjobpreview  ">
                              {resume?.independent_c2c_company_name &&(
                              <div className="col-lg-12 ">
                                <label>
                                  Independent/C2C Company Name
                                  <span className="CRlabelcol">:&nbsp;</span>
                                </label>
                                <span>
                                  {resume?.independent_c2c_company_name}
                                </span>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div style={{ position: "relative" }}>
                      <img
                        src={rectangelImage}
                        alt="Image Line"
                        className="rectangel-image"
                      />
                      <hr className="hr-style" />
                    </div>
                    {/*------------------------- Salary Expectation Section 5 -------------------- */}
                    <div className="CRgeneralpreview CRjobdescpreview ">
                      <div className="CRjobpreview-screen">
                        <div className="row">
                          <h4>Salary Expectation</h4>
                        </div>
                        <div className="CRjobpreview-screen">
                          <div className="row">
                            <div className="col-lg-12  CRjobpreview  ">
                              {resume?.salary_expectations && (
                                <div className="col-lg-12 ">
                                  <label>
                                    Annual Salary
                                    <span className="CRlabelcol">:&nbsp;</span>
                                  </label>
                                  <span>
                                    {"$" + resume?.salary_expectations}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="CRjobpreview-screen">
                          <div className="row">
                            <div className="col-lg-12  CRjobpreview  ">
                              {resume?.expected_hourly_rate && (
                                <div className="col-lg-12 ">
                                  <label>
                                    Hourly Rate
                                    <span className="CRlabelcol">:&nbsp;</span>
                                  </label>
                                  <span>
                                    {"$" + resume?.expected_hourly_rate}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ position: "relative" }}>
                      <img
                        src={rectangelImage}
                        alt="Image Line"
                        className="rectangel-image"
                      />
                      <hr className="hr-style" />
                    </div>
                    {/*------------------------- Skills Section 6 -------------------- */}
                    <div className="CRgeneralpreview CRjobdescpreview ">
                      <div className="CRjobpreview-screen">
                        <div className="row">
                          <h4>Skills</h4>
                        </div>
                      </div>
                      <div className="CRjobpreview-screen">
                        <div className="row">
                          <div className="col-lg-12  CRjobpreview  ">
                            {/*<div className="col-lg-2 ">*/}
                            {/*    <label>Skills<span className="CRlabelcol" >:</span></label>*/}
                            {/*</div>*/}

                            {/*<div className="col-lg-9 three-d-perspective">*/}
                            {/*    <p>Java (Expert), Angular (Intermediate), JavaScript (Basic), HTML (Expert), CSS (Expert)</p>*/}
                            {/*</div>*/}
                          </div>

                          <div
                            className="col-lg-12 three-d-perspective"
                            style={{ marginTop: "10px" }}
                          >
                            <ul className="list-group">
                              {resume?.skills.map((skill, index) => (
                                <li
                                  className="list-group-item d-flex justify-content-between align-items-center"
                                  key={index}
                                >
                                  <span
                                      className="info-span"
                                      key={index}
                                      dangerouslySetInnerHTML={{
                                        __html: Helper.highlightSkills(skill.skill_set.name, matched_keywords)
                                      }}
                                  ></span>

                                  <span
                                    className="badge rounded-pill"
                                    style={{
                                      backgroundColor: getBadgeColor(
                                        skill.skill_set_level
                                      ),
                                      color: "#FFF",
                                    }}
                                  >
                                    {skill.skill_set_level}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />
                  </div>
                </div>
              </div>
              {/*---------------------Resume Preview screen---------------------*/}
              {/*{console.log(resumeFile)}*/}
              {resumeFile && (
                <div className={`${isMiniScreen?'col-lg-12':'col-lg-6'}`}>
                  <div className="CRpostpreviewcontainer">
                    <hr className="custom-hr" />
                    <div className=" CRpdf">

                      {pdfCheck?
                          <PdfViewer resumeFile={resumeFile} matchedKeywords={matched_keywords} />
                          :
                          (<DocViewer resumeFile={resumeFile} />)
                      }

                    </div>
                  </div>
                </div>
              )}

            </div>
          </div>
        </div>
      </div>
      {fetchError &&  <ErrorHandlingComponent error={fetchErrorMessage} />}

    </div>
  );
}

export default ResumeDetailsScreen;
