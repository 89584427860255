import React from "react";


export const AppliedJobs = ({
                                jobSeeker,
                                handleAppliedFavJobClick,
                                handleCompanyImg,
                                viewAllAppliedJobsHandler
                            })=>{

    return(
        <div className="analytics-rd">
            {jobSeeker?.job_seeker?.job_applications?.length > 0 &&(
                <div className="resume-rd">
                    <h2>
                        Jobs Applied
                    </h2>
                    {/*{jobSeeker && jobSeeker.job_seeker.job_applications.length > 3 && (*/}
                    <p style={{cursor:"pointer"}} onClick={viewAllAppliedJobsHandler}>View All <i className="fas fa-chevron-right"></i></p>
                    {/*)}*/}
                </div>
            )}
            {/* Display Job Applications */}
            {jobSeeker?.job_seeker?.job_applications?.length > 0 &&
                jobSeeker?.job_seeker?.job_applications?.slice(0,3).map((application) => {
                    const jobAd = application.job_ad;

                    return (
                        <div className="activejob-rd" key={application.id}>
                            {/*<img src={STORAGE_URL+application.job_ad.employer.users[0].image} alt="logo" />*/}
                            {handleCompanyImg(application)}
                            <div className="activejob-rdtxt"   onClick={() => handleAppliedFavJobClick(jobAd.id)}>
                                <h6>
                                    {jobAd?.job_title}
                                </h6>
                                <p>
                                    {jobAd?.location}
                                </p>
                                <button className="btn btn-secondary btn-sm" disabled>
                                    {application?.job_application_status?.name}
                                </button>
                            </div>
                        </div>
                    );
                })}
        </div>
    )
}