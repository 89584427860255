import React from "react";


export const JobType = ({
                            resumeCounter,
                            filter,
                            handleJobType
                        })=>{

    return(
        <div className="resumeupdate work-authoriz work-authorizbord">
            <h2>Job Type</h2>
            {resumeCounter?.jobTypeCount.map((item) => (
                <div className="work-experience" key={item.type}>
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                value={item.type}
                                checked={
                                    filter.job_type.some(id => id === item.type)
                                }
                                onClick={()=>handleJobType(item.type)}
                            />
                            {item.type}
                        </label>
                    </div>
                    <p></p>
                    {/*<p>{item.count}</p>*/}
                </div>
            ))}
        </div>
    )
}