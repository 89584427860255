import React, {useEffect, useRef, useState} from "react";

import './assets/css/header.css';
import '../src/assets/css/style_v1.css';
import rectangelImage from "./assets/rectangel.png";
import EmployerHeader    from "./EmployerHeader";
import post1 from '../src/assets/post1.svg';
import {useNavigate} from "react-router-dom";
import { Modal,Button } from "react-bootstrap";
import {BsEmojiSmile, BsThreeDots} from "react-icons/bs";
import axios from "axios";
import BASE_URL, {STORAGE_URL} from "./config";
import styles from "./assets/css/LoadingComponent.module.css";
import avatar from "./assets/profile5.png";
import SocialMediaPosts from "./SocialMediaPost";
import {useDispatch, useSelector} from "react-redux";
import Helper from "./Helper";
import {useMediaQuery} from "react-responsive";
import {EmployerSideBars} from "./components/Sidbars/EmpSideBars";
import {JobAd} from "./components/JobAd/JobAd";
import {CustomDivider} from "./components/Sidbars/SideBarContent/Divider";
import {UserInfo} from "./components/Sidbars/SideBarContent/userInfo";
import {JobAnalytics} from "./components/Sidbars/SideBarContent/JobAnalytics";
import {ResumeAnalytics} from "./components/Sidbars/SideBarContent/ResumeAnalytics";
import {FavResumeContent} from "./components/Sidbars/SideBarContent/FavResumeContent";
import {MyCircle} from "./components/Sidbars/SideBarContent/MyCircle";
import {Emoji} from "./components/Emoji/Emoji";
import {BiSolidImage} from "react-icons/bi";
import ErrorModal from "./ErrorModal";
import {ErrorHandlingComponent} from "./ErrorHandlingComponent";
import {addUser} from "./store/slices/UserSlice";
import UserFetch from "./UserFetch";


function EmployerDashboard_v1(){
    const navigate = useNavigate();

 const [isPostCreateVisible, setIsPostCreateVisible] = useState(false);
    const [userInput, setUserInput] = useState('');
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [jobId, setjobId] = useState("");
    const [contactType, setContactType] = useState({});
    const [socialMediaPosts, setSocialMediaPosts] = useState([]);
    const [postErrors, setPostErrors] = useState({});
    const [reloadPosts, setReloadPosts] = useState(0); // State to trigger reloading posts
    const dispatch = useDispatch();

    const [images, setImages] = useState([]); // Initialize images state with an empty array
    const [favoriteResumes, setFavoriteResumes] = useState([]);
    const [showResumeDropdown, setShowResumeDropdown] = useState(false);
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const containerRef1 = useRef(null);
    const [showPicker, setShowPicker] = useState(false);
    const [imageLabel, setImageLabel] = useState("")
    const textareaMaxChars = 5000;
    const [fetchError, setFetchError] = useState(false);
    const [fetchErrorMessage, setFetchErrorMessage] = useState(false);
    let token = localStorage.getItem("access_token");
    let headers = {
        Authorization: `Bearer ${token}`,
    };
    const user_data = useSelector((state) => {
        return state?.user?.user;
    });
    const isMiniScreen = useMediaQuery({ query: '(max-width: 1024px)' })
    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (!event.target.closest(".js_container_div")) {
                setShowResumeDropdown(false);
            }
        };

        document.addEventListener("click", handleDocumentClick);

        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, []);

    const handleResumeOptionClick = (index) => {

        if(openDropdownIndex === index){
            setOpenDropdownIndex(null);
            setShowResumeDropdown(false)
        }else{
            setOpenDropdownIndex(index);
            setShowResumeDropdown(true)
        }

    };

    const handleEditJobClick = (jobId) => {
        const from="/employer_dashboard";
        navigate("/edit_a_job", { state: { jobId,from } });
    };
    const handleDeleteJobClick = (jobId) => {
        setIsDeleteModalOpen(true);
        setjobId(jobId);
    };

    const handleCloseModal =()=>{
        setIsDeleteModalOpen(false)
    }
    const handleConfirmDeleteJob = () => {
        const payload = {
            employer_id: parseInt(employerId)
        };
        axios
        .delete(`${BASE_URL}/job_ad/` + jobId, {
          headers: headers, data:payload
        })
        .then((response) => {
          setLoading(false);
          setIsDeleteModalOpen(false);
          fetchEmployerData();
        })
        .catch((error) => {
          // Handle error
            setLoading(false);
            setFetchError(true)
            setFetchErrorMessage(error)
        });
    };

    const handleConfirmDeleteResume = async (resumeId) => {
        setLoading(true);
        const url = `${BASE_URL}/fav_resumes/${resumeId}`
        const method = 'DELETE';
        const payload = {
            employer_id: employerId,
            resume_id: resumeId
        };

        try {
            const response = await axios({
                method: method,
                url: url,
                headers: headers,
                data: payload  // Axios uses 'data' instead of 'body' for the request payload
            });

            const data = response.data;
            if(data.status==200){
                setOpenDropdownIndex(null)
                fetchEmployerData();
            }

            // Handle the response data here
        } catch (error) {
            setLoading(false)
            setFetchError(true)
            setFetchErrorMessage(error)
        }
    };


    const handleInputChange = (e) => {
        const inputValue = e.target.value;

        if (inputValue.length <= textareaMaxChars) {
            setUserInput(inputValue);
            setPostErrors((prevPostErrors) => ({
                ...prevPostErrors,
                userInput: "", // Clear any previous errors
            }));
        } else {
            // Display an error message when the input exceeds the character limit
            setPostErrors((prevPostErrors) => ({
                ...prevPostErrors,
                userInput: "Input exceeds the maximum character limit of "+textareaMaxChars+".",
            }));
        }
    };

    const handleImageChange = (event) => {
        const fileList = Array.from(event.target.files);
        const fileNames = fileList.map((file) => file.name);
        setImageLabel(fileNames);
        setImages(fileList);
        setPostErrors((prevPostErrors) => ({
            ...prevPostErrors,
            images: "",
        }));
    };
    const handlePostSubmit = async () => {
        try {
            const errors = {};
            if (!userInput.trim()) {
                errors.userInput = "Post content is required.";
            } else   if (postErrors.userInput) {
                return false; // Return false if there are errors
            }


            // Add additional validations for the images if required
            if (images && images.length > 0) {
                images.forEach((imageFile, index) => {
                    if (imageFile) {
                        // Check file type and size before appending
                        if (
                            imageFile.type !== "image/jpeg" &&
                            imageFile.type !== "image/png"
                        ) {
                            errors.images =
                                "Invalid file type. Only JPEG and PNG are allowed.";
                        } else if (imageFile.size > 5242880) {
                            errors.images = "File size exceeds 5 MB limit.";
                        }
                    }
                });
            }

            // Check if there are any errors
            if (Object.keys(errors).length > 0) {
                setPostErrors(errors);
                return;
            }

            setLoading(true);
            const formData = new FormData();
            console.log(userInput)
            formData.append("content", userInput);

            formData.append("user_id", employer?.employer_data?.users[0]?.id);

            // Append each image file to the formData
            if (images && images.length > 0) {
                images.forEach((imageFile, index) => {
                    if (imageFile) {
                        formData.append(`images[${index}]`, imageFile);
                    }
                });
            }

            // Send the formData to the API endpoint using fetch
            const response = await fetch(`${BASE_URL}/posts`, {
                method: "POST",
                body: formData,
                headers,
            });
            if (response.ok) {
                // Clear the file input field
                setImageLabel([])
                setPosts([...posts, userInput]);
                setUserInput("");
                setImages([]);
                setIsPostCreateVisible(false);
                setLoading(false)
                document.getElementById("postimg").value = "";
                setReloadPosts(reloadPosts + 1);

            }
        } catch (error) {
            setLoading(false)
            setFetchError(true)
            setFetchErrorMessage(error)
        }
    };


    const [employer, setEmployer] = useState(null);
    const employerId = localStorage.getItem("employerId");
    const textareaRef = useRef(null);



    useEffect(() => {
        if (isPostCreateVisible) {
            textareaRef.current.focus();
        }
    }, [isPostCreateVisible]);

    if(token === null || token === undefined ){
        token = localStorage.getItem("access_token");
        headers = {
            Authorization: `Bearer ${token}`
        };
    }
    async function fetchFilterData() {
        try {
            const response = await fetch(`${BASE_URL}/job_ads/employer_job_counters/${employerId}`, { headers });
            const data = await response.json();

            if (data.status === 200) {
                setContactType(data.jobs);
            }
        } catch (error) {
            setLoading(false); // Ensure loading state is set to false
            setFetchError(true)
            setFetchErrorMessage(error)
        }
    }

    async function fetchEmployerData() {
        try {
            const response = await fetch(`${BASE_URL}/employer_dashboard_data/${employerId}`,{
                headers,
            });

            const data = await response.json();
            console.log('Employer')
            console.log(data)
            if (data.status === 200) {
                UserFetch()
                    .then((response) => {
                        const user_data = response.data.user;
                        // console.log(user_data, "user data");
                        dispatch(addUser(user_data));
                    })
                    .catch((error) => {
                        console.error("Error fetching user:", error);
                    });
                console.log(data);
                setLoading(false);
                setEmployer(data);
                setFavoriteResumes(data?.employer_data?.favorite_resume_user_resumes);
            }
        } catch (error) {
            setLoading(false); // Ensure loading state is set to false
            setFetchError(true)
            setFetchErrorMessage(error)

        }

    }
    useEffect(() => {
        fetchFilterData();
        fetchEmployerData();
    }, []);

    // if (!employer) {
    //     return <div>Loading...</div>;
    // }

    const togglePostCreate = () => {
        setIsPostCreateVisible(!isPostCreateVisible);
    };
    const postCreateStyle = {
        display: isPostCreateVisible ? "block" : "none",
        // add any other styles here
    };
    const viewAllHandler = ()=>{
        const defaultfrom="/employer_dashboard";
        navigate('/alljobs', { state: { defaultfrom } });
    }
    const viewAllResumeHandler = () => {
        const defaultfrom = "/employer_dashboard";
        navigate("/fav-resumes", {
            state: { defaultfrom } // Pass the favoriteResumes state
        });
    };



// Function to show modal with title and message
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalMessage, setModalMessage] = useState('');

    const showModalWithMessage = (title, message) => {
        setModalTitle(title);
        setModalMessage(message);
        setShowModal(true);
    };
    const handleResumeClick = (resumeId) => {
        setLoading(true)
        axios.get(`${BASE_URL}/get-user-data`, {
            headers,
        })
            .then((res) => {
                const userData = res.data.user
                const viewedResumesIds=userData?.userable?.viewed_resumes_ids;
                const subscription = userData.userable.subscription;
                // Check if the resume has been viewed
                const viewedResumeIds = viewedResumesIds?.map(viewedResume => viewedResume?.resume_id);
                if (viewedResumeIds?.includes(resumeId)) {
                    setLoading(false);
                    const from="/employer_dashboard";
                    navigate('/resumes', { state: { resumeId,from } });
                    return;
                }
                // Check if subscription is expired
                const endDate = new Date(subscription.end_date);
                const server_time = new Date(res.data?.server_time);

                if (endDate < server_time) {
                    setLoading(false)
                    showModalWithMessage("Subscription Expired", "Please contact admin to renew your subscription.");
                    return;
                }

                // Check if remaining jobs are zero or less
                if (subscription.remaining_views <= 0) {
                    setLoading(false)
                    showModalWithMessage("No Remaining Views", "Your resumes views limit has been reached. Please contact admin for assistance.");
                    return;
                }
                // If subscription is active and Views are remaining, navigate to post job page
                const from="/employer_dashboard";
                navigate('/resumes', { state: { resumeId,from } });
            })
            .catch((err) => {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(err)
            });
    };

    const handlePreviewJob =(jobId)=>{
        const from="/employer_dashboard";
        navigate('/post_a_job_preview', { state: { jobId,from } });
    }

    const handleProfileClick = () => {
        navigate("/create_profile");
    };
    const createUpdateProfile = () => {
        navigate("/create_profile");
    };

    const handleUserImg = (user)=>{

        let img =Helper.profile_log(user)
        return img;

    }
    const handleFollowFollowing = () => {

        const from="/employer_dashboard";
        navigate("/follow", { state: { from } });
    }
    const showEmoji = () => {
        setShowPicker(!showPicker);
    };

    return (
            <div className='page-wrapper'>
                <ErrorModal
                    show={showModal}
                    handleClose={() => setShowModal(false)}
                    title={modalTitle}
                    message={modalMessage}
                />
                {/*Header*/}
                <EmployerHeader/>
                {loading && (
                    <div className={styles.loadingOverlay}>
                        <div className={styles.loadingSpinner}></div>
                    </div>
                )}
                {/*---------------------------------------------------------------*/}
                {/*                        MAin Body Start                        */}
                {/*---------------------------------------------------------------*/}
                <div className="maindashbordv1">
                    <div className="container">
                        {/*---------------------------------Main section -----------------*/}
                        <div className="mainpagev1">
                            {/*---------------------------First Column ---------------------*/}
                            <div className="resumecolv1">

                                <UserInfo
                                   employer={employer}
                                   createUpdateProfile={createUpdateProfile}
                                   handleProfileClick={handleProfileClick}
                                 />
                                <CustomDivider />
                                <h2>Analytics</h2>
                                <br/>
                                <JobAnalytics
                                      employer={employer}
                                       contactType={contactType}
                                    />
                                <CustomDivider />
                                {/*--------------- 2nd section of first column -----------------*/}
                                <ResumeAnalytics
                                 employer={employer}
                                       />
                                <CustomDivider />
                                {/*--------------- 3rd section of first column -----------------*/}
                            <FavResumeContent
                           employer={employer}
                           viewAllResumeHandler={viewAllResumeHandler}
                           handleUserImg={handleUserImg}
                           handleResumeClick={handleResumeClick}
                           containerRef1={containerRef1}
                           handleResumeOptionClick={handleResumeOptionClick}
                           openDropdownIndex={openDropdownIndex}
                           showResumeDropdown={showResumeDropdown}
                           handleConfirmDeleteResume={handleConfirmDeleteResume}
                             />
                            </div>

                            {/*--------------------------- Second  Column ---------------------*/}
                            <div className="postcol-rd">
                                {/*----------- mini screen filter -----------*/}
                                {isMiniScreen &&(<EmployerSideBars
                                    handleProfileClick={handleProfileClick}
                                    createUpdateProfile={createUpdateProfile}
                                    employer={employer}
                                    viewAllResumeHandler={viewAllResumeHandler}
                                    handleUserImg={handleUserImg}
                                    handleResumeClick={handleResumeClick}
                                    containerRef1={containerRef1}
                                    handleResumeOptionClick={handleResumeOptionClick}
                                    openDropdownIndex={openDropdownIndex}
                                    showResumeDropdown={showResumeDropdown}
                                    handleConfirmDeleteResume={handleConfirmDeleteResume}
                                    viewAllHandler={viewAllHandler}
                                    handlePreviewJob={handlePreviewJob}
                                    handleEditJobClick={handleEditJobClick}
                                    handleDeleteJobClick={handleDeleteJobClick}
                                    handleConfirmDeleteJob={handleConfirmDeleteJob}
                                    isDeleteModalOpen={isDeleteModalOpen}
                                    handleCloseModal={handleCloseModal}
                                    setOpenDropdownIndex={setOpenDropdownIndex}
                                    contactType={contactType}
                                    user_data={user_data}
                                    handleFollowFollowing={handleFollowFollowing}
                                />)}
                                {/*------------------- Creat Post ------------------------*/}

                                <div className="creatpost">
                                    {employer?.employer_data?.users[0].firstname ||
                                    employer?.employer_data?.users[0].lastname ? (
                                        <>
                                            <p onClick={togglePostCreate}>What's on your mind?</p>
                                            <div id="postcreat" style={postCreateStyle}>

                                          <textarea
                                              name="postText"
                                              ref={textareaRef}
                                              cols={30}
                                              rows={8}
                                              placeholder=""
                                              value={userInput}
                                              onChange={handleInputChange}
                                              style={{ padding: "10px" }}
                                          />
                                                {postErrors.userInput && (
                                                    <span className="text-danger">
                                                      {postErrors.userInput}
                                                    </span>
                                                )}

                                                <div className="col-12 d-flex ">
                                                    <div className="col-6 postmaterial">
                                                        <div
                                                            className="postmaterial align-items-center gap-2 "
                                                        >
                                                            <div style={{position:'relative'}}>
                                                                <BsEmojiSmile
                                                                    size={24}
                                                                    role="button"
                                                                    onClick={showEmoji}
                                                                    title="select emoji"
                                                                ></BsEmojiSmile>

                                                                {showPicker && (<Emoji
                                                                        showPicker={showPicker}
                                                                        setShowPicker={setShowPicker}
                                                                        setUserInput={setUserInput}
                                                                        userInput={userInput}
                                                                    />
                                                                )}
                                                            </div>
                                                            <label htmlFor="postimg" role="button">
                                                                <BiSolidImage size={28} title="Select Image" />

                                                            </label>
                                                            <input
                                                                type="file"
                                                                name="postimg"
                                                                style={{ display: "none" }}
                                                                id="postimg"
                                                                multiple
                                                                onChange={handleImageChange}
                                                            />

                                                            {imageLabel[0]}
                                                        </div>

                                                        {/*<img src={post2}/>*/}
                                                        {/*<img src={post3}/>*/}
                                                    </div>

                                                    <div className="col-6 d-flex justify-content-end align-items-baseline postpublish">
                                                        {/*<select name="posttypecat" id="posttype-cat">*/}
                                                        {/*    <option value="Anyone">Anyone</option>*/}
                                                        {/*    <option value="my-circle">My Circle</option>*/}
                                                        {/*</select>*/}
                                                        <button onClick={handlePostSubmit}>Post</button>
                                                    </div>
                                                </div>
                                                {postErrors.images && (
                                                    <span className="text-danger">{postErrors.images}</span>
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        <p>Posts will be shown here after completing your profile</p>
                                    )}
                                </div>

                                <div className="js-dashboard-posts-section">
                                    {employer?.employer_data?.users[0].firstname ||
                                    employer?.employer_data?.users[0].lastname ? (

                                        <SocialMediaPosts key={reloadPosts}  />
                                    ) : null}
                                </div>
                            </div>
                            {/* --------------------------------Third Column ----------------------*/}
                            <div className="dashboardcolv1">
                                {/*-------------------------------My circle -------------*/}
                                <MyCircle
                          user_data={user_data}
                          handleFollowFollowing={handleFollowFollowing}
                                 />
                                <CustomDivider />
                                {/*--------------------Active Jobs ------------------*/}

                                <div className=" analyticsv1">
                                    <div className="resumev1">
                                        <h2> Active Jobs</h2>
                                        {/*{employer?.employer_data?.job_ads.length > 3 && (*/}
                                            <p onClick={viewAllHandler}>View All <i className="fas fa-chevron-right"></i></p>
                                         {/*)}*/}
                                    </div>
                                    {employer?.employer_data?.job_ads.filter(ads => ads.job_status_id == 1).slice(0, 3).map((ads) => (
                                        <JobAd
                                            key={ads.id}
                                            ad={ads}
                                            handlePreviewJob={handlePreviewJob}
                                            handleEditJobClick={handleEditJobClick}
                                            handleDeleteJobClick={handleDeleteJobClick}
                                            handleConfirmDeleteJob={handleConfirmDeleteJob}
                                            isDeleteModalOpen={isDeleteModalOpen}
                                            handleCloseModal={handleCloseModal}
                                            setOpenDropdownIndex={setOpenDropdownIndex}
                                        />

                                    ))}

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {fetchError &&  <ErrorHandlingComponent error={fetchErrorMessage} />}

            </div>
        );
    }

export default EmployerDashboard_v1;