import React from "react";


export const CustomModalStyle = ()=>{

    return (
        <style>
            {`
                        .modal.right.fade .modal-dialog {
                          position: fixed;
                          top: 0;
                          right: 0;
                          margin-right: 0;
                          height: 100%;
                          width: 100%;
                          transform: translateX(100%);
                          transition: transform 0.4s ease-in-out;
                        }
                        
                        .modal.right.fade.show .modal-dialog {
                          transform: translateX(0);
                        }
                    
                        .modal-body-scrollable {
                          max-height: 80vh; /* Adjust this value as needed */
                          overflow-y: auto;
                          padding: 15px !important; 
                        }
                        .modal-body-scrollable::-webkit-scrollbar {
                            width: 0;
                        }
                          .modal-body-scrollable1 {
                          max-height: 80vh; /* Adjust this value as needed */
                          overflow-y: auto;
                          padding: 0px !important; 
                        }
                        .modal-body-scrollable1::-webkit-scrollbar {
                            width: 0;
                        }
                      `}
        </style>
    )
}