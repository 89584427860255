import React from 'react';
import PageHeader from "./PageHeader";
import '../src/assets/css/staticpages.css'; // Import the CSS file
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import colors from "./colors";
import {FaSackDollar} from "react-icons/fa6";
import {HiCurrencyDollar} from "react-icons/hi";

const Pricing = () => {
    const navigate = useNavigate();

    return (
        <>
            <PageHeader />
            <div className="sp-about-us-content">
                <div className="sp-background-style">
                    <div className="AJbackbtn">
                        <button onClick={() => navigate('/')}><BsArrowLeft /> Back</button>
                    </div>
                    <h1>
                        <FaSackDollar className="mb-1" style={{ color: colors.primaryColor }} /> Pricing
                    </h1>
                    <div className="content-container">
                        <div className="sp-text-content">
                            <p>
                                Explore Rezmaze's pricing options to find the plan that suits your professional needs. We offer flexible pricing to accommodate individuals, job seekers, and businesses of all sizes.
                            </p>

                            <p>
                                <strong>Individual Plans:</strong>
                            </p>

                            <ul>
                                <li>
                                    <HiCurrencyDollar className="mb-1" style={{ color: colors.primaryColor }} /> <strong>Free Plan:</strong> Get started with our free plan to access essential features for job searching and professional development.
                                </li>
                                <li>
                                    <HiCurrencyDollar className="mb-1" style={{ color: colors.primaryColor }} /> <strong>Premium Plan:</strong> Upgrade to our premium plan for enhanced features, such as unlimited access to job listings, advanced search filters, and priority support.
                                </li>
                            </ul>

                            <p>
                                <strong>Business Plans:</strong>
                            </p>

                            <p>
                                Whether you're a small business or a large enterprise, Rezmaze offers customizable plans to meet your recruitment and professional development needs. Contact our sales team to discuss tailored solutions.
                            </p>

                            <p>
                                <strong>Education and Nonprofit:</strong>
                            </p>

                            <p>
                                Rezmaze offers special pricing for educational institutions and nonprofit organizations. Contact us to learn more about our discounted plans.
                            </p>

                            <p>
                                <strong>Contact Sales:</strong>
                            </p>

                            <p>
                                If you have specific requirements or need a custom plan for your organization, please get in touch with our sales team. We're here to assist you in finding the right solution.
                            </p>

                            <p>
                                Thank you for considering Rezmaze for your professional development and recruitment needs. We're committed to providing cost-effective and flexible solutions for our users.
                            </p>

                            <p>
                                Explore our pricing options and choose the plan that helps you achieve your career goals.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Pricing;
