import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import RezmazeImage from './assets/logo4.svg'
import {useNavigate} from "react-router-dom";
function LoginRegisterModal({ show, onHide }) {

    const navigate = useNavigate();

    const handleLogin = () => {
        navigate('/jobseeker_login');

    };

    const handleRegister = () => {
        navigate('/jobseeker_signup');

    };
    return (
        <Modal show={show} onHide={onHide} centered >
            <Modal.Header closeButton>
                <div className="d-flex justify-content-between">
                    <Modal.Title className="text-primary">Unlock Full Job Details</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body className="border p-3">
                <p className="lead">Access exclusive job opportunities and detailed job descriptions.</p>
                <p className="text-muted">
                    Unlock the full potential of your job search by logging in or registering.
                    Job details are exclusively available to registered users.
                </p>
                <div className="d-flex justify-content-center">
                    <Button variant="primary" onClick={handleLogin} className="mx-3">
                        <i className="fas fa-lock"></i> Login
                    </Button>
                    <Button variant="success" onClick={handleRegister} className="mx-3">
                        <i className="fas fa-user"></i> Register
                    </Button>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default LoginRegisterModal;
