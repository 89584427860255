import React, {useEffect} from "react";

import Signup from "./SignUp";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import {BrowserRouter, Route, Routes, useHistory, Navigate, useNavigate} from "react-router-dom";

import EmployerDashboard from "./EmployerDashboard";
import PostAJob from "./PostAJob";
import EditJob from "./EditJob";
import PostAJobPreview from "./postAJobPreview";
import SeachResume from "./SeachResume";
import JobSeekerDashboard from "./JobSeekerDashboard";
import JobSeekerLogin from "./job_seeker_login";
import JobSeekerSignUp from "./job_seeker_signup";
import CreateResume from "./createResume";
import EditResume from "./editResume";
import Alljobs from "./Alljobs";
import Alljobv2 from "./Alljobv2";
import EmployerDashboard_v1 from "./EmployerDashboard_v1";
import EmployeeMobileHeader from "./EmployeeMobileHeader";
import CreateResumev1 from "./PostResume";
import CreatResumepreview from "./PostResumePreview";
import LandingPage from "./LandingPage";
import PostResume from "./PostResume";
import VideoRecorder from "./VideoRecorder";
import CreateProfile from "./createprofile";
import ResumeDetailsScreen from "./ResumeDetailsScreen";
import ProfileView from "./ProfileView";
import LoginAttempt from "./LoginAttempt";
import SearchJob from "./SearchJob";
import JobApplication from "./jobapplications";
import FavoriteResumes from "./FavoriteResumes";
import FavoriteJobs from "./FavoriteJobs";
import JobsApplied from "./JobsApplied";
import FollowersFollowingScreen from "./FollowersFollowingScreen";
import MyProfileView from "./MyProfileView";
import AllNotification from "./AllNotification";
import Post from "./Post";
import PdfTextHighlighter from "./PdfTextHighlighter";
import SamplePdf from "./SamplePdf";
import ReqForSubscription from "./views/ReqForSubscription ";
import OuterJobSearch from "./OuterJobSearch";
import AdminPanel from "./views/AdminPanel";
import AdminLogin from "./views/AdminLogin";
import VideoResumeGuide from "./VideoResumeGuide";
import SocialMediaCapabilities from "./SocialMediaCapabilities";
import AboutUs from "./AboutUs";
import Careers from "./Careers";
import Press from "./Press";
import Pricing from "./Pricing";
import HelpDesk from "./HelpDesk";
import Support from "./Support";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";

const checkTokenExpiry = (navigate) => {
  const accessToken = localStorage.getItem("access_token");
  const expiryDate = localStorage.getItem("expiry_date");
  const userableType = localStorage.getItem("userable_type");
  if (accessToken && expiryDate && userableType) {
    const currentDateTime = new Date();
    const expiryDateTime = new Date(expiryDate);

    if (currentDateTime > expiryDateTime) {
      localStorage.clear();
      navigate(
          userableType === "App\\Models\\JobSeeker"
              ? "/jobseeker_login"
              : "/employer-login"
      );
    }
  }

};
function Root() {
  const navigate = useNavigate(); // Get the navigate function

  useEffect(() => {
    // Check on component mount
    checkTokenExpiry(navigate);

    // Check every 5 seconds
    const intervalId = setInterval(() => {
      checkTokenExpiry(navigate);
    }, 60000);

    return () => {
      clearInterval(intervalId); // Clear the interval on component unmount
    };
  }, [navigate]); // Pass navigate as a dependency to useEffect

  return (
      <Routes>
        <Route path="/employer-login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route
              path="/employer_dashboard1"
              element={
                  localStorage.getItem("userable_type") === "App\\Models\\Employer" ? (
                      <EmployerDashboard />
                  ) : (
                      <Navigate to="/" />
                  )
              }
          />
          <Route
              path="/employer_dashboard"
              element={
                  localStorage.getItem("userable_type") === "App\\Models\\Employer" ? (
                      <EmployerDashboard_v1 />
                  ) : (
                      <Navigate to="/" />
                  )
              }
          />
          <Route
              path="/job_seeker_dashboard"
              element={
                  localStorage.getItem("userable_type") === "App\\Models\\JobSeeker" ? (
                      <JobSeekerDashboard />
                  ) : (
                      <Navigate to="/" />
                  )
              }
          />

          <Route
              path="/post_a_job"
              element={
                  localStorage.getItem("userable_type") === "App\\Models\\Employer" ? (
                      <PostAJob />
                  ) : (
                      <Navigate to="/" />
                  )
              }
          />
          <Route
              path="/edit_a_job"
              element={
                  localStorage.getItem("userable_type") === "App\\Models\\Employer" ? (
                      <EditJob />
                  ) : (
                      <Navigate to="/" />
                  )
              }
          />
          <Route path="/post_a_job_preview" element={<PostAJobPreview />} />
          <Route
              path="/search_resume"
              element={
                  localStorage.getItem("userable_type") === "App\\Models\\Employer" ? (
                      <SeachResume />
                  ) : (
                      <Navigate to="/" />
                  )
              }
          />
        <Route path="jobseeker_login" element={<JobSeekerLogin />} />
        <Route path="jobseeker_signup" element={<JobSeekerSignUp />} />
          <Route
              path="create_resume"
              element={
                  localStorage.getItem("userable_type") === "App\\Models\\JobSeeker" ? (
                      <CreateResume />
                  ) : (
                      <Navigate to="/" />
                  )
              }
          />
          <Route
              path="edit_resume"
              element={
                  localStorage.getItem("userable_type") === "App\\Models\\JobSeeker" ? (
                      <EditResume />
                  ) : (
                      <Navigate to="/" />
                  )
              }
          />

        <Route path="create_profile" element={<CreateProfile />} />
          <Route
              path="alljobs"
              element={
                  localStorage.getItem("userable_type") === "App\\Models\\Employer" ? (
                      <Alljobs />
                  ) : (
                      <Navigate to="/" />
                  )
              }
          />
          <Route
              path="alljobv2"
              element={
                  localStorage.getItem("userable_type") === "App\\Models\\Employer" ? (
                      <Alljobv2 />
                  ) : (
                      <Navigate to="/" />
                  )
              }
          />
        <Route path="employeemobileheader" element={<EmployeeMobileHeader />} />
          <Route
              path="/post-resume"
              element={
                  localStorage.getItem("userable_type") === "App\\Models\\JobSeeker" ? (
                      <PostResume />
                  ) : (
                      <Navigate to="/" />
                  )
              }
          />
          <Route
              path="/post-resume-preview"
              element={
                  localStorage.getItem("userable_type") === "App\\Models\\JobSeeker" ? (
                      <CreatResumepreview />
                  ) : (
                      <Navigate to="/" />
                  )
              }
          />
          <Route path="/resumes" element={<ResumeDetailsScreen />} />

        <Route path="/" element={<LandingPage />} />
        <Route path="/vr" element={<VideoRecorder />} />
        <Route path="/profile-view" element={<ProfileView />} />
        <Route path="/my-profile-view" element={<MyProfileView />} />

        <Route path="/login-attempt" element={<LoginAttempt />} />
          <Route
              path="/search_job"
              element={
                  localStorage.getItem("userable_type") === "App\\Models\\JobSeeker" ? (
                      <SearchJob />
                  ) : (
                      <Navigate to="/" />
                  )
              }
          />
          <Route
              path="/jobapplications"
              element={
                  localStorage.getItem("userable_type") === "App\\Models\\Employer" ? (
                      <JobApplication />
                  ) : (
                      <Navigate to="/" />
                  )
              }
          />
          <Route
              path="/fav-resumes"
              element={
                  localStorage.getItem("userable_type") === "App\\Models\\Employer" ? (
                      <FavoriteResumes />
                  ) : (
                      <Navigate to="/" />
                  )
              }
          />
          <Route
              path="/fav-jobs"
              element={
                  localStorage.getItem("userable_type") === "App\\Models\\JobSeeker" ? (
                      <FavoriteJobs />
                  ) : (
                      <Navigate to="/" />
                  )
              }
          />
          <Route
              path="/applied-jobs"
              element={
                  localStorage.getItem("userable_type") === "App\\Models\\JobSeeker" ? (
                      <JobsApplied />
                  ) : (
                      <Navigate to="/" />
                  )
              }
          />
        <Route path="/follow" element={<FollowersFollowingScreen />} />
        <Route path="/AllNotifications" element={<AllNotification />} />
        <Route path="/Post" element={<Post />} />

        <Route path="/pdf" element={<SamplePdf />} />
        <Route path="/Request_For_Subscription" element={<ReqForSubscription />}/>

        <Route path='/Outer_job_search' element={<OuterJobSearch />} />
        <Route path='/video-resume-guide' element={<VideoResumeGuide  />} />
        <Route path='/social-media-capabilities' element={<SocialMediaCapabilities/>} />
        <Route path='/about-us' element={<AboutUs/>} />
        <Route path='/careers' element={<Careers/>} />
        <Route path='/press' element={<Press/>} />
        <Route path='/pricing' element={<Pricing/>} />
        <Route path='/help-desk' element={<HelpDesk/>} />
        <Route path='/support' element={<Support/>} />
        <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
        <Route path='/term-condition' element={<TermsAndConditions/>} />

        <Route path='/admin-panel' element={<AdminPanel />} />
        <Route path='/admin-login' element={<AdminLogin />} />

      </Routes>
  );
}
function App() {
  return (
      <BrowserRouter>
        <Root />
      </BrowserRouter>
  );
}
export default App;
