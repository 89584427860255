import React, {useEffect, useRef, useState} from 'react';
import PageHeader from "./PageHeader";
import '../src/assets/css/SocialMediaCapabilities.css'; // Import the CSS file
import { BsArrowLeft } from "react-icons/bs";
import {FaShare, FaThumbsUp, FaComment, FaUser, FaVideo} from "react-icons/fa"; // Import icons
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import colors from "./colors";
import {Carousel} from "react-responsive-carousel";
import sliderImage1 from "./assets/socialmedia-tutorial1.png";
import sliderImage2 from "./assets/socialmedia-tutorial2.png";
import sliderImage3 from "./assets/socialmedia-tutorial3.png";
import sliderImage4 from "./assets/socialmedia-tutorial4.png";
import sliderImage5 from "./assets/socialmedia-tutorial5.png";
import sliderImage6 from "./assets/socialmedia-tutorial6.png";
import sliderImage7 from "./assets/socialmedia-tutorial7.png";

const SocialMediaCapabilities = () => {
    const navigate = useNavigate();
    const [selectedStep, setSelectedStep] = useState(0);

    const steps = [
        "Begin by navigating to your dashboard, where you can share your thoughts. Click on the \"What's on your mind?\" section to compose a post. After writing your message, hit the \"Post\" button to share it with your network.",
        "Give the post a thumbs-up by clicking the like button icon.",
        "The like icon will change to blue once you've successfully liked the post.",
        "Select the Comment icon to open the comment area, type your message, and then click the send button to post your comment.",
        "Your comment will appear instantly.",
        "Select the share icon button to distribute the post.",
        "Clicking the share icon button will prompt a share post pop-up where you can include your comment before clicking the 'Share' button to share the post."

    ];

    const images = [sliderImage1, sliderImage2, sliderImage3, sliderImage4,sliderImage5,sliderImage6,sliderImage7];
    const carouselRef = useRef(null);

    useEffect(() => {
        if (carouselRef.current) {
            carouselRef.current.pause();
        }
    }, [carouselRef]);

    const handleStepClick = (index) => {
        setSelectedStep(index);
    };
    return (
        <>
            <PageHeader />
            <div className="smc-social-media-content">
                <div className="smc-background-style">
                    <div className="AJbackbtn">
                        <button onClick={() => navigate('/')}><BsArrowLeft /> Back</button>
                    </div>
                    <h1><FaShare className="mb-1"  style={{color:colors.primaryColor}}/> Social Media Capabilities</h1>
                    <div className="content-container">
                        <div className="smc-text-content">
                            <p>
                                Welcome to Rezmaze's Social Media Capabilities guide. Our platform offers a range of social features to enhance your job search and career development.
                                Here's how you can use them effectively:
                            </p>

                            <p>
                                Rezmaze's social media capabilities allow you to network, interact, and stay updated with the latest job opportunities.
                            </p>
                            <p>
                                To access these features, simply create an account and start exploring our social job portal.

                            </p>
                            <p>
                                Good luck with your job search and career development!
                            </p>
                            <ol >
                                {steps.map((step, index) => (
                                    <li className="smc-ol-clickable" key={index} onClick={() => handleStepClick(index)}>{step}</li>
                                ))}
                            </ol>
                            <p>
                                A well-crafted video resume can help you make a positive impression on potential employers and increase your chances of getting noticed.
                            </p>
                            <p>
                                Good luck with your video resume!
                            </p>
                        </div>
                        <div className="smc-image-content">
                            <Carousel showThumbs={false} selectedItem={selectedStep} autoPlay={true} interval={5000} infiniteLoop={true}>
                                {images.map((image, index) => (
                                    <div key={index}>
                                        <img src={image} alt={`Step ${index + 1}`} />
                                    </div>
                                ))}
                            </Carousel>

                        </div>
                    </div>

                </div>
            </div>
            <Footer/>
        </>
    );
};

export default SocialMediaCapabilities;
