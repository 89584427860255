import React, {useState} from "react";
import './assets/css/header.css';
import '../src/assets/css/login.css';
import logoImage from '../src/assets/logo4.svg';
import emailImage from '../src/assets/emailplaceholder.svg';
import watchPasswordImage from '../src/assets/watchpassword.svg';
import setPasswordImage from '../src/assets/setpassword.svg';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import BASE_URL, {RECAPTCHA_KEY} from "./config";
import styles from "./assets/css/LoadingComponent.module.css";
import style from '../src/assets/css/LoadingComponent.module.css';
import {useMediaQuery} from "react-responsive";
import {SignInHeader} from "./components/Header/SignInHeader";
import {FiCheckCircle} from "react-icons/fi";
import {toast, ToastContainer} from "react-toastify";
import {IoEyeOffOutline, IoEyeOutline} from "react-icons/io5";
import {ErrorHandlingComponent} from "./ErrorHandlingComponent";
import ReCAPTCHA from "react-google-recaptcha";

function JobSeekerSignUp() {

    const navigate = useNavigate();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const signInClick = () => {
        navigate('/jobseeker_login');
    };
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [checked, setChecked] = useState(false);

    const [error, setError] = useState([]);
    const [success, setSuccess] = useState([]);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fetchError, setFetchError] = useState(false);
    const [fetchErrorMessage, setFetchErrorMessage] = useState(false);
    const passwordVisible = () => {
        console.log(isPasswordVisible)
        setIsPasswordVisible(!isPasswordVisible);
    }
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
    const ConfirmPasswordVisible = () => {
        console.log(isConfirmPasswordVisible)
        setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
    }
    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            handleSignUp();
        }
    };
    const handleSignUp = (e) => {
        setError([]);
        if(e) {
            e.preventDefault();
        }
        setLoading(true);
        setIsPasswordVisible(false);
        setIsConfirmPasswordVisible(false);
        if (password !== confirmPassword) {
            setError({...error, confirmPassword: 'Password does not match'});
            setLoading(false);
            return;
        }
        // if (!checked) {
        //     setError([]);
        //     setError({...error, checked: 'Please check the "I am not a robot" box before submitting.'});
        //     setLoading(false);
        //     return;
        // }
        if (!checked) {
            setError({ ...error, checked: 'Please complete the reCAPTCHA verification' });
            setLoading(false);
            return;
        }
        const payload = {
            email: email,
            password: password
        };

        axios.post(`${BASE_URL}/job_seeker_register`, payload)
            .then(response => {
                setLoading(false);
                console.log(response);
                if (response.status === 200) {
                    setError([]);
                    setSuccess({ ...success, successMessage: 'Email sent for verification' });
                    setEmail('');
                    setPassword('');
                    setConfirmPassword('');
                    setChecked('');
                    toast.success('Email sent for verification', {
                        position: toast.POSITION.TOP_CENTER
                    });
                    localStorage.setItem('login', 0);

                    //navigate("/");
                } else {
                    setError(response.data);
                }
            })
            .catch(error => {
                setError(error.response.data);
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
            });

    }
    const handleLogoClick = () => {
        navigate("/");

    }
    const handleRecaptchaChange = (value) => {
        console.log("reCAPTCHA value:", value);
        setError({ ...error, checked: '' });
        setChecked(true); // Assuming you have a state variable to track the checkbox status
    };
    const handleConfirmPasswordChange=(e)=>{
        setConfirmPassword(e.target.value);

        if (password !== e.target.value) {
            setError({...error, confirmPassword: 'Password does not match'});
        }
        else
        {
        setError({ ...error, confirmPassword: '' });
        }
    }

    return (
        <div>
            {/*---------------------------------------------------------------*/}
            {/*                         NAVBAR Start                          */}
            {/*---------------------------------------------------------------*/}
            <div className="nav bg-white ">
                <div className="container-fluid">
                    <div className="row navpad">
                        <div className="d-flex justify-content-between">
                            <div className="col-lg-4 logosearch ">
                                <a href="javascript:void(0)">
                                    <img onClick={handleLogoClick} src={logoImage} alt="Logo"/>
                                </a></div>
                            <div className="col-lg-8 d-flex justify-content-end align-items-center">
                                {isTabletOrMobile?(<SignInHeader text="LOGIN" handlerClick={signInClick} />)
                                    :(
                                        <div className="postbtn">
                                            <button type="submit"><a href="javascript:void(0)" onClick={signInClick}> LOGIN </a></button>
                                        </div>
                                    ) }

                            </div>
                        </div>
                    </div>
                </div>
            </div>


                <div className="mainbg">

                    <div className="form">
                        {success.successMessage && (
                            <div className="success-message-container">
                                 <span className="success-message">
                                      <FiCheckCircle className="icon" size={24}/>
                                      <h4>{success.successMessage}</h4>
                                  </span>
                            </div>
                        )}

                        <form action="">
                            <div className="formdata">
                                <h2>Job Seeker Sign Up </h2>

                                <p>Or get registration via an other email</p>
                                <div className="emailinfo">
                                    <img src={emailImage} alt=""/>
                                    <input type="text" placeholder="Email" value={email}
                                           onChange={e => setEmail(e.target.value)} onKeyPress={handleKeyPress} required/>

                                </div>

                                {error.email && <span className="text-danger">{error.email}</span>}

                                <div className="emailinfo passwordinfo">
                                    <img src={setPasswordImage} alt=""/>
                                    <input type={isPasswordVisible ? "text" : "password"}
                                           placeholder="Set Password"
                                           value={password}
                                           onChange={e => setPassword(e.target.value)}
                                           onKeyPress={handleKeyPress} required/>

                                    {/*<img src={watchPasswordImage} onClick={passwordVisible}/>*/}
                                    <div className="eye-icon-container" onClick={passwordVisible}>
                                        {isPasswordVisible ? (
                                            <IoEyeOutline className="eye-icon" size={24} />
                                        ) : (
                                            <IoEyeOffOutline className="eye-icon" size={24} />
                                        )}
                                    </div>
                                </div>
                                {error.password && <span className="text-danger">{error.password}</span>}

                                {/*--------------------Confirm Password ----------*/}
                                <div className="emailinfo passwordinfo">
                                    <img src={setPasswordImage} alt=""/>
                                    <input type={isConfirmPasswordVisible ? "text" : "password"}
                                           placeholder="Confirm Password"
                                           value={confirmPassword}
                                           onChange={handleConfirmPasswordChange}

                                           onKeyPress={handleKeyPress} required/>
                                    <div className="eye-icon-container" onClick={ConfirmPasswordVisible}  >
                                        {isConfirmPasswordVisible ? (
                                            <IoEyeOutline className="eye-icon" size={24} />
                                        ) : (
                                            <IoEyeOffOutline className="eye-icon" size={24} />
                                        )}
                                    </div>
                                    {/*<img src={watchPasswordImage} onClick={ConfirmPasswordVisible}/>*/}
                                </div>
                                {error.confirmPassword && <span className="text-danger">{error.confirmPassword}</span>}


                                {/*------------------------I am not Rebort --------------------*/}
                                <div className="formforget">
                                    {/*<div>*/}
                                    {/*    <input type="checkbox" id="remember" checked={checked}  onChange={() => setChecked(!checked)} name="remember"/>*/}
                                    {/*    <label className="checkbox-label"> I am not a robot</label><br/>*/}
                                    {/*</div>*/}
                                    {/*<p><a style={{color: '#bbbbbb'}} href="javascript:void(0)"> Captcha</a></p>*/}
                                    <ReCAPTCHA
                                        sitekey={RECAPTCHA_KEY}
                                        onChange={handleRecaptchaChange}
                                    />

                                </div>
                                {error.checked && <span className="text-danger">{error.checked}</span>}


                                {/*--------------------------------Sign Up BTN ------------------*/}
                                <div className="formbtn">
                                    <button onClick={handleSignUp}>Sign Up</button>
                                    {loading && (
                                        <div className={style.loadingOverlay}>
                                            <div className={style.loadingSpinner}></div>
                                        </div>
                                    )}
                                    <p>Already have an account? Please<span><a href="javascript:void(0)"
                                                                               onClick={signInClick}> Sign In </a> </span> here!
                                    </p>
                                </div>

                            </div>
                        </form>

                    </div>

                </div>
            <ToastContainer/>
            {fetchError &&  <ErrorHandlingComponent error={fetchErrorMessage} />}

            </div>



    );
}

export default JobSeekerSignUp;
