import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import style from '../src/assets/css/LoadingComponent.module.css';
import headerIcon2 from '../src/assets/headericone2.svg';
import { BsArrowLeft } from 'react-icons/bs';
import EmployerHeader from "./EmployerHeader";
import {BASE_URL} from "./config";
import {Button, Modal} from "react-bootstrap";
import axios from "axios";
import {AiTwotoneHeart} from "react-icons/ai";
import ErrorModal from "./ErrorModal";
import {ErrorHandlingComponent} from "./ErrorHandlingComponent";

function FavoriteResumes() {
    const location = useLocation();
    const navigate = useNavigate();
    const { defaultfrom } = location.state || {}; // Get the passed state data
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem("access_token");
    const [favoriteResumes, setFavoriteResumes] = useState([]);
    const employerId = localStorage.getItem("employerId");
    const [fetchError, setFetchError] = useState(false);
    const [fetchErrorMessage, setFetchErrorMessage] = useState(false);
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const fetchFavoriteResumes = async () => {
        setLoading(true);
        try {
            // Fetch favorite resumes using the provided endpoint and payload
            const response = await fetch(`${BASE_URL}/resumes/get_favorite`, {
                method: "POST",
                headers: {
                    ...headers,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    employer_id: employerId, // Update with your employer_id
                }),
            });

            const data = await response.json();
            if (data.status === 200) {
                setFavoriteResumes(data.favorite_resume);
            }
            else if (data.status==404) {
                setFavoriteResumes([]);
            }

        } catch (error) {
            setLoading(false)
            setFetchError(true)
            setFetchErrorMessage(error)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFavoriteResumes();
    }, []);

// Function to show modal with title and message
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalMessage, setModalMessage] = useState('');

    const showModalWithMessage = (title, message) => {
        setModalTitle(title);
        setModalMessage(message);
        setShowModal(true);
    };
    // const handleResumeClick = (resumeId) => {
    //     const from="/fav-resumes";
    //     navigate('/resumes', { state: { resumeId,from } });
    // };
    const handleResumeClick = (resumeId) => {
        setLoading(true)
        axios.get(`${BASE_URL}/get-user-data`, {
            headers,
        })
            .then((res) => {
                const userData = res.data.user
                const viewedResumesIds=userData?.userable?.viewed_resumes_ids;
                const subscription = userData.userable.subscription;
                // Check if the resume has been viewed
                const viewedResumeIds = viewedResumesIds?.map(viewedResume => viewedResume?.resume_id);
                if (viewedResumeIds?.includes(resumeId)) {
                    setLoading(false);
                    const from="/fav-resumes";
                    navigate('/resumes', { state: { resumeId,from } });
                    return;
                }
                // Check if subscription is expired
                const endDate = new Date(subscription.end_date);
                const server_time = new Date(res.data?.server_time);

                if (endDate < server_time) {
                    setLoading(false)
                    showModalWithMessage("Subscription Expired", "Please contact admin to renew your subscription.");
                    return;
                }

                // Check if remaining jobs are zero or less
                if (subscription.remaining_views <= 0) {
                    setLoading(false)
                    showModalWithMessage("No Remaining Views", "Your resumes views limit has been reached. Please contact admin for assistance.");
                    return;
                }
                // If subscription is active and Views are remaining, navigate to post job page
                const from="/fav-resumes";
                navigate('/resumes', { state: { resumeId,from } });
            })
            .catch((err) => {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(err)
            });
    };


    const handleConfirmDeleteResume = async (resumeId) => {
        setLoading(true);
        const url = `${BASE_URL}/fav_resumes/${resumeId}`
        const method = 'DELETE';
        const payload = {
            employer_id: employerId,
            resume_id: resumeId
        };

        try {
            const response = await axios({
                method: method,
                url: url,
                headers: headers,
                data: payload  // Axios uses 'data' instead of 'body' for the request payload
            });

            const data = response.data;
            console.log(data)
            if(data.status==200){
                fetchFavoriteResumes();

            }
            // Handle the response data here
        } catch (error) {
            setLoading(false)
            setFetchError(true)
            setFetchErrorMessage(error)
        }
    };

    const handleRedirection = ()=>{
        navigate(-1);
        // if(defaultfrom === "search_resume"){
        //     navigate("/search_resume")
        // }else{
        //     navigate('/employer_dashboard')
        // }
    }
    return (
        <div>
            <ErrorModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                title={modalTitle}
                message={modalMessage}
            />
            {loading && (
                <div className={style.loadingOverlay}>
                    <div className={style.loadingSpinner}></div>
                </div>
            )}
            <EmployerHeader />
            <div className="container">
                <div className=".job-JB-main">
                    <div className="JBmain">
                        <div className=" JAbackbtn mb-2">
                                <button onClick={handleRedirection}>
                                    <BsArrowLeft /> Back
                                </button>
                            </div>
                        
                            
                            <div className="RDheadtitle fvthead ">
                                <h2>Favorite Resumes</h2>
                            </div>
                        <div className="table-responsive">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Preferred Job Title</th>
                                <th>Candidate Name</th>
                                <th>Relocation</th>
                                <th>Contract Type</th>
                                <th>Salary Expectations</th>
                                <th>US Based</th>
                                <th>Action</th>

                            </tr>
                            </thead>
                            <tbody>
                            {favoriteResumes.map((resume) => (
                                <tr key={resume.id}
                                    onClick={() => handleResumeClick(resume.resume.id)}
                                    style={{ cursor: "pointer" }}
                                >
                                    <td>{resume.resume.preferred_job_title}</td>
                                    <td>{`${resume.resume.first_name} ${resume.resume.last_name}`}</td>
                                    <td>{resume.resume.relocation}</td>
                                    <td>{resume.resume.contract_type}</td>
                                    <td>{resume.resume.salary_expectations?"$"+resume.resume.salary_expectations:''}</td>
                                    <td>{resume.resume.us_based}</td>
                                    <td>
                                        <div className="editjobsicon">
                                    <AiTwotoneHeart title="Unfavorite"   onClick={(e) => {
                                        e.stopPropagation(); // Stop event propagation
                                        handleConfirmDeleteResume(resume.resume.id);
                                    }}
                                    style={{color: '#e54545',cursor:"pointer"}} size={24}/>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
            </div>
            {fetchError &&  <ErrorHandlingComponent error={fetchErrorMessage} />}
        </div>
    )
}

export default FavoriteResumes;
