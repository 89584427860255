// useNotificationHandler.js
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../config";

export const useNotificationHandler = () => {

    const token = localStorage.getItem("access_token");
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const navigate = useNavigate()
    const RedirectNotification = async (notification, setUnreadCount, unreadCount) => {

        if (notification?.type) {
           await axios
                .post(`${BASE_URL}/notifications/${notification?.id}`, {}, {
                    headers
                })
                .then((response) => {
                    if (response?.data?.status === 200) {
                        let unread = unreadCount;
                        setUnreadCount(unread - 1);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });

            let id = notification?.source_id;
            let current_redirect = localStorage.getItem("redirection");

            switch (notification?.type) {
                case "new_post":
                case "post_liked":
                case "post_commented":
                case "post_shared":

                    localStorage.setItem('redirection', '/Post');
                        navigate('/Post', { state: { id} });
                    if(current_redirect == '/Post'){
                        window.location.reload();
                    }

                    break;
                case "user_followed":

                    localStorage.setItem('redirection', '/follow');
                    navigate('/follow');
                    if(current_redirect == '/follow'){
                        window.location.reload();
                    }
                    break;
                case "job_posted":
                case "job_application_status_updated":

                    localStorage.setItem('redirection', '/post_a_job_preview');
                    navigate('/post_a_job_preview', { state: { jobId: id } });
                    if(current_redirect == '/post_a_job_preview'){
                        window.location.reload();
                    }
                    break;
                case "job_seeker_available":

                    localStorage.setItem('redirection', '/profile-view');
                    navigate('/profile-view', { state: { user_id: id } });
                    if(current_redirect === '/profile-view'){
                        window.location.reload();
                    }
                    break;
                default:
                    console.log("Unknown notification type");
            }
        } else {
            console.log("Something Wrong!");
        }
    };

    return { RedirectNotification };
};

