import React, { useEffect, useState } from 'react';
import logoImage from '../src/assets/logo4.svg';
import { useNavigate } from 'react-router-dom';
import '../src/assets/css/header.css';
import '../src/assets/css/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import BASE_URL from './config';
import { addUser, setResumeStatus } from './store/slices/UserSlice';
import UserFetch from './UserFetch';
import { useMediaQuery } from 'react-responsive';
import { MainHeader, MiniHeader, MobileHeader } from './components/Header/Header';
import { employer_variable } from './components/Header/variables';
import ErrorModal from "./ErrorModal";
import style from "./assets/css/LoadingComponent.module.css";
import Helper from "./Helper";
import {ErrorHandlingComponent} from "./ErrorHandlingComponent";
import Pusher from 'pusher-js';
import addNotification, { Notifications } from 'react-push-notification';
import {ToastContainer, toast} from 'react-toastify';
import ButterToast, { POS_TOP, POS_RIGHT,Cinnamon } from 'butter-toast';



function Header() {
    const [count, setCount] = useState(0);
    const navigate = useNavigate();
    // const [notification, setNotification] = useState(null);
    const position = {
        vertical: POS_TOP,
        horizontal: POS_RIGHT
    };
    //Redux
    const user_data = useSelector((state) => {
        return state?.user?.user;
    });

    useEffect(() => {}, [count]);
    useEffect(() => {
        if (count === 0) {
            axios
                .get(`${BASE_URL}/get-user-data`, {
                    headers,
                })
                .then((res) => {
                    setCount(1);
                })
                .catch((err) => {
                    setLoading(false)
                    setFetchError(true)
                    setFetchErrorMessage(err)
                });        }
    }, []);
    if (!user_data ) {
        UserFetch()
            .then((response) => {
                const user_data = response.data.user;
                dispatch(addUser(user_data));
            })
            .catch((error) => {
                console.error('Error fetching user:', error);
            });
    }

    //Redux End

    // Pusher code start
    useEffect(() => {
        const pusher = new Pusher('c6c602043d0983387557', {
            cluster: 'ap2',
            forceTLS: true
        });
        const channel = pusher.subscribe('channel-active-for-jobs');
        const handleNotification = (data) => {
            UserFetch()
                .then((response) => {
                    const user_data = response.data.user;
                    console.log(user_data, "user data");
                    dispatch(addUser(user_data));
                    fetchNotifications();
                    // Check if user_data and followings are available
                    if (user_data && user_data?.followings) {
                        // Find if the user_id exists in the followings array
                        let isFollowing = user_data?.followings?.some(
                            (following) => following?.id === data?.notification?.user_id
                        );
                        if (isFollowing) {
                            ButterToast.raise({
                                content:     <Cinnamon.Crisp
                                    scheme={Cinnamon.Crisp.SCHEME_BLUE}
                                    content={data?.notification.content}
                                    title="Active For Job"
                                    namespace="top" // Optional namespace
                                    style={{ top: '50px' }} // Customizing position to override default top position
                                />,
                                onClick: () => handleNotificationClick(data?.notification?.user_id), // Pass user_id directly
                                sticky:true
                            });

                        } else {
                            // Handle the case where the user is not following
                        }
                    } else {
                        // Handle the case where user data or followings are not available
                    }

                })
                .catch((error) => {
                    console.error("Error fetching user:", error);
                });




        };

        channel.bind('active-for-jobs-event', handleNotification);

        return () => {
            channel.unbind('active-for-jobs-event', handleNotification);
            pusher.unsubscribe('channel-active-for-jobs');
            pusher.disconnect();
        };
    }, [user_data]);

    const handleNotificationClick = (userId) => {
        // if (notification) {
            localStorage.setItem('redirection', '/profile-view');
            // const userId = notification.user_id;
            // setNotification(null)
            navigate('/profile-view', { state: { user_id: userId } });
        // }
    };

    //
    // useEffect(() => {
    //     if (notification) {
    //         toast.info(notification.content, {
    //             position: toast.POSITION.TOP_RIGHT,
    //             autoClose: 5000,
    //             onClose: handleNotificationClick,
    //             // You can add other options as needed
    //         });
    //     }
    // }, [notification]); // Run the effect when notification or handleNotificationClick changes
    // useEffect(() => {
    //     if (notification) {
    //         // Use addNotification to display the notification with options
    //         addNotification({
    //             title: 'New Notification',
    //             subtitle: 'Available for Job Opportunities', // Updated subtitle
    //             message: notification.content,
    //             onClick: handleNotificationClick, // Optional onClick callback
    //             theme: 'red', // Optional
    //             duration: 5000, // Optional
    //             native: true, // Make the notification a native OS notification
    //             // icon: '..src', // Optional icon for the notification
    //             vibrate: [200, 100, 200], // Optional vibration pattern for the notification
    //             silent: false // Make the notification silent
    //         });
    //     }
    // }, [notification]); // Run the effect when notification changes
    // // Pusher code end


        const [fetchError, setFetchError] = useState(false);
        const [fetchErrorMessage, setFetchErrorMessage] = useState(false);

        // Function to show modal with title and message
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalMessage, setModalMessage] = useState('');
        const showModalWithMessage = (title, message) => {
            setModalTitle(title);
            setModalMessage(message);
            setShowModal(true);
        };

    const [notifications, setNotifications] = useState([]);
    const [serverTime, setServerTime] = useState('')

    const dispatch = useDispatch();
    const isTablet = useMediaQuery({ query: '(max-width: 930px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const token = localStorage.getItem('access_token'); // Get the token from localStorage
    const headers = {
        Authorization: `Bearer ${token}`, // Set the Authorization header with the token
    };
    const postAJob = () => {
        // setLoading(true)

        axios.get(`${BASE_URL}/get-user-data`, {
            headers,
        })
            .then((res) => {

                if(res.data.status==200){
                const userData = res.data.user;
                const subscription = userData.userable.subscription;
                // Check if subscription is expired
                const currentDate = new Date();
                const endDate = new Date(subscription.end_date);
                const server_time = new Date(res.data?.server_time);
                if (endDate < server_time) {
                    setLoading(false)
                    showModalWithMessage("Subscription Expired", "Please contact admin to renew your subscription.");
                    return;
                }
                // Check if remaining jobs are zero or less
                if (subscription.remaining_jobs <= 0) {
                    setLoading(false)
                    showModalWithMessage("No Remaining Jobs", "Your job postings limit has been reached. Please contact admin for assistance.");
                    return;
                }
                // If subscription is active and jobs are remaining, navigate to post job page
                navigate('/post_a_job');
                }

            })
                .catch((err) => {
                    setLoading(false)
                    setFetchError(true)
                    setFetchErrorMessage(err)
                });
    };

    const signInClick = () => {
        localStorage.clear(); // Clear local storage
        navigate('/employer-login');
    };
    const SearchResume = () => {
        navigate('/search_resume');
    };
    const handleLogoClick = () => {
        navigate('/employer_dashboard');
    };
    const handleAllPostClick = () => {
        const defaultfrom = '/employer_dashboard';
        navigate('/alljobs', { state: { defaultfrom } });
    };
    const handleProfileClick = () => {
        navigate('/create_profile');
    };
    const viewProfileClick = () => {
        navigate('/my-profile-view');
    };
    const showSearchResume = () => {
        navigate('/search_resume');
    };
    const showFavResume = () => {
        navigate('/fav-resumes');
    };
    const fetchNotifications = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/notifications`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response?.data?.status === 200 && response?.data?.notifications) {
                setNotifications(response?.data?.notifications);
                setServerTime(response?.data?.server_time);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            setFetchError(true);
            setFetchErrorMessage(error);
        }
    };
    useEffect(() => {
        // Fetch notifications from the API
       fetchNotifications();
    }, [token]); // Include any dependencies that trigger the fetch


    const [loading, setLoading] = useState(false);

    return (
        <div className="nav bg-white ">
            <Notifications /> {/* Render the Notifications component at the top level */}
            <ToastContainer/>
            <ButterToast position={position}/>

            {loading && (
                <div className={style.loadingOverlay}>
                    <div className={style.loadingSpinner}></div>
                </div>
            )}            <ErrorModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                title={modalTitle}
                message={modalMessage}
            />
            <div className="container-fluid">
                <div className="navpad">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="logosearch ">
                            <a href="javascript:void(0)" onClick={handleLogoClick}>
                                {' '}
                                <img src={logoImage} alt="Logo" />
                            </a>
                        </div>
                        {isTablet ? (
                            isMobile ? (
                                <MobileHeader
                                    user_data={user_data}
                                    notifications={notifications}
                                    serverTime={serverTime}
                                    viewProfileClick={viewProfileClick}
                                    signInClick={signInClick}
                                    showJobSearchHandler={showSearchResume}
                                    handleProfileClick={handleProfileClick}
                                    showFavoriteHandler={showFavResume}
                                    viewAllAppliedJobsHandler={handleAllPostClick}
                                    postAJob={postAJob}
                                    jobSeach={SearchResume}
                                    variables={employer_variable}
                                />
                            ) : (
                                <MiniHeader
                                    user_data={user_data}
                                    notifications={notifications}
                                    serverTime={serverTime}
                                    viewProfileClick={viewProfileClick}
                                    signInClick={signInClick}
                                    showJobSearchHandler={showSearchResume}
                                    handleProfileClick={handleProfileClick}
                                    showFavoriteHandler={showFavResume}
                                    viewAllAppliedJobsHandler={handleAllPostClick}
                                    postAJob={postAJob}
                                    jobSeach={SearchResume}
                                    variables={employer_variable}
                                />
                            )
                        ) : (
                            <MainHeader
                                user_data={user_data}
                                notifications={notifications}
                                serverTime={serverTime}
                                viewProfileClick={viewProfileClick}
                                signInClick={signInClick}
                                showJobSearchHandler={showSearchResume}
                                handleProfileClick={handleProfileClick}
                                showFavoriteHandler={showFavResume}
                                viewAllAppliedJobsHandler={handleAllPostClick}
                                postAJob={postAJob}
                                jobSeach={SearchResume}
                                variables={employer_variable}
                            />
                        )}
                    </div>
                </div>
            </div>
            {fetchError &&  <ErrorHandlingComponent error={fetchErrorMessage} />}
        </div>
    );
}
export default Header;
