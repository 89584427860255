import React, { useRef, useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import RecordRTC from 'recordrtc';
import { Modal, Button } from 'react-bootstrap';
import { Player } from 'react-html5video';
import 'react-html5video/dist/styles.css';
const VideoRecorder = ({ showModal, setShowModal, onVideoRecorded }) => {
    const webcamRef = useRef(null);
    const videoRef = useRef(null);
    const audioStreamRef = useRef(null);
    const [recordRTC, setRecordRTC] = useState(null);
    const [recordedBlob, setRecordedBlob] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [timer, setTimer] = useState(0);
    const [recordingTime, setRecordingTime] = useState(0);

    const startRecording = () => {
        const webcam = webcamRef.current.video;
        navigator.mediaDevices
            .getUserMedia({
                audio: true,
                video: true
            })
            .then((mediaStream) => {
                const rtc = RecordRTC(mediaStream, {
                    type: 'video',
                    mimeType: 'video/mp4',
                });

                rtc.startRecording();

                setRecordRTC(rtc);
                setIsRecording(true);
                setRecordedBlob(null); // Reset recorded blob
                setTimer(0); // Reset timer
            })
            .catch((error) => console.error('Error accessing media devices:', error));
    };

    const stopRecording = () => {
        if (recordRTC) {
            recordRTC.stopRecording(() => {
                const blob = recordRTC.getBlob();
                setRecordedBlob(blob);
                setIsRecording(false);
                if (audioStreamRef.current) {
                    audioStreamRef.current.stop();
                }
                onVideoRecorded(blob);
            });
        }
    };

    useEffect(() => {
        let interval;

        if (isRecording) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer + 1);
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [isRecording]);

    useEffect(() => {
        if (isRecording) {
            const recordingInterval = setInterval(() => {
                setRecordingTime((prevRecordingTime) => prevRecordingTime + 1);
            }, 1000);

            if (timer > 30) {
                stopRecording();
            }

            return () => {
                clearInterval(recordingInterval);
            };
        }
    }, [isRecording, timer]);

    useEffect(() => {
        let mediaStream;

        const stopCamera = () => {
            if (webcamRef.current && webcamRef.current.video) {
                mediaStream = webcamRef.current.video.srcObject;
                const tracks = mediaStream.getTracks();
                tracks.forEach((track) => track.stop());
            }
        };

        const stopRecordingAndCamera = () => {
            if (recordRTC) {
                recordRTC.stopRecording(() => {
                    const blob = recordRTC.getBlob();
                    setRecordedBlob(blob);
                    setIsRecording(false);
                    if (audioStreamRef.current) {
                        audioStreamRef.current.stop();
                    }
                    stopCamera();

                    // Call the callback function with the video blob
                    onVideoRecorded(blob);
                });
            }
        };

        if (!showModal && isRecording) {
            stopRecordingAndCamera();
        }

        return () => {
            if (mediaStream) {
                mediaStream.getTracks().forEach((track) => track.stop());
            }
        };
    }, [showModal, isRecording]);

    const handleModalClose = () => {
   
        setShowModal(false);
    };



    return (
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Video Recorder</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center mb-4" style={{ marginTop: '10px' }}>
                  <Webcam
                        audio={false}
                        ref={webcamRef}
                        videoConstraints={{
                            width: 400, // Adjust the width as needed
                            height: 300, // Adjust the height as needed
                        }}
                    />
                </div>
                <div className="text-center mb-4">
                    <Button variant="primary" className="me-2" style={{ background: '#003580' }} onClick={startRecording} disabled={isRecording}>
                        Start Recording
                    </Button>
                    <Button variant="danger" className="me-2" style={{ background: '#E54545' }} onClick={stopRecording} disabled={!isRecording}>
                        Stop Recording
                    </Button>
                </div>
                {recordedBlob && (
                    <div className="text-center">
                        <video
                            ref={videoRef}
                            controls
                            width="400"
                            height="300"
                            onLoadedMetadata={() => {
                                setRecordingTime(videoRef.current.duration);
                            }} // Set the total time of the video
                            onPlay={() => setIsRecording(false)} // Pause the recording when the video starts playing
                        >
                            <source src={URL.createObjectURL(recordedBlob)} type="video/mp4" />
                        </video>

                    </div>
                )}

                {isRecording && (
                    <div className="text-center mt-4">
                        <div className="text-primary">Recording Time: {timer} seconds</div>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" className="me-2" style={{ background: '#E54545' }} onClick={handleModalClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default VideoRecorder;
