import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from "react-router-dom";

export const ErrorHandlingComponent = ({ error }) => {
    const navigate = useNavigate();

    if (error.response) {
        // The request was made and the server responded with a status code
        console.error('Server Error:', error.response.status);
        if (error.response.status === 401) {
            // Unauthorized error
            localStorage.clear();
            toast.error("UnAuthorized", {
                position: toast.POSITION.TOP_CENTER,
            });
            navigate('/');
            // Refresh the app after a short delay
            setTimeout(() => {
                window.location.reload();
            }, 500);
        }
        // else {
        //     // Other server errors
        //     toast.error("Server Error: An unexpected error occurred. Please try again later.", {
        //         position: toast.POSITION.TOP_CENTER,
        //     });
        // }
    }
    // else if (error.request) {
    //     // The request was made but no response was received
    //     console.error('No Response from Server:', error.request);
    //     toast.error("No Response from Server. Please check your internet connection and try again.", {
    //         position: toast.POSITION.TOP_CENTER,
    //     });
    // }
    else {
        // Something happened in setting up the request that triggered an error
        // console.error('Request Error:', error.message);
        // toast.error("Request Error: An unexpected error occurred. Please try again later.", {
        //     position: toast.POSITION.TOP_CENTER,
        // });
    }


    return <ToastContainer />;
};

