import React, {useState, useEffect} from "react";
import styles from "../assets/css/adminpanel.module.css"
import '../assets/css/header.css'
import logo from "../assets/footerlogo.png"
import {RequestTable} from "../components/Tables/RequestTable";
import axios from "axios";
import {BASE_URL} from "../config";
import {useNavigate} from "react-router-dom";
import {SubscriptionTable} from "../components/Tables/SubscriptionTable";
import style from "../assets/css/LoadingComponent.module.css";
import headerIcone6 from "../assets/searchresumepro.svg";
const AdminPanel = ()=>{

    const [qouteList, setQouteList] = useState([])
    const [subscriptionList, setSubscriptionList] = useState([])
    const [activeTab, setActiveTab] = useState('request')
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem("access_token");
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const navigate = useNavigate();

    useEffect(()=>{
        fetchQoute()
        fetchSubscription()
    }, [])
    const fetchQoute = ()=>{
        setLoading(true)
        axios.get(BASE_URL + "/user-quote-requests", {headers})
            .then(res=>{
                if(res.data.status === 200){
                    if(res?.data?.user_quote_requests){
                        setQouteList(res?.data?.user_quote_requests)
                    }
                    setLoading(false)
                }
                else if(res.data.api_status == 401){
                    navigate("/admin-login")
                }
                else if(res?.data?.status===404){
                    setLoading(false);
                    setQouteList([]);
                }
                else {
                    setLoading(false)
                }
            })
            .catch(err=>{
                console.log(err)
                setLoading(false)
            })
    }

    const fetchSubscription  = ()=>{
        axios.get(BASE_URL + "/subscriptions", {headers})
            .then(res=>{
                if(res.data.status === 200){

                    setSubscriptionList(res.data.subscriptions)

                }else if(res.data.api_status == 401){
                    navigate("/admin-login")
                }
            })
            .catch(err=>{
                console.log(err)
            })
    }
    const showAllRequest= ()=>{
        setActiveTab('request')
    }
    const showAllSubscription = ()=>{
        setActiveTab('subscription')
    }
    const handleLogout = () => {
        localStorage.clear(); // Clear local storage
        navigate('/admin-login');
    };

    return (
           <div className={styles.wrapper}>
               {loading && (
                   <div className={style.loadingOverlay}>
                       <div className={style.loadingSpinner}></div>
                   </div>
               )}
               <div className={styles.leftSide}>
                   <div className={styles.leftContent}>
                   <img src={logo} alt='logo' />
                   <hr className={styles.content_divider} />
                  <ul>
                      <li onClick={showAllRequest}
                          className={`text-center ${activeTab === 'request'?styles.active:'text-light'}`}
                      >Requests</li>
                      <li onClick={showAllSubscription}
                          className={`text-center ${activeTab === 'subscription'?styles.active:'text-light'}`}
                      >Subscriptions</li>
                  </ul>
                   </div>
               </div>
               <div className={styles.rightSide}>
                   <div className={styles.right_top}>
                     <div className='p-3 d-flex align-items-center justify-content-between'>
                          <h3>Welcome To Admin Panel</h3>
                         <img onClick={handleLogout} src={headerIcone6} alt='active user' />
                     </div>
                   </div>
                   <div className={styles.right_bottom}>
                   {activeTab === 'request' &&(<RequestTable
                       qouteList ={qouteList}
                       fetchQoute={fetchQoute}
                       fetchSubscription={fetchSubscription}
                   />)}
                   {activeTab === 'subscription' &&(<SubscriptionTable
                       subscriptionList={subscriptionList}
                       fetchSubscription={fetchSubscription}
                   />)}
                   </div>

               </div>
           </div>
    )
}

export default AdminPanel;