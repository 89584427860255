import React from "react";
import {STORAGE_URL} from "../../../config";
import avatar from "../../../assets/profile5.png";
import Helper from "../../../Helper";
import {BsThreeDots} from "react-icons/bs";
import {Button, Modal} from "react-bootstrap";



export const SeekerResume = ({
                                 jobSeeker,
                                 handleConfirmDeleteResume,
                                 handleResumeCloseModal,
                                 isResumeModalOpen,
                                 handleDeleteResumeClick,
                                 handleEditResumeClick,
                                 showResumeDropdown,
                                 handleResumeOptionClick,
                                 containerRef,
                                 handleResumeClick,
                                 createResume

                             })=>{

    return (
        <div className="analytics-rd">
            {jobSeeker &&
            jobSeeker.job_seeker &&
            jobSeeker.job_seeker.resumes &&
            jobSeeker.job_seeker.resumes.length > 0 ? (
                <div className="resume-rd">
                    <h2 className="mb-3">
                        {/* <a href="javascript:void(0)">Personal Resume Copies</a> */}
                        My Resume
                    </h2>

                </div>
            ) : (

                <div className="activejob-rdtxt">
                    <h6>Please upload your resume</h6>
                    <br />
                    <button
                        className="btn w-100 d-block"
                        style={{
                            backgroundColor: "#E54545",
                            color: "#ffffff",
                            borderColor: "#E54545",
                        }}
                        onClick={createResume}
                    >
                        Upload Resume
                    </button>
                </div>
            )}


            {jobSeeker &&
                jobSeeker.job_seeker &&
                jobSeeker.job_seeker.resumes &&
                jobSeeker.job_seeker.resumes.map((resume) => (
                    <div className="personalresume-rd" key={resume.id}>
                        <div className="d-flex align-items-center personalimg-rd">
                            <img className="profile-image" src={
                                jobSeeker?.job_seeker?.user?.image
                                    ? `${STORAGE_URL}${jobSeeker?.job_seeker?.user?.image}`
                                    : avatar
                            } alt="Resume Icon" />

                            <div className="personaldash-txt"  onClick={() => handleResumeClick(resume.id)}>
                                <h6 style={{ cursor: "pointer" }}>{resume.first_name} {resume.last_name} </h6>
                                <p> {resume.preferred_job_title} </p>
                                <p className="JStext">Last Updated: {Helper.changeDateFormat(resume?.updated_at)}</p>
                                {/* Display resume skills */}
                            </div>
                        </div>

                        <div ref={containerRef} className="js_container_div">

                            <div className="dropdown">

                                <BsThreeDots className="basic_text" size={24} style={{  cursor: 'pointer' }} onClick={handleResumeOptionClick}></BsThreeDots>

                                <div
                                    className={`dropdown-menu ${showResumeDropdown ? "show" : ""}`}
                                    style={{ minWidth: "120px",    position: "absolute", right: "-10px" }} // Adjust the width as needed
                                >
                                    <li
                                        className="dropdown-item dropdown-link"
                                        onClick={() => handleEditResumeClick(resume.id)}

                                    >
                                        Edit
                                    </li>
                                    <li
                                        className="dropdown-item dropdown-link"
                                        onClick={() => handleDeleteResumeClick(resume.id)}

                                    >
                                        Delete
                                    </li>
                                </div>

                                <Modal show={isResumeModalOpen} onHide={handleResumeCloseModal} centered>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Delete Resume</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>Are you sure to delete this Resume!</Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={()=>handleResumeCloseModal()}>
                                            Close
                                        </Button>
                                        <Button variant="danger" onClick={() =>handleConfirmDeleteResume()}>
                                            Delete
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                            </div>

                        </div>
                    </div>
                ))}
        </div>
    )
}