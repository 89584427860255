import {Form} from "react-bootstrap";
import React from "react";


export const ActiveForJob = ({
                                 handleToggle,
                                 isActiveForJobs,
                                 jobSeeker
                             })=>{

    return (
        <div className="resume2-rd">
            <h2>Active for Jobs</h2>
            <div className="resume2-rd-btn">
                {jobSeeker?.job_seeker?.user.firstname && jobSeeker?.job_seeker?.user.lastname && (jobSeeker?.job_seeker?.resumes[0]?.resume_availability_file_path || jobSeeker?.job_seeker?.resumes[0]?.resume_availability_file_path)  ? (
                    // Code block executed when firstname or lastname exists
                    // This block represents the toggle button in an enabled state
                    <>
                        <Form>
                            <div className="d-flex align-items-center">
                          <span className="me-2 text-color">
                            {isActiveForJobs ? "Yes" : "No"}
                          </span>
                                <Form.Check
                                    type="switch"
                                    id="toggle-switch"
                                    label=""
                                    checked={isActiveForJobs}
                                    onChange={handleToggle}
                                />
                            </div>
                        </Form>
                    </>
                ) : (
                    // Code block executed when firstname and lastname are both missing
                    // This block represents the toggle button in a disabled state
                    <>
                        <Form>
                            <div className="d-flex align-items-center">
                          <span className="me-2 text-color">
                            No
                          </span>
                                <Form.Check
                                    type="switch"
                                    id="toggle-switch"
                                    label=""
                                    checked={false}
                                    onChange={handleToggle}
                                    disabled
                                />
                            </div>
                        </Form>
                    </>
                )}
            </div>
        </div>
    )
}