import '../src/assets/css/CreatResume-v1.css';
import PRpreferencesImage from '../src/assets/PRpreferences.svg';
import PRresumeavailabilityImage from '../src/assets/PRresumeavailability.svg';
import DeleteResumeImage from '../src/assets/deleteresumePR.svg';
import rectangelImage from "./assets/rectangel.png";
import {BsArrowLeft} from 'react-icons/bs';
import contactnforesume from '../src/assets/contactnforesume.svg'
import PRpersonalinfoImage from '../src/assets/PRpersonalinfo.svg'
import JobSeekerHeader from "./JobSeekerHeader";
import React, {useEffect, useRef, useState} from "react";
import '../src/assets/css/CreatResume-v1.css';
import styles from '../src/assets/css/LoadingComponent.module.css';
import {useNavigate} from "react-router-dom";
import BASE_URL from "./config";
import axios from "axios";
import Select from "react-select";
import VideoRecorder from "./VideoRecorder";
import {Modal, Button} from 'react-bootstrap';
import colors from "./colors";
import Validator from "./Validator";
import {ErrorHandlingComponent} from "./ErrorHandlingComponent";
import Helper from "./Helper";
import {useSelector} from "react-redux";
import {InfoIconWithTooltip} from "icon-with-tooltip";

function PostResume() {
    //Redux
    const user_data = useSelector((state) => {
        return state?.user?.user;
    });
    const [fetchError, setFetchError] = useState(false);
    const [fetchErrorMessage, setFetchErrorMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState("");
    const [usaBaseValue, setUsaBaseValue] = useState("");
    const [openConfirmModel, setOpenConfirmModel] = useState(false);
    const jobSeekerId = localStorage.getItem("jobSeekerId");
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    const resume_code = `${hours}${minutes}${seconds}`;
    const currentDateSend = new Date().toISOString().slice(0, 10);
    const currentDate = new Date().toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
    }).replace(/\//g, "/");

    //State
    const [resumeData, setResumeData] = useState({
        job_seeker_id: jobSeekerId,
        resume_code: resume_code + jobSeekerId,
        resume_post_creation_date: currentDateSend,
        us_based: 'Yes',
        preferred_job_title: '',
        first_name: '',
        last_name: '',
        address: '',
        location: '',
        searched_location: '',

        country_id: '',
        city: '',
        state: '',

        country_label: '',
        mobile: '',
        home: '',
        office: '',
        emailL: '',
        preferred_number: '',
        resume_availability: 'No',
        resume_availability_file_path: '',
        resume_availability_video_file: '',
        work_authorization_id: '',
        work_authorization_label: '',
        relocation: 'No',
        relocation_option: 'US',
        anywhere_in_us: 0,
        anywhere_in_country: 0,
        preferred_location1: '',
        preferred_location2: '',
        preferred_location3: '',
        state1: '',
        city1: '',
        state2: '',
        city2: '',
        state3: '',
        city3: '',
        preferred_job_type: 'Full Time',
        contract_type: 'Independent',
        independent_c2c_company_name: '',
        salary_expectations: '',
        expected_hourly_rate: '',
        skills: [], // Array to store selected skills
        yearsof_experience_id: 0,
        yearsof_experience_label: '',
        can_post: 0,

    });
    useEffect(() => {
        if (user_data) {
            setResumeData((prevData) => ({
                ...prevData,
                first_name: user_data.firstname ? user_data.firstname : '',
                last_name: user_data.lastname ? user_data.lastname : '',
                location:  user_data?.userable?.location ?  user_data?.userable?.location : '',
                country_id: user_data?.userable?.country?.id ? user_data?.userable?.country?.id : '',
                us_based: user_data?.userable?.us_based ? user_data?.userable?.us_based : '',
                city: user_data?.userable?.city ? user_data?.userable?.city : '',
                state: user_data?.userable?.state ? user_data?.userable?.state : '',
                address: user_data?.address ? user_data?.address: '',


            }));
            setSelectedZipCodeOptions({value: user_data?.userable?.location, label: user_data?.userable?.location});
            setSelectedCountry({
                value: user_data?.userable?.country?.name,
                label:user_data?.userable?.country?.name
            })
        }
    }, [user_data]);

    const handleRelocationOptionChange = (option) => {
        setResumeData((prevData) => ({
            ...prevData,
            relocation_option: option,
        }));
    };

    const [errors, setErrors] = useState([]);
    const handlePreferredJobTitleChange = (event) => {
        const value = event.target.value;
        setResumeData((prevState) => ({
            ...prevState,
            preferred_job_title: value,
        }));
        setErrors({...errors, preferred_job_title: ""}); // Clear the error for the title field


    };
    const handleFirstNameChange = (event) => {
        const value = event.target.value;
        setResumeData((prevState) => ({
            ...prevState,
            first_name: value,
        }));
        setErrors({...errors, first_name: ""}); // Clear the error for the title field

    };
    const handleLastNameChange = (event) => {
        const value = event.target.value;
        setResumeData((prevState) => ({
            ...prevState,
            last_name: value,
        }));
        setErrors({...errors, last_name: ""}); // Clear the error for the title field

    };
    const handleAddressChange = (event) => {
        const value = event.target.value;
        setResumeData((prevState) => ({
            ...prevState,
            address: value,
        }));
        setErrors({...errors, address: ""}); // Clear the error for the title field

    };
    const handleStateChange = (event) => {
        const value = event.target.value;
        setResumeData((prevState) => ({
            ...prevState,
            state: value,
        }));
        setErrors({...errors, state: ""}); // Clear the error for the title field

    };
    const handleCityChange = (event) => {
        const value = event.target.value;
        setResumeData((prevState) => ({
            ...prevState,
            city: value,
        }));
        setErrors({...errors, city: ""}); // Clear the error for the title field

    };
    const handleState1Change = (event) => {
        const value = event.target.value;
        setResumeData((prevState) => ({
            ...prevState,
            state1: value,
        }));
        setErrors({...errors, state1: ""}); // Clear the error for the title field

    };
    const handleCity1Change = (event) => {
        const value = event.target.value;
        setResumeData((prevState) => ({
            ...prevState,
            city1: value,
        }));
        setErrors({...errors, city1: ""}); // Clear the error for the title field

    };
    const handleState2Change = (event) => {
        const value = event.target.value;
        setResumeData((prevState) => ({
            ...prevState,
            state2: value,
        }));
        setErrors({...errors, state2: ""}); // Clear the error for the title field

    };
    const handleCity2Change = (event) => {
        const value = event.target.value;
        setResumeData((prevState) => ({
            ...prevState,
            city2: value,
        }));
        setErrors({...errors, city2: ""}); // Clear the error for the title field

    };
    const handleState3Change = (event) => {
        const value = event.target.value;
        setResumeData((prevState) => ({
            ...prevState,
            state3: value,
        }));
        setErrors({...errors, state3: ""}); // Clear the error for the title field

    };
    const handleCity3Change = (event) => {
        const value = event.target.value;
        setResumeData((prevState) => ({
            ...prevState,
            city3: value,
        }));
        setErrors({...errors, city3: ""}); // Clear the error for the title field

    };
    const handleResumeAvailabilityChange = (event) => {
        // setResumeAvailability(event.target.value);
        const value = event.target.value;
        setResumeData((prevState) => ({
            ...prevState,
            resume_availability: value,
        }));
        setErrors({...errors, resume_availability: ""}); // Clear the error for the title field

    }

    // Work Authorization Code
    const [selectedWorkAuthorization, setSelectedWorkAuthorization] = useState(null);
    const [workAuthorizations, setWorkAuthorizations] = useState([]);

    const token = localStorage.getItem("access_token");
    const headers = {
        Authorization: `Bearer ${token}`,
    };


    useEffect(() => {
        const fetchWorkAuthorizations = async () => {
            try {

                const response = await fetch(`${BASE_URL}/work_authorization`, {
                    headers,
                });
                const data = await response.json();
                if (data.status === 200) {
                    setWorkAuthorizations(data.work_authorizations);

                }

            } catch (error) {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
            }
        };

        fetchWorkAuthorizations();


    }, []);
    const work_authorization_options = workAuthorizations.map((authorization) => ({
        value: authorization.id,
        label: authorization.work_authorization,
    }));
// Skills Code
    const [selectedSkill, setSelectedSkill] = useState(null);
    const [selectedLevel, setSelectedLevel] = useState(null);
    const [selectedSkills, setSelectedSkills] = useState([]);
    useEffect(() => {
        console.log(selectedSkills);
    }, [selectedSkills]);

    // State
    const [selectedOptions, setSelectedOptions] = useState([]);

// Fetch skills and set options
    const [optionsSkills, setOptionsSkills] = useState([]);

    useEffect(() => {
        fetchSkills();
    }, []);

    const fetchSkills = () => {
        fetch(`${BASE_URL}/skills_set`, {
            headers,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 200) {
                    const skills = data.skills;
                    const optionsArray = skills.map((skill) => ({
                        value: skill.name,
                        label: skill.name,
                        id: skill.id, // Assuming the API provides the ID for each skill
                    }));
                    setOptionsSkills(optionsArray);
                }

            })
            .catch((error) => {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
            });
    };

// Handle skill select
    const handleSkillSelect = (selectedOption) => {
        const selectedOptions = Array.isArray(selectedOption) ? selectedOption : [selectedOption];
        setErrors({...errors, skills: ""}); // Clear the error for the title field
        setSelectedOptions(selectedOptions);

        if (selectedLevel) {
            selectedOptions.forEach((option) => {
                const skill = option.label;
                const skillSetId = option.value;
                setSelectedSkills((prevSkills) => [
                    ...prevSkills,
                    {skill, level: selectedLevel, skill_set_id: skillSetId},
                ]);
            });

            setSelectedLevel(null);
        }
    };

// Handle skill level select
    const [skillLevels, setSkillLevels] = useState([]);
    useEffect(() => {
        // Fetch skill levels from the API endpoint
        axios.get(`${BASE_URL}/skill_level`, {
            headers,
        })
            .then(response => {
                if (response.status === 200) {
                    const levels = response.data.skill_level.map(item => ({
                        value: item.id,
                        label: item.skill_level
                    }));
                    setSkillLevels(levels);
                    console.log(skillLevels)
                }
            })

            .catch(error => {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
            });
    }, []);

    const handleLevelSelect = (selectedOption) => {
        setSelectedLevel(selectedOption.label);
        if (selectedOptions.length > 0) {
            selectedOptions.forEach((option) => {
                const skill = option.label;
                const skillSetId = option.id;
                let replace = false;
                selectedSkills.forEach((skill) => {
                    if (skill.skill_set_id == option.id)
                        replace = true;
                })
                if (replace) {
                    const newSkill = {
                        skill: skill,
                        level: selectedOption.label,
                        skill_set_level: selectedOption.label,
                        skill_set_id: skillSetId,
                    };
                    const updatedSkills = selectedSkills.map((prevSkill) => {
                        if (prevSkill.skill_set_id === newSkill.skill_set_id) {
                            return {
                                ...prevSkill,
                                level: newSkill.skill_set_level,
                            };
                        }
                        return prevSkill;
                    });
                    setSelectedSkills(updatedSkills);
                    setResumeData((prevData) => ({
                        ...prevData,
                        skills: updatedSkills,
                    }));
                } else {
                    const newSkill = {
                        skill: skill,
                        level: selectedOption.label,
                        skill_set_level: selectedOption.label,
                        skill_set_id: skillSetId,
                    };
                    setSelectedSkills((prevSkills) => [...prevSkills, newSkill]);

                    setResumeData((prevData) => ({
                        ...prevData,
                        skills: [...prevData.skills, newSkill],
                    }));
                }
            });

            setSelectedSkill(null);
            setSelectedLevel(null);
            setSelectedOptions([]);
        }
    };

    const handleSkillRemove = (skill) => {
        setSelectedSkills((prevSkills) => {
            return prevSkills.filter((s) => s !== skill);
        });
    };

    const handleRelocationChange = (event) => {
        const value = event.target.value;
        setResumeData((prevState) => ({
            ...prevState,
            relocation: value,
        }));
    };
    const handleUSBasedChange = (event) => {

        setOpenConfirmModel(true)
        const value = event.target.value;
        setUsaBaseValue(value)
        setConfirmMessage('Are you sure you want to change your location?')

    };
    const handleConfirmUSBasedChange = () => {

        setErrors({...errors, location: ""});
        if (usaBaseValue === 'Yes' || usaBaseValue === 'yes') {
            setResumeData((prevState) => ({
                ...prevState,
                relocation_option: 'US',
            }));
        }
        setResumeData((prevState) => ({
            ...prevState,
            us_based: usaBaseValue,
        }));

        setOpenConfirmModel(false)
    }
    const navigate = useNavigate();
    const previewClickBtn = () => {
        const {resume_availability_file_path, resume_availability_video_file, ...dataWithoutFiles} = resumeData;

        const resumeFile = {
            fileName: resume_availability_file_path?.name || '',
            dataUrl: resume_availability_file_path ? URL.createObjectURL(resume_availability_file_path) : '',
        };
        localStorage.setItem('resumeData', JSON.stringify(dataWithoutFiles));
        localStorage.setItem('resumeFile', JSON.stringify(resumeFile));
        if (resume_availability_video_file) {
            const videoFile = {
                fileName: resume_availability_video_file?.name || 'Resume Video',
                dataUrl: URL.createObjectURL(resume_availability_video_file),
            };

            localStorage.setItem('videoFile', JSON.stringify(videoFile));
        } else {
            localStorage.setItem('videoFile', JSON.stringify(null));
        }
        window.open('/post-resume-preview', '_blank');
    };


    const handleJobTypeChange = (event) => {
        const value = event.target.value;
        setResumeData((prevState) => ({
            ...prevState,
            preferred_job_type: value,
        }));
    };

    const handleContractTypeChange = (event) => {
        const value = event.target.value;
        setResumeData((prevState) => ({
            ...prevState,
            contract_type: value,
        }));
    };

    const handleC2CCompanyNameChange = (event) => {
        const value = event.target.value;
        setResumeData((prevState) => ({
            ...prevState,
            independent_c2c_company_name: value,
        }));
        setErrors({...errors, independent_c2c_company_name: ""}); // Clear the error for the title field

    };

    const handleSalaryExpectationsChange = (event) => {
        if (event.target.value.length <= 0) {
            setResumeData((prevState) => ({
                ...prevState,
                salary_expectations: '',
                salary_expectations_or_expected_hourly_rate:''

            }));
        } else {
            // const numericValue = event.target.value.replace(/\D/g, '');
            // const formattedValue = parseInt(numericValue).toLocaleString();
            setResumeData((prevState) => ({
                ...prevState,
                salary_expectations: event.target.value,
            }));
        }
        setErrors({...errors, salary_expectations: "",salary_expectations_or_expected_hourly_rate:""}); // Clear the error for the title field

    };

    const handleExpectedHourlyRateChange = (event) => {
        if (event.target.value.length <= 0) {
            setResumeData((prevState) => ({
                ...prevState,
                expected_hourly_rate: '',
                salary_expectations_or_expected_hourly_rate:'',

            }));
        } else {
            // const numericValue = event.target.value.replace(/\D/g, '');
            // const formattedValue = parseInt(numericValue).toLocaleString();
            setResumeData((prevState) => ({
                ...prevState,
                expected_hourly_rate: event.target.value,
            }));
        }
        setErrors({...errors, expected_hourly_rate: "",salary_expectations_or_expected_hourly_rate:""}); // Clear the error for the title field

    };

    function getBadgeColor(skillLevel) {
        switch (skillLevel) {
            case 'Expert':
                return colors.secondaryColor;
            case 'Intermediate':
                return colors.thirdColor;
            case 'Beginner':
                return colors.primaryColor;
            default:
                return 'badge-default';
        }
    }

    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsCheckboxChecked(!isCheckboxChecked);
        setResumeData(prevJobData => ({
            ...prevJobData,
            can_post: isCheckboxChecked ? 0 : 1
        }));
        setErrors({...errors, can_post: ""}); // Clear the error for the title field

    };
    const [preferredNumber, setPreferredNumber] = useState('');
    const handlePreferredCheckboxChange = (event, phoneNumber) => {
        if (event.target.checked) {
            setPreferredNumber(phoneNumber);
            setResumeData((prevData) => ({
                ...prevData,
                preferred_number: phoneNumber,
            }));
        } else {
            setPreferredNumber('');
            setResumeData((prevData) => ({
                ...prevData,
                preferred_number: '',
            }));
        }
    };
    const formatPhoneNumber = (phoneNumber) => {
        // Remove all non-digit characters from the input
        const numericValue = phoneNumber.replace(/\D/g, '');

        // Apply the desired format (973)-997-4556 using regular expressions
        const formattedNumber = numericValue.replace(
            /(\d{3})(\d{3})(\d{4})/,
            '($1)-$2-$3'
        );

        return formattedNumber;
    };
    const handleMobileChange = (event) => {
        if (resumeData.us_based === 'Yes' || resumeData.us_based === 'yes') {
            const formattedNumber = formatPhoneNumber(event.target.value);

            setResumeData((prevState) => ({
                ...prevState,
                mobile: formattedNumber,
            }));
        } else {
            setResumeData((prevState) => ({
                ...prevState,
                mobile: event.target.value,
            }));
        }
    };

    const handleHomeChange = (event) => {

        if (resumeData.us_based === 'Yes' || resumeData.us_based === 'yes') {
            const formattedNumber = formatPhoneNumber(event.target.value);

            setResumeData((prevState) => ({
                ...prevState,
                home: formattedNumber,
            }));
        } else {
            setResumeData((prevState) => ({
                ...prevState,
                home: event.target.value,
            }));
        }
    };

    const handleOfficeChange = (event) => {
        if (resumeData.us_based === 'Yes' || resumeData.us_based === 'yes') {
            const formattedNumber = formatPhoneNumber(event.target.value);

            setResumeData((prevState) => ({
                ...prevState,
                office: formattedNumber,
            }));
        } else {
            setResumeData((prevState) => ({
                ...prevState,
                office: event.target.value,
            }));
        }
    };
    const handleEmailChange = (event) => {
        setResumeData((prevState) => ({
            ...prevState,
            email: event.target.value,
        }));
        setErrors({...errors, email: ""}); // Clear the error for the title field

    };
    //ZipCode/Location Code
    const [optionsZipCodes, setOptionsZipCpdes] = useState([]);
    const [selectedZipCodeOptions, setSelectedZipCodeOptions] = useState();

    function handleLocationInputChange(inputValue) {
        if (inputValue.length > 2) {
            fetchZipCodes(inputValue);
        }
    }

    const fetchZipCodes = (query) => {
        const isZipCode = /^\d+$/.test(query); // Check if the query is a number

        fetch(`${BASE_URL}/zipcode_filter/${query}`, {
            headers,
        })
            .then((response) => response.json())
            .then((data) => {
                const zipcodes = data.zipcodes.original;

                let optionsArray = [];
                if (Array.isArray(zipcodes)) {
                    optionsArray = zipcodes.map((zipcode) => {
                        let value, label;
                        if (isZipCode) {
                            value = zipcode.zipcode;
                            label = zipcode.zipcode;
                            // label = `${zipcode.zipcode}, ${zipcode.city}, ${zipcode.state}`;
                            label = `${zipcode.city}, ${zipcode.state}, ${zipcode.zipcode}`;

                        } else {
                            value = zipcode.zipcode;
                            label = `${zipcode.city}, ${zipcode.state}, ${zipcode.zipcode}`;
                        }
                        return {
                            value: value,
                            label: label,
                        };
                    });
                }

                setOptionsZipCpdes(optionsArray);
            })
            .catch((error) => {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
            });
    };

    function handleSelectZipCode(selectedZipcode) {
        if (selectedZipcode) {
            setSelectedZipCodeOptions(selectedZipcode);
            // Update job data with selected zipcode
            setResumeData(prevJobData => ({
                ...prevJobData,
                location: ''
            }));
            setErrors({...errors, location: ""}); // Clear the error for the title field
            fetchLocationData(selectedZipcode.value, 'location');
        } else {
            setSelectedZipCodeOptions(null);
            // Reset location field in job data
            setResumeData(prevJobData => ({
                ...prevJobData,
                location: ''
            }));
        }
        // setError({ ...error, location: "" }); // Clear the error for the title field

    }

//Preferred Location ZipCode Start
    const [selectedZipCodeOptions1, setSelectedZipCodeOptions1] = useState([]);
    const [selectedZipCodeOptions2, setSelectedZipCodeOptions2] = useState([]);
    const [selectedZipCodeOptions3, setSelectedZipCodeOptions3] = useState([]);

    // Preferred Location 1
    function handlePreferredLocationInputChange1(inputValue) {
        if (inputValue.length > 2) {
            fetchZipCodes(inputValue);
        }
    }

    function handlePreferredLocationSelect1(selectedOptions) {
        if (selectedOptions) {
            setSelectedZipCodeOptions1(selectedOptions);
            // Update job data with selected zip codes
            setResumeData((prevJobData) => ({
                ...prevJobData,
                preferred_location1: selectedOptions.label,
            }));
            fetchLocationData(selectedOptions.value, 'preferred_location1');

        } else {
            setSelectedZipCodeOptions1([]);
            // Reset preferred_location1 field in job data
            setResumeData((prevJobData) => ({
                ...prevJobData,
                preferred_location1: "",
            }));
        }
    }

// Preferred Location 2
    function handlePreferredLocationInputChange2(inputValue) {
        if (inputValue.length > 2) {
            fetchZipCodes(inputValue);
        }
    }

    function handlePreferredLocationSelect2(selectedOptions) {
        if (selectedOptions) {
            setSelectedZipCodeOptions2(selectedOptions);
            // Update job data with selected zip codes
            setResumeData((prevJobData) => ({
                ...prevJobData,
                preferred_location2: selectedOptions.label,
            }));
            fetchLocationData(selectedOptions.value, 'preferred_location2');

        } else {
            setSelectedZipCodeOptions2([]);
            // Reset preferred_location2 field in job data
            setResumeData((prevJobData) => ({
                ...prevJobData,
                preferred_location2: "",
            }));
        }
    }

// Preferred Location 3
    function handlePreferredLocationInputChange3(inputValue) {
        if (inputValue.length > 2) {
            fetchZipCodes(inputValue);
        }
    }

    function handlePreferredLocationSelect3(selectedOptions) {
        if (selectedOptions) {
            setSelectedZipCodeOptions3(selectedOptions);
            // Update job data with selected zip codes
            setResumeData((prevJobData) => ({
                ...prevJobData,
                preferred_location3: selectedOptions.label,
            }));
            fetchLocationData(selectedOptions.value, 'preferred_location3');

        } else {
            setSelectedZipCodeOptions3([]);
            // Reset preferred_location3 field in job data
            setResumeData((prevJobData) => ({
                ...prevJobData,
                preferred_location3: "",
            }));
        }
    }

    function fetchLocationData(zipcode, selected_location) {
        fetch(`${BASE_URL}/zipcode/${zipcode}`, {
            headers,
        })
            .then((response) => response.json())

            .then((data) => {
                if (data.zipcodes) {
                    let location = '';
                    if (data.zipcodes[0].city != null) {
                        location = data.zipcodes[0].city;
                    }
                    if (data.zipcodes[0].state != null) {
                        location += ', ' + data.zipcodes[0].state;
                    }
                    if (data.zipcodes[0].county_area != null) {
                        location += ', ' + data.zipcodes[0].zipcode;
                    }
                    // location+=', USA';
                    console.log(location);
                    if (selected_location === 'location') {
                        setResumeData(prevJobData => ({
                            ...prevJobData,
                            location: location,
                            temp_location: location
                        }));
                    }
                    if (selected_location === 'preferred_location1') {
                        setResumeData(prevJobData => ({
                            ...prevJobData,
                            preferred_location1: location
                        }));
                    }
                    if (selected_location === 'preferred_location2') {
                        setResumeData(prevJobData => ({
                            ...prevJobData,
                            preferred_location2: location
                        }));
                    }
                    if (selected_location === 'preferred_location3') {
                        setResumeData(prevJobData => ({
                            ...prevJobData,
                            preferred_location3: location
                        }));
                    }
                }
            })
            .catch((error) => {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
            });
    }

    //End ZipCode/Location Code
    const [isAnywhereChecked, setIsAnywhereChecked] = useState(false);
    const handleAnywhereChange = (checked) => {

        setIsAnywhereChecked(!isAnywhereChecked);
        setResumeData(prevJobData => ({
            ...prevJobData,
            anywhere_in_us: isAnywhereChecked ? 0 : 1
        }));
    };

    const [isAnywhereInTheCountry, setIsAnywhereInTheCountry] = useState(false);
    const handleAnywhereInTheCountry = (checked) => {

        setIsAnywhereInTheCountry(!isAnywhereInTheCountry);
        setResumeData(prevJobData => ({
            ...prevJobData,
            anywhere_in_country: isAnywhereInTheCountry ? 0 : 1
        }));
    };


    // Years Of Experience Code
    const [selectedYearOfExperience, setSelectedYearOfExperience] = useState(null);
    const [YearOfExperiences, setYearOfExperiences] = useState([]);

    useEffect(() => {
        const fetchYearOfExperience = async () => {
            try {
                const response = await fetch(`${BASE_URL}/years_of_experience`, {
                    headers,
                });
                const data = await response.json();
                if (data.status === 200) {
                    setYearOfExperiences(data.years_of_experience);

                }
            } catch (error) {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
            }
        };

        fetchYearOfExperience();
    }, []);
    const years_of_experience_options = YearOfExperiences.map((YearOfExperience) => ({
        value: YearOfExperience.id,
        label: YearOfExperience.experience,
    }));


    // Countries Code
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [Countries, setCountries] = useState([]);

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await fetch(`${BASE_URL}/countries`, {
                    headers,
                });
                const data = await response.json();
                if (data.status === 200) {
                    setCountries(data.countries);

                }

            } catch (error) {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
            }
        };

        fetchCountries();
    }, []);
    const countries_options = Countries.map((Country) => ({
        value: Country.id,
        label: Country.name,
    }));


    // State Code
    const [selectedState, setSelectedState] = useState(null);
    const [States, setStates] = useState([]);

    useEffect(() => {
        const fetchStates = async () => {
            try {
                if (selectedCountry) {
                    const response = await fetch(`${BASE_URL}/states/` + resumeData.country_id, {
                        headers,
                    });
                    const data = await response.json();
                    setStates(data.states);
                }
            } catch (error) {
                console.error('Error fetching years_of_experience:', error);
            }
        };

        fetchStates();
    }, [selectedCountry]);
    const states_options = States ? States.map((State) => ({
        value: State.id,
        label: State.name,
    })) : [];


    // City Code
    const [selectedCity, setSelectedCity] = useState(null);
    const [Cities, setCities] = useState([]);

    useEffect(() => {
        const fetchCities = async () => {
            try {
                if (selectedState) {
                    const response = await fetch(`${BASE_URL}/cities/` + resumeData.state_id, {
                        headers,
                    });
                    const data = await response.json();
                    setCities(data.cities);
                    console.log(data)
                }
            } catch (error) {
                console.error('Error fetching years_of_experience:', error);
            }
        };

        fetchCities();
    }, [selectedState]);
    const cities_options = Cities ? Cities.map((City) => ({
        value: City.id,
        label: City.name,
    })) : [];


    // State Code
    const [selectedState1, setSelectedState1] = useState(null);
    const [States1, setStates1] = useState([]);

    useEffect(() => {
        const fetchStates1 = async () => {
            try {
                if (selectedCountry) {
                    const response = await fetch(`${BASE_URL}/states/` + resumeData.country_id, {
                        headers,
                    });
                    const data = await response.json();
                    setStates1(data.states);
                    console.log(data);
                }
            } catch (error) {
                console.error('Error fetching states:', error);
            }
        };

        fetchStates1();
    }, [selectedCountry]);
    const states1_options = States1
        ? States1.map((state) => ({
            value: state.id,
            label: state.name,
        }))
        : [];

// City Code
    const [selectedCity1, setSelectedCity1] = useState(null);
    const [Cities1, setCities1] = useState([]);

    useEffect(() => {
        const fetchCities1 = async () => {
            try {
                if (selectedState1) {
                    const response = await fetch(`${BASE_URL}/cities/` + selectedState1.value, {
                        headers,
                    });
                    const data = await response.json();
                    setCities1(data.cities);
                    console.log(data);
                }
            } catch (error) {
                console.error('Error fetching cities:', error);
            }
        };

        fetchCities1();
    }, [selectedState1]);
    const cities1_options = Cities1
        ? Cities1.map((city) => ({
            value: city.id,
            label: city.name,
        }))
        : [];

// State2 Code
    const [selectedState2, setSelectedState2] = useState(null);
    const [States2, setStates2] = useState([]);

    useEffect(() => {
        const fetchStates2 = async () => {
            try {
                if (selectedCountry) {
                    const response = await fetch(`${BASE_URL}/states/` + resumeData.country_id, {
                        headers,
                    });
                    const data = await response.json();
                    setStates2(data.states);
                    console.log(data);
                }
            } catch (error) {
                console.error('Error fetching states:', error);
            }
        };

        fetchStates2();
    }, [selectedCountry]);
    const states2_options = States2
        ? States2.map((state) => ({
            value: state.id,
            label: state.name,
        }))
        : [];

// City2 Code
    const [selectedCity2, setSelectedCity2] = useState(null);
    const [Cities2, setCities2] = useState([]);

    useEffect(() => {
        const fetchCities2 = async () => {
            try {
                if (selectedState2) {
                    const response = await fetch(`${BASE_URL}/cities/` + selectedState2.value, {
                        headers,
                    });
                    const data = await response.json();
                    setCities2(data.cities);
                    console.log(data);
                }
            } catch (error) {
                console.error('Error fetching cities:', error);
            }
        };

        fetchCities2();
    }, [selectedState2]);
    const cities2_options = Cities2
        ? Cities2.map((city) => ({
            value: city.id,
            label: city.name,
        }))
        : [];

// State3 Code
    const [selectedState3, setSelectedState3] = useState(null);
    const [States3, setStates3] = useState([]);

    useEffect(() => {
        const fetchStates3 = async () => {
            try {
                if (selectedCountry) {
                    const response = await fetch(`${BASE_URL}/states/` + resumeData.country_id, {
                        headers,
                    });
                    const data = await response.json();
                    setStates3(data.states);
                    console.log(data);
                }
            } catch (error) {
                console.error('Error fetching states:', error);
            }
        };

        fetchStates3();
    }, [selectedCountry]);
    const states3_options = States3
        ? States3.map((state) => ({
            value: state.id,
            label: state.name,
        }))
        : [];

// City3 Code
    const [selectedCity3, setSelectedCity3] = useState(null);
    const [Cities3, setCities3] = useState([]);

    useEffect(() => {
        const fetchCities3 = async () => {
            try {
                if (selectedState3) {
                    const response = await fetch(`${BASE_URL}/cities/` + selectedState3.value, {
                        headers,
                    });
                    const data = await response.json();
                    setCities3(data.cities);
                    console.log(data);
                }
            } catch (error) {
                console.error('Error fetching cities:', error);
            }
        };

        fetchCities3();
    }, [selectedState3]);
    const cities3_options = Cities3
        ? Cities3.map((city) => ({
            value: city.id,
            label: city.name,
        }))
        : [];


    // Errors
    // File Upload Code
    const fileInputRef = useRef(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileName = file.name;
            document.getElementById('file-label').innerText = fileName;
        }
        setResumeData((prevState) => ({
            ...prevState,
            resume_availability_file_path: file,
        }));
        setErrors({...errors, resume_availability_file_path: ""}); // Clear the error for the title field

        console.log(resumeData)
    };


    //

    const [postNowClicked, setPostNowClicked] = useState(false);

    const PostNow = (e) => {
        e.preventDefault();
        const isValid = validateRequest();
        if (!isValid) {
            setPostNowClicked(true);
            return;
        }
        setLoading(true);
        setErrors([]);
        setPostNowClicked(true);

        const updatedResumeData = {...resumeData};
        // console.log(updatedResumeData)

        // console.log(resumeData.relocation);
        // console.log(resumeData.relocation_option);
        if ( (resumeData.relocation === 'Yes' || resumeData.relocation === 'yes') && resumeData.relocation_option === 'US' && updatedResumeData.anywhere_in_us === 1) {
            updatedResumeData.preferred_location1 = '';
            updatedResumeData.preferred_location2 = '';
            updatedResumeData.preferred_location3 = '';
        } else if ((resumeData.relocation === 'Yes' || resumeData.relocation === 'yes') && resumeData.relocation_option === 'Local' && updatedResumeData.anywhere_in_country === 1) {
            updatedResumeData.preferred_location1 = '';
            updatedResumeData.preferred_location2 = '';
            updatedResumeData.preferred_location3 = '';
        } else if ((resumeData.relocation === 'Yes' || resumeData.relocation === 'yes') && resumeData.relocation_option === 'US') {
            // console.log('US HAI');

            updatedResumeData.preferred_location1 = resumeData.preferred_location1 || '';
            updatedResumeData.preferred_location2 = resumeData.preferred_location2 || '';
            updatedResumeData.preferred_location3 = resumeData.preferred_location3 || '';
        } else if ((resumeData.relocation === 'Yes' || resumeData.relocation === 'yes') && resumeData.relocation_option === 'Local') {

            updatedResumeData.city1 = resumeData.city1 || '';
            updatedResumeData.state1 = resumeData.state1 || '';
            updatedResumeData.city2 = resumeData.city2 || '';
            updatedResumeData.state2 = resumeData.state2 || '';
            updatedResumeData.city3 = resumeData.city3 || '';
            updatedResumeData.state3 = resumeData.state3 || '';

            // console.log('LOCAL HAI');
            // updatedResumeData.preferred_location1 =
            //     (resumeData.preferred_location1 && resumeData.state1
            //         ? resumeData.state1 + ', '
            //         : '') +
            //     (resumeData.preferred_location1 && resumeData.city1
            //         ? resumeData.city1
            //         : '');
            //
            // updatedResumeData.preferred_location2 =
            //     (resumeData.preferred_location2 && resumeData.state2
            //         ? resumeData.state2 + ', '
            //         : '') +
            //     (resumeData.preferred_location2 && resumeData.city2
            //         ? resumeData.city2
            //         : '');
            //
            // updatedResumeData.preferred_location3 =
            //     (resumeData.preferred_location3 && resumeData.state3
            //         ? resumeData.state3 + ', '
            //         : '') +
            //     (resumeData.preferred_location3 && resumeData.city3
            //         ? resumeData.city3
            //         : '');
        }


        if (resumeData.us_based === 'Yes' || resumeData.us_based === 'yes') {
            updatedResumeData.searched_location = resumeData.location;
        } else {
            updatedResumeData.searched_location =
                (resumeData.country_label ? resumeData.country_label : '') +
                (resumeData.country_label && resumeData.state ? ', ' : '') +
                (resumeData.state ? resumeData.state : '') +
                (resumeData.state && resumeData.city ? ', ' : '') +
                (resumeData.city ? resumeData.city : '');
        }

        console.log(updatedResumeData);
        // setLoading(false)
        // return;
        const formData = new FormData();
        for (const key in updatedResumeData) {
            if (key === 'skills') {
                updatedResumeData.skills.forEach((skill, index) => {
                    formData.append(`skills[${index}][skill_set_level]`, skill.skill_set_level);
                    formData.append(`skills[${index}][skill_set_id]`, skill.skill_set_id);
                });
            } else {
                if (updatedResumeData[key] === 'null' ||
                    updatedResumeData[key] === null ||
                    updatedResumeData[key] === undefined
                ) {
                    updatedResumeData[key] = '';
                }
                formData.append(key, updatedResumeData[key]);
            }
        }

        axios
            .post(`${BASE_URL}/resume`, formData, {
                headers: headers
            }).then((response) => {
            // Handle success
            setLoading(false);
            console.log(response.data);
            navigate("/job_seeker_dashboard");
        })
            .catch((error) => {
                // Handle error
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(error)
                setErrors(error.response.data); // Set the errors state
            });
    };
    const jobTitleRef = useRef(null);
    const jobLocationRef = useRef(null);
    const jobWorkAuthorizationRef = useRef(null);
    const resumeAvailableRef = useRef(null);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const addressRef = useRef(null);
    const cityRef = useRef(null);
    const stateRef = useRef(null);
    const skillsRef = useRef(null);
    const emailRef = useRef(null);

    const city1Ref = useRef(null);
    const state1Ref = useRef(null);
    const city2Ref = useRef(null);
    const state2Ref = useRef(null);
    const city3Ref = useRef(null);
    const state3Ref = useRef(null);
    const independentC2CCompanyNameRef = useRef(null);
    const salaryExpectationsRef = useRef(null);
    const expectedHourlyRateRef = useRef(null);
    const canPostRef = useRef(null);


    useEffect(() => {
        if (postNowClicked) {
            if (errors.preferred_job_title) {
                jobTitleRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, jobTitleRef.current.getBoundingClientRect().top - 100);
            }
            else if (errors.first_name) {
                firstNameRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, firstNameRef.current.getBoundingClientRect().top - 100);
            }

            else if (errors.last_name) {
                lastNameRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, lastNameRef.current.getBoundingClientRect().top - 100);
            }
            else if (errors.address) {
                addressRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, addressRef.current.getBoundingClientRect().top - 100);
            }

            else if (errors.city) {
                cityRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, cityRef.current.getBoundingClientRect().top - 100);
            }
            else if (errors.state) {
                stateRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, stateRef.current.getBoundingClientRect().top - 100);
            }
            else if (errors.location ||errors.country_id) {
                jobLocationRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state

                window.scrollBy(0, jobLocationRef.current.getBoundingClientRect().top - 100);
            }
            else if (errors.email) {
                emailRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, emailRef.current.getBoundingClientRect().top - 100);
            }
            else if (errors.resume_availability_file_path) {
                resumeAvailableRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, resumeAvailableRef.current.getBoundingClientRect().top - 100);
            }
            else if (errors.skills) {
                skillsRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, skillsRef.current.getBoundingClientRect().top - 100);
            }
            else if (errors.work_authorization_id) {
                jobWorkAuthorizationRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state

                window.scrollBy(0, jobWorkAuthorizationRef.current.getBoundingClientRect().top - 100);
            }
            else if (errors.state1) {
                state1Ref.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, state1Ref.current.getBoundingClientRect().top - 100);
            }
            else if (errors.city1) {
                city1Ref.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, city1Ref.current.getBoundingClientRect().top - 100);
            }
            else if (errors.state2) {
                state2Ref.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, state2Ref.current.getBoundingClientRect().top - 100);
            }
            else if (errors.city2) {
                city2Ref.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, city2Ref.current.getBoundingClientRect().top - 100);
            }
            else if (errors.state3) {
                state3Ref.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, state3Ref.current.getBoundingClientRect().top - 100);
            }
            else if (errors.city3) {
                city3Ref.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, city3Ref.current.getBoundingClientRect().top - 100);
            }
            else if (errors.independent_c2c_company_name) {
                independentC2CCompanyNameRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, independentC2CCompanyNameRef.current.getBoundingClientRect().top - 100);
            }
            else if (errors.salary_expectations) {
                salaryExpectationsRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, salaryExpectationsRef.current.getBoundingClientRect().top - 100);
            }
            else if (errors.expected_hourly_rate) {
                expectedHourlyRateRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, expectedHourlyRateRef.current.getBoundingClientRect().top - 100);
            }
            else if (errors.can_post) {
                canPostRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
                setPostNowClicked(false); // Reset postNowClicked state
                window.scrollBy(0, canPostRef.current.getBoundingClientRect().top - 100);
            }

        }
    }, [postNowClicked,errors.can_post,errors.salary_expectations,errors.expected_hourly_rate,errors.independent_c2c_company_name,errors.city1,errors.city2,errors.city3, errors.state1,errors.state2,errors.state3,errors.preferred_job_title,errors.first_name,errors.last_name,errors.address,errors.email,errors.city,errors.state, errors.location, errors.work_authorization_id, errors.resume_availability_file_path,errors.skills]);

// Inside your component
    const [showModal, setShowModal] = useState(false);

    const openVideoRecorder = () => {
        setShowModal(true);
    };
    const handleVideoRecording = (videoBlob) => {
        // Do something with the videoBlob, such as storing it in the resumeData state
        setResumeData((prevJobData) => ({
            ...prevJobData,
            resume_availability_video_file: videoBlob

        }));
    };
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

//Validations Errors
    const validateRequest = () => {
        const newErrors = Validator.ValidateResume(resumeData);
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    return (
        <div>
            <JobSeekerHeader/>
            {/*---------------------------------------------------------------*/}
            {/*                         NAVBAR END                            */}
            {/*---------------------------------------------------------------*/}
            {/*---------------------------------------------------------------*/}
            {/*                        MAin Body Start                        */}
            {/*---------------------------------------------------------------*/}
            <div className="main-RP">
                <div className="ERscreen">
                    <hr className="custom-hr"/>
                    <div className="postcontainer-CR">
                        {/* <h2>Post a Resume</h2> */}
                        <div className=" AJbackbtn">
                            <button onClick={() => navigate(-1)}><BsArrowLeft/> Back</button>
                        </div>
                        <div className="AJheadtitle">

                            <div className="AJtitle w-100">
                                <h2>Post a Resume</h2>
                            </div>
                        </div>
                        <div style={{position: "relative"}}>
                            <img
                                src={rectangelImage}
                                alt="Image Line"
                                className="rectangel-image"
                            />
                            <hr className="hr-style"/>
                        </div>
                        <section action>
                            <div className="jobfield-RP">
                                {/*<div className="job-id-PR">*/}
                                {/*    <label>Resume ID:</label>*/}
                                {/*    <input type="text" placeholder={resumeData.resume_code} disabled/>*/}
                                {/*    {errors.resume_code && (*/}
                                {/*        <div className="text-danger">{errors.resume_code}</div>)*/}
                                {/*    }*/}
                                {/*</div>*/}
                                {/*<div className="job-date-PR">*/}
                                {/*    <label>Date Created:</label>*/}
                                {/*    <input type="text" placeholder={currentDate} value={currentDate} disabled/>*/}
                                {/*</div>*/}
                                <div className="job-date-PR">
                                    <div className="d-flex wrap align-items-center">
                                        <label style={{marginRight: '10px'}}>Date Created:</label>
                                        <label className="normal-font">
                                            {currentDate}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {/*----US-Based Or International */}

                            <div className="adress-CR">
                                <div className="CRrow1">
                                    <label>Are you located in the United States?<span
                                        className="CRlabelcol"></span></label>
                                    <div className="toggle-radio">
                                        <input
                                            type="radio"
                                            name="non-us-based"
                                            id="non-us-based"
                                            value="Yes"
                                            checked={resumeData.us_based === 'Yes' ||resumeData.us_based === 'yes'}
                                            disabled={true}
                                            onChange={handleUSBasedChange}
                                        />
                                        <label htmlFor="non-us-based">Yes</label>
                                        <input
                                            type="radio"
                                            name="us-based"
                                            id="us-based"
                                            value="No"
                                            checked={resumeData.us_based === 'No' || resumeData.us_based === 'no'}
                                            disabled={true}
                                            onChange={handleUSBasedChange}
                                        />
                                        <label htmlFor="us-based">No</label>

                                    </div>

                                </div>
                            </div>


                            <div className="job-title-CR" ref={jobTitleRef}>
                                <label>Preferred Job Title<span className="CRlabelcol">:</span><span
                                    className="required-field">*</span></label>
                                <input
                                    type="text"
                                    placeholder="Preferred Job Title"
                                    value={resumeData.preferred_job_title}
                                    onChange={handlePreferredJobTitleChange}
                                />
                                {errors.preferred_job_title && (
                                    <div className="text-danger">{errors.preferred_job_title}</div>)
                                }
                            </div>
                            <div className="adress-CR">

                                <div className="CRrow1">
                                    <label>Years of Experience<span className="CRlabelcol">:</span> </label>
                                    <Select
                                        id="years_of_experience"
                                        name="term-contract"
                                        options={years_of_experience_options}
                                        value={selectedYearOfExperience}
                                        isClearable={true}
                                        onChange={(selectedOption) => {
                                            setSelectedYearOfExperience(selectedOption);

                                            // Check if selectedOption is not null before accessing its properties
                                            const id = selectedOption ? parseInt(selectedOption.value) : null;
                                            const label = selectedOption ? selectedOption.label : '';

                                            setResumeData((prevJobData) => ({
                                                ...prevJobData,
                                                yearsof_experience_id: id,
                                                years_of_experience_label: label
                                            }));

                                            setErrors((prevError) => ({
                                                ...prevError,
                                                yearsof_experience_id: ""
                                            }));
                                        }}
                                        placeholder="Select Years of Experience"
                                        isSearchable
                                        required
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                padding: '4px 5px',
                                                border: '1px solid rgba(46, 46, 46, 0.5)',
                                                boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                borderRadius: '8px',
                                                fontFamily:colors.fontFamily
                                            }),
                                        }}
                                    />
                                    {errors.yearsof_experience_id && (
                                        <div className="text-danger">{errors.yearsof_experience_id}</div>)
                                    }


                                </div>
                            </div>
                            <div style={{position: "relative"}}>
                                <img
                                    src={rectangelImage}
                                    alt="Image Line"
                                    className="rectangel-image"
                                />
                                <hr className="hr-style"/>
                            </div>
                            {/*---------------------------/// General Information ///-----------------------*/}
                            <section className="generalinosec">
                                <div className="formheading-RP">
                                    <img src={PRpersonalinfoImage} className="images-label-texts"/>
                                    <h5>General Information</h5>
                                    <div className="App">
                                        <InfoIconWithTooltip
                                            text="General Information Imported from User Profile: To update, please modify the profile directly."
                                            placement="right" />
                                    </div>
                                </div>
                                {/*--------------------------- Full Name -----------------------*/}
                                <div className="adress-CR">
                                    <div className="CRrow1" ref={firstNameRef}>
                                        {console.log(resumeData.first_name,'name')}
                                        <label>First Name<span className="CRlabelcol">:</span></label>
                                        <input
                                            type="text"
                                            placeholder="First Name"
                                            value={resumeData.first_name}
                                            disabled={true}
                                            onChange={handleFirstNameChange}
                                        />
                                        {errors.first_name && (
                                            <div className="text-danger">{errors.first_name}</div>)
                                        }
                                    </div>
                                    <div className="CRrow1" ref={lastNameRef}>
                                        <label>Last Name<span className="CRlabelcol">:</span></label>
                                        <input
                                            type="text"
                                            placeholder="Last Name"
                                            value={resumeData.last_name}
                                            disabled={true}
                                            onChange={handleLastNameChange}/>
                                        {errors.last_name && (
                                            <div className="text-danger">{errors.last_name}</div>)
                                        }
                                    </div>
                                </div>
                                {/*--------------------------Adress / City -------------------- */}
                                <div className="adress-CR">
                                    <div className="CRrow1" ref={addressRef}>
                                        <label>Address<span className="CRlabelcol">:</span></label>
                                        <input
                                            className="adres-CR"
                                            type="text"
                                            placeholder="Address"
                                            value={resumeData.address}
                                            disabled={true}

                                            onChange={handleAddressChange}
                                        />
                                        {errors.address && (
                                            <div className="text-danger">{errors.address}</div>)
                                        }
                                    </div>
                                    {/* -------------------------Location/city , state-------------- */}
                                </div>

                                {(resumeData.us_based.trim() == 'Yes' || resumeData.us_based.trim() == 'yes') && (
                                    <div className="adress-CR">

                                        <div className="CRrow1" ref={jobLocationRef}>

                                            <label>Location (ZIP Code/City)<span className="CRlabelcol">:</span><span
                                                className="required-field">*</span> </label>

                                            <Select
                                                options={optionsZipCodes}
                                                placeholder="Search ZIP Code/City"
                                                value={selectedZipCodeOptions}
                                                onChange={handleSelectZipCode}
                                                onInputChange={handleLocationInputChange}
                                                isSearchable={true}
                                                isClearable={true}
                                                isMulti={false}
                                                isDisabled={true}
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        padding: '4px 5px',
                                                        border: '1px solid rgba(46, 46, 46, 0.5)',
                                                        boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                        borderRadius: '8px'
                                                    }),
                                                }}
                                            />


                                            {errors.location && (
                                                <div className="text-danger">{errors.location}</div>)
                                            }
                                        </div>
                                    </div>
                                )}


                                {/*-----City Code------*/}
                                {(resumeData.us_based === 'No' || resumeData.us_based === 'no') && (
                                    <div className="adress-CR">

                                        <div className="CRrow1" ref={cityRef}>
                                            <label>City<span className="CRlabelcol">:</span> </label>

                                            <input
                                                className="adres-CR"
                                                type="text"
                                                placeholder="Enter City"
                                                value={resumeData?.city}
                                                disabled={true}
                                                onChange={handleCityChange}

                                            />
                                            {errors.city && (
                                                <div className="text-danger">{errors.city}</div>)
                                            }
                                        </div>
                                    </div>
                                )}

                                {/*----State Code---------------*/}
                                {(resumeData.us_based === 'No'  || resumeData.us_based === 'no') && (
                                    <div className="adress-CR">

                                        <div className="CRrow1" ref={stateRef}>
                                            <label>State/Province<span className="CRlabelcol">:</span> </label>
                                            <input
                                                className="adres-CR"
                                                type="text"
                                                placeholder="Enter State/Province"
                                                value={resumeData?.state}
                                                disabled={true}
                                                onChange={handleStateChange}

                                            />
                                            {errors.state && (
                                                <div className="text-danger">{errors.state}</div>)
                                            }
                                        </div>

                                    </div>
                                )}

                                {/*-----Country Code------*/}
                                {(resumeData.us_based === 'No' ||  resumeData.us_based === 'no') && (
                                    <div className="adress-CR">
                                        <div className="CRrow1" ref={jobLocationRef}>
                                            <label>Country<span className="CRlabelcol">:</span> </label>
                                            <Select
                                                name="term-contract"
                                                options={countries_options}
                                                value={selectedCountry}
                                                isDisabled={true}
                                                isClearable={true}
                                                onChange={(selectedOption) => {
                                                    setSelectedCountry(selectedOption);
                                                    setSelectedState(null); // Reset selected state
                                                    setSelectedCity(null); // Reset selected city
                                                    const id = selectedOption ? parseInt(selectedOption.value) : null;
                                                    setResumeData((prevJobData) => ({
                                                        ...prevJobData,
                                                        // location: selectedOption ? selectedOption.label : '',
                                                        country_id: id,
                                                        country_label: selectedOption ? selectedOption.label : ''
                                                    }));
                                                    setErrors((prevError) => ({
                                                        ...prevError,
                                                        country_id: '',
                                                    }));
                                                }}

                                                placeholder="Select Country"
                                                isSearchable
                                                required
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        padding: '4px 5px',
                                                        border: '1px solid rgba(46, 46, 46, 0.5)',
                                                        boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                        borderRadius: '8px'
                                                    }),
                                                }}
                                            />


                                            {errors.country_id && (
                                                <div className="text-danger">{errors.country_id}</div>)
                                            }
                                        </div>


                                    </div>
                                )}


                                <div style={{position: "relative"}}>
                                    <img
                                        src={rectangelImage}
                                        alt="Image Line"
                                        className="rectangel-image"
                                    />
                                    <hr className="hr-style"/>
                                </div>

                                {/*--------------------------Home -------------------- */}
                                <div className="formheading-RP contacticon">
                                    <img src={contactnforesume} className="images-label-texts"/>
                                    <h5>Contact Info</h5>
                                </div>
                                {/* Mobile */}
                                <div className="adress-CR">
                                    <div className="CRrow1">
                                        <label>Mobile<span className="CRlabelcol">:</span></label>
                                        <input
                                            className="adres-CR"
                                            type="text"
                                            placeholder="Mobile"
                                            value={resumeData.mobile}
                                            onChange={handleMobileChange}
                                        />
                                    </div>
                                    <div className="CRrow1 pre-phone-num">
                                        <input
                                            className="pre-phone-num-CR"
                                            type="checkbox"
                                            checked={preferredNumber === 'mobile'}
                                            onChange={(event) => handlePreferredCheckboxChange(event, 'mobile')}
                                        />

                                        <p>Preferred Number</p>
                                    </div>
                                </div>

                                {/* Home */}
                                <div className="adress-CR">
                                    <div className="CRrow1">
                                        <label>Home<span className="CRlabelcol">:</span></label>
                                        <input
                                            className="adres-CR"
                                            type="text"
                                            placeholder="Home"
                                            value={resumeData.home}
                                            onChange={handleHomeChange}
                                        />
                                    </div>
                                    <div className="CRrow1 pre-phone-num">
                                        <input
                                            className="pre-phone-num-CR"
                                            type="checkbox"
                                            checked={preferredNumber === 'home'}
                                            onChange={(event) => handlePreferredCheckboxChange(event, 'home')}
                                        />

                                        <p>Preferred Number</p>
                                    </div>
                                </div>

                                {/* Office */}
                                <div className="adress-CR">
                                    <div className="CRrow1">
                                        <label>Office<span className="CRlabelcol">:</span></label>
                                        <input
                                            className="adres-CR"
                                            type="text"
                                            placeholder="Office"
                                            value={resumeData.office}
                                            onChange={handleOfficeChange}
                                        />
                                    </div>

                                    <div className="CRrow1 pre-phone-num">
                                        <input
                                            className="pre-phone-num-CR"
                                            type="checkbox"
                                            checked={preferredNumber === 'office'}
                                            onChange={(event) => handlePreferredCheckboxChange(event, 'office')}
                                        />

                                        <p>Preferred Number</p>
                                    </div>
                                </div>
                                <div className="adress-CR" ref={emailRef}>
                                    <div className="CRrow1">
                                        <label>Alternative Email<span className="CRlabelcol">:</span></label>
                                        <input
                                            className="adres-CR"
                                            type="text"
                                            placeholder="Email"
                                            value={resumeData?.email}
                                            onChange={handleEmailChange}
                                        />
                                    </div>

                                </div>
                                {errors.email && (
                                    <div className="text-danger">{errors.email}</div>)
                                }

                                {/* Other form components */}
                                <div style={{position: "relative"}}>
                                    <img
                                        src={rectangelImage}
                                        alt="Image Line"
                                        className="rectangel-image"
                                    />
                                    <hr className="hr-style"/>
                                </div>
                                {/*--------------------------------------Resume Availability ---------------*/}
                                <div className="formheading-RP" ref={resumeAvailableRef}>
                                    <img src={PRresumeavailabilityImage} className="images-label-texts"/>
                                    <h5>Resume Searchable</h5>
                                    <div className="App">
                                        <InfoIconWithTooltip
                                            text="If yes, recruiters can search and view the document resume information. If no, the resume is not accessible to recruiters."
                                            placement="right"/>
                                    </div>
                                </div>
                                <div className="CRrow3">
                                    <div className="toggle-radio">
                                        <input
                                            type="radio"
                                            name="resumeavailbility"
                                            id="resumeavailbility-2"
                                            value="No"
                                            checked={resumeData.resume_availability === 'No' || resumeData.resume_availability === 'no'}
                                            onChange={handleResumeAvailabilityChange}
                                        />
                                        <label htmlFor="resumeavailbility-2">No</label>
                                        <input
                                            type="radio"
                                            name="resumeavailbility"
                                            id="resumeavailbility-1"
                                            value="Yes"
                                            checked={resumeData.resume_availability === 'Yes' || resumeData.resume_availability === 'yes'}
                                            onChange={handleResumeAvailabilityChange}
                                        />
                                        <label htmlFor="resumeavailbility-1">Yes</label>
                                    </div>

                                </div>


                                    <>
                                        <div className="resume-upload-container">
                                            <div className="resume-upload">
                                                {/* {/ File Upload /} */}
                                                <div className="resumedesign">
                                                    <p>Upload Resume!</p>

                                                    <label htmlFor="myfile" className="btn btn-primary  btn-sm "
                                                           style={{background: colors.primaryColor}}>
                                                        <input type="file" id="myfile" name="myfile"
                                                               onChange={handleFileChange}  accept=".pdf,.doc,.docx"
                                                               ref={fileInputRef} className="d-none"/>
                                                        Choose File
                                                    </label>
                                                    <span id="file-label">No file chosen</span>
                                                    {/* <br/>
                                            <p></p> */}
                                                </div>
                                                <p>Supported formats: PDF, Word (DOC, DOCX) </p>
                                            </div>
                                            {errors.resume_availability_file_path && (
                                                <div className="text-danger">{errors.resume_availability_file_path}</div>)
                                            }

                                            <div className="video-resume">
                                                <p>Record Video Resume!</p>
                                                <button
                                                    className="btn btn-primary btn-sm me-2"
                                                    onClick={openVideoRecorder}
                                                    style={{background: colors.primaryColor}}
                                                >
                                                    Open Video Recorder
                                                </button>
                                                {/* {/ Place your video resume component or additional elements here /} */}
                                                <VideoRecorder
                                                    showModal={showModal}
                                                    setShowModal={setShowModal}
                                                    onVideoRecorded={handleVideoRecording}
                                                />

                                                {resumeData.resume_availability_video_file && (
                                                    <div>
                                                        {/* {/ Display your video recording confirmation or other content here /} */}
                                                        <p></p>
                                                        <p>Video recorded successfully!</p>
                                                        {/ Open the video modal when the button is clicked /}
                                                        <Button className="btn btn-primary btn-sm me-2"
                                                                style={{
                                                                    background: colors.thirdColor,
                                                                    border: colors.thirdColor
                                                                }}
                                                                onClick={() => setIsVideoModalOpen(true)}>View Video
                                                            Resume</Button>
                                                        {/* {/ Render the video modal /} */}
                                                        <Modal
                                                            isOpen={isVideoModalOpen}
                                                            onRequestClose={() => setIsVideoModalOpen(false)}
                                                            style={{
                                                                content: {
                                                                    width: '500px', // Adjust the width as needed
                                                                    height: '400px', // Adjust the height as needed
                                                                    margin: 'auto',

                                                                },
                                                            }}
                                                        >
                                                            {/ Render the video with controls for playback /}
                                                            <video
                                                                src={URL.createObjectURL(resumeData.resume_availability_video_file)}
                                                                controls
                                                                style={{
                                                                    width: '100%',
                                                                    height: '90%',
                                                                }}
                                                            />
                                                            {/ Add a close button /}
                                                            <Button variant="danger" className="me-2"
                                                                    style={{background: colors.secondaryColor}}
                                                                    onClick={() => setIsVideoModalOpen(false)}>Close</Button>
                                                        </Modal>

                                                    </div>
                                                )}

                                            </div>

                                        </div>

                                    </>


                                {/*-------------------------- Skills / Levls -----------------------*/}

                                <div className="adress-CR">
                                    <div className="CRrow1" ref={skillsRef}>
                                        <label>Select Skills<span className="CRlabelcol">:</span><span
                                            className="required-field">*</span></label>
                                        <Select
                                            options={optionsSkills}
                                            placeholder="Select Skill"
                                            value={selectedOptions}
                                            onChange={handleSkillSelect}
                                            isSearchable={true}
                                            isMulti={false}
                                            isClearable={true}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    padding: '4px 5px',
                                                    border: '1px solid rgba(46, 46, 46, 0.5)',
                                                    boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                    borderRadius: '8px',
                                                    fontFamily:colors.fontFamily

                                                }),
                                            }}
                                        />
                                        {errors.skills && (
                                            <div className="text-danger">{errors.skills}</div>)
                                        }

                                    </div>

                                    <div className="CRrow1">
                                        <label>Skill Level<span className="CRlabelcol">:</span></label>
                                        <Select
                                            options={skillLevels}
                                            onChange={handleLevelSelect}
                                            value={selectedLevel}
                                            isSearchable={true}
                                            isMulti={false}
                                            isClearable={true}
                                            placeholder="Select Skill Level"
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    padding: '4px 5px',
                                                    border: '1px solid rgba(46, 46, 46, 0.5)',
                                                    boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                    borderRadius: '8px',
                                                    fontFamily:colors.fontFamily

                                                }),
                                            }}
                                        />
                                    </div>

                                </div>
                                {selectedSkills.length > 0 && (
                                    <div className="col-lg-12 three-d-perspective" style={{marginTop: '24px'}}>
                                        <ul className="list-group">
                                            {selectedSkills.map((skill, index) => (
                                                <li className="list-group-item d-flex justify-content-between align-items-center"
                                                    key={index}>
                                                    {skill.skill}
                                                    <div>

                                                <span className="badge rounded-pill" style={{
                                                    backgroundColor: getBadgeColor(skill.level),
                                                    color: '#FFF'
                                                }}>
                                                    {skill.level}
                                                  </span>
                                                        <img
                                                            src={DeleteResumeImage}
                                                            alt="Delete"
                                                            onClick={() => handleSkillRemove(skill)}
                                                            className="delete-icon images-label-texts"
                                                        />
                                                    </div>
                                                </li>
                                            ))}

                                        </ul>
                                    </div>
                                )}
                                <div style={{position: "relative"}}>
                                    <img
                                        src={rectangelImage}
                                        alt="Image Line"
                                        className="rectangel-image"
                                    />
                                    <hr className="hr-style"/>
                                </div>
                                {/* ------------ Work Authorization And Job Location Preferences--------------*/}
                                <section className="jobpreferencesec" ref={jobWorkAuthorizationRef}>
                                    <div className="formheading-RP">
                                        <img src={PRpreferencesImage} className="images-label-texts"/>
                                        <h5>Work Authorization And Job Location Preferences</h5>
                                    </div>
                                    {/* ---------------Work Authorization / relocation ---------*/}
                                    <div className="CRrow1">
                                        <br/>
                                        <label>Work Authorization<span className="CRlabelcol">:</span><span
                                            className="required-field">*</span></label>
                                        <Select
                                            id="work_authorization"
                                            name="term-contract"
                                            options={work_authorization_options}
                                            value={selectedWorkAuthorization}
                                            isClearable={true}
                                            onChange={(selectedOption) => {
                                                setSelectedWorkAuthorization(selectedOption);

                                                const id = selectedOption ? parseInt(selectedOption.value) : null;
                                                setResumeData((prevState) => ({
                                                    ...prevState,
                                                    work_authorization_id: id,
                                                    work_authorization_label: selectedOption.label
                                                }));
                                                setErrors((prevError) => ({
                                                    ...prevError,
                                                    work_authorization_id: ""
                                                }));
                                            }}

                                            placeholder="Work Authorization"
                                            isSearchable={true}

                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    padding: '4px 5px',
                                                    border: '1px solid rgba(46, 46, 46, 0.5)',
                                                    boxShadow: '0px 1px 4px rgba(115, 153, 191, 0.05)',
                                                    borderRadius: '8px',
                                                }),
                                            }}
                                        />
                                        {errors.work_authorization_id && (
                                            <div className="text-danger">{errors.work_authorization_id}</div>)
                                        }

                                    </div>


                                    <div className="adress-CR">
                                        <div className="CRrow1">
                                            <label>Relocation<span className="CRlabelcol">:</span></label>
                                            <div className="toggle-radio">
                                                <input
                                                    type="radio"
                                                    name="relocation"
                                                    id="relocation-2"
                                                    value="No"
                                                    checked={resumeData.relocation === 'No' || resumeData.relocation === 'no'}
                                                    onChange={handleRelocationChange}
                                                />
                                                <label htmlFor="relocation-2">No</label>
                                                <input
                                                    type="radio"
                                                    name="relocation"
                                                    id="relocation-1"
                                                    value="Yes"
                                                    checked={resumeData.relocation === 'Yes' || resumeData.relocation === 'yes'}
                                                    onChange={handleRelocationChange}
                                                />
                                                <label htmlFor="relocation-1">Yes</label>
                                            </div>
                                            {errors.relocation && (
                                                <div className="text-danger">{errors.relocation}</div>
                                            )}
                                        </div>
                                    </div>

                                    {(resumeData.relocation === 'Yes' || resumeData.relocation === 'yes') && (resumeData.us_based=='No' || resumeData.us_based=='no') &&(

                                        <div className="adress-CR">
                                            <div className="CRrow1">
                                                <label>Relocation Option<span className="CRlabelcol">:</span></label>
                                                {/*<div className="toggle-radio usaradio">*/}
                                                <div className="toggle-radio ">
                                                <input
                                                        type="radio"
                                                        name="relocationOption"
                                                        id="relocationOption-us"
                                                        value="US"
                                                        checked={resumeData.relocation_option === 'US'}
                                                        onChange={() => handleRelocationOptionChange('US')}
                                                    />
                                                    <label htmlFor="relocationOption-us">US</label>
                                                    {(resumeData.us_based === 'No' || resumeData.us_based === 'no')&& (
                                                        <>
                                                            <input
                                                                type="radio"
                                                                name="relocationOption"
                                                                id="relocationOption-local"
                                                                value="Local"
                                                                checked={resumeData.relocation_option === 'Local'}
                                                                onChange={() => handleRelocationOptionChange('Local')}
                                                            />
                                                            <label htmlFor="relocationOption-local">Non US</label>
                                                        </>
                                                    )}
                                                </div>

                                            </div>
                                        </div>
                                    )}

                                    {resumeData.relocation_option === 'US' && (
                                        <>
                                            {(resumeData.relocation === 'Yes' || resumeData.relocation === 'yes') && (
                                                <div className="checkbox-container">
                                                    <input
                                                        type="checkbox"
                                                        id="anywhere-checkbox"
                                                        checked={isAnywhereChecked}
                                                        onChange={(e) => handleAnywhereChange(e.target.checked)}

                                                    />
                                                    <span className="pj-checkbox-text">Anywhere in US</span>
                                                </div>
                                            )}
                                            {/*{!isAnywhereChecked && resumeData.relocation === 'Yes' && (*/}
                                                {(resumeData.relocation === 'Yes' || resumeData.relocation === 'yes')  && (

                                                <>

                                                    <div className="CRrow1 PL1">

                                                        <label>OR<span
                                                            className="CRlabelcol"></span></label><br/>
                                                        Preferred Location(s)<span
                                                            className="">:</span>
                                                        <Select
                                                            options={optionsZipCodes}
                                                            placeholder="Search ZIP Code/City"
                                                            value={selectedZipCodeOptions1}
                                                            onChange={handlePreferredLocationSelect1}
                                                            onInputChange={handlePreferredLocationInputChange1}
                                                            isSearchable={true}
                                                            isMulti={false}
                                                            isClearable={true}  // Set isClearable to true to enable clearable functionality
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    marginTop: "10px",
                                                                    padding: "4px 5px",
                                                                    border: "1px solid rgba(46, 46, 46, 0.5)",
                                                                    boxShadow: "0px 1px 4px rgba(115, 153, 191, 0.05)",
                                                                    borderRadius: "8px",
                                                                }),
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="CRrow1 PL">
                                                        <Select
                                                            options={optionsZipCodes}
                                                            placeholder="Search ZIP Code/City"
                                                            value={selectedZipCodeOptions2}
                                                            onChange={handlePreferredLocationSelect2}
                                                            onInputChange={handlePreferredLocationInputChange2}
                                                            isSearchable={true}
                                                            isMulti={false}
                                                            isClearable={true}  // Set isClearable to true to enable clearable functionality

                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    marginTop: "10px",
                                                                    padding: "4px 5px",
                                                                    border: "1px solid rgba(46, 46, 46, 0.5)",
                                                                    boxShadow: "0px 1px 4px rgba(115, 153, 191, 0.05)",
                                                                    borderRadius: "8px",
                                                                }),
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="CRrow1 PL">
                                                        <Select
                                                            options={optionsZipCodes}
                                                            placeholder="Search ZIP Code/City"
                                                            value={selectedZipCodeOptions3}
                                                            onChange={handlePreferredLocationSelect3}
                                                            onInputChange={handlePreferredLocationInputChange3}
                                                            isSearchable={true}
                                                            isMulti={false}
                                                            isClearable={true}  // Set isClearable to true to enable clearable functionality

                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    marginTop: "10px",
                                                                    padding: "4px 5px",
                                                                    border: "1px solid rgba(46, 46, 46, 0.5)",
                                                                    boxShadow: "0px 1px 4px rgba(115, 153, 191, 0.05)",
                                                                    borderRadius: "8px",
                                                                }),
                                                            }}
                                                        />
                                                    </div>
                                                </>
                                            )}


                                        </>
                                    )}

                                    {(resumeData.relocation === 'Yes' || resumeData.relocation === 'yes')  && resumeData.relocation_option === 'Local' && (
                                        <>
                                            {(resumeData.relocation === 'Yes' || resumeData.relocation === 'yes')  && (
                                                <div className="checkbox-container">
                                                    <input
                                                        type="checkbox"
                                                        id="anywhere-checkbox"
                                                        checked={isAnywhereInTheCountry}
                                                        onChange={(e) => handleAnywhereInTheCountry(e.target.checked)}

                                                    />
                                                    <span className="pj-checkbox-text">
                                          {resumeData.country_label ? `Anywhere in ${resumeData.country_label}` : 'Anywhere in Country'}
                                        </span>
                                                </div>
                                            )}

                                                <div className="adress-CR">
                                                    <div className="CRrow1" ref={city1Ref}>
                                                        <label>City 1<span className="CRlabelcol"></span></label>
                                                        <input
                                                            className="adres-CR"
                                                            type="text"
                                                            placeholder="Enter City 1"
                                                            value={resumeData?.city1}
                                                            onChange={handleCity1Change}

                                                        />
                                                        {errors.city1 && (
                                                            <div className="text-danger">{errors.city1}</div>)
                                                        }
                                                    </div>
                                                    <div className="CRrow1" ref={state1Ref}>
                                                        <label>State 1<span className="CRlabelcol"></span></label>
                                                        <input
                                                            className="adres-CR"
                                                            type="text"
                                                            placeholder="Enter State 1"
                                                            value={resumeData?.state1}
                                                            onChange={handleState1Change}

                                                        />
                                                        {errors.state1 && (
                                                            <div className="text-danger">{errors.state1}</div>)
                                                        }
                                                    </div>

                                                </div>


                                                <div className="adress-CR">

                                                    <div className="CRrow1" ref={city2Ref}>
                                                        <label>City 2<span className="CRlabelcol"></span></label>
                                                        <input
                                                            className="adres-CR"
                                                            type="text"
                                                            placeholder="Enter City 2"
                                                            value={resumeData?.city2}
                                                            onChange={handleCity2Change}
                                                        />
                                                        {errors.city2 && (
                                                            <div className="text-danger">{errors.city2}</div>)
                                                        }
                                                    </div>
                                                    <div className="CRrow1" ref={state2Ref}>
                                                        <label>State 2<span className="CRlabelcol"></span></label>
                                                        <input
                                                            className="adres-CR"
                                                            type="text"
                                                            placeholder="Enter State 2"
                                                            value={resumeData?.state2}
                                                            onChange={handleState2Change}
                                                        />
                                                        {errors.state2 && (
                                                            <div className="text-danger">{errors.state2}</div>)
                                                        }
                                                    </div>
                                                </div>


                                                <div className="adress-CR">

                                                    <div className="CRrow1" ref={city3Ref}>
                                                        <label>City 3<span className="CRlabelcol"></span></label>
                                                        <input
                                                            className="adres-CR"
                                                            type="text"
                                                            placeholder="Enter City 3"
                                                            value={resumeData?.city3}
                                                            onChange={handleCity3Change}
                                                        />
                                                        {errors.city3 && (
                                                            <div className="text-danger">{errors.city3}</div>)
                                                        }
                                                    </div>
                                                    <div className="CRrow1" ref={state3Ref}>
                                                        <label>State 3<span className="CRlabelcol"></span></label>
                                                        <input
                                                            className="adres-CR"
                                                            type="text"
                                                            placeholder="Enter State 3"
                                                            value={resumeData?.state3}
                                                            onChange={handleState3Change}
                                                        />
                                                        {errors.state3 && (
                                                            <div className="text-danger">{errors.state3}</div>)
                                                        }
                                                    </div>
                                                </div>



                                        </>
                                    )}

                                    {/*----------------------Job Type --------------- */}
                                    <div className="adress-CR">

                                        <div className="CRrow2">
                                            <label>Preferred Job Type<span className="CRlabelcol">:</span></label>
                                            <div className="toggle-radio">
                                                <input
                                                    type="radio"
                                                    name="jobtype"
                                                    id="jobtype-1"
                                                    value="Full Time"
                                                    checked={resumeData.preferred_job_type === 'Full Time'}
                                                    onChange={handleJobTypeChange}
                                                />
                                                <label htmlFor="jobtype-1">Full Time</label>
                                                <input
                                                    type="radio"
                                                    name="jobtype"
                                                    id="jobtype-2"
                                                    value="Contract"
                                                    checked={resumeData.preferred_job_type === 'Contract'}
                                                    onChange={handleJobTypeChange}
                                                />
                                                <label htmlFor="jobtype-2">Contract</label>
                                                <input
                                                    type="radio"
                                                    name="jobtype"
                                                    id="jobtype-3"
                                                    value="1099"
                                                    checked={resumeData.preferred_job_type === '1099'}
                                                    onChange={handleJobTypeChange}
                                                />
                                                <label htmlFor="jobtype-3">1099</label>

                                                <input
                                                    type="radio"
                                                    name="jobtype"
                                                    id="jobtype-any"
                                                    value="Any"
                                                    checked={resumeData.preferred_job_type === 'Any'}
                                                    onChange={handleJobTypeChange}
                                                />
                                                <label htmlFor="jobtype-any">Any</label>

                                            </div>
                                            {errors.preferred_job_type && (
                                                <div className="text-danger">{errors.preferred_job_type}</div>)
                                            }
                                            {resumeData.preferred_job_type === 'Contract' && (
                                                <div className="adress-CR">
                                                    {/* <label><span className="CRlabelcol"></span></label> */}

                                                    <div className="contractc2c">
                                                        <label>Contract Type<span
                                                            className="CRlabelcol">:</span></label>
                                                        <div className="toggle-radio">
                                                            <input
                                                                type="radio"
                                                                name="contract"
                                                                id="contract-1"
                                                                value="Independent"
                                                                checked={resumeData.contract_type === 'Independent'}
                                                                onChange={handleContractTypeChange}
                                                            />
                                                            <label htmlFor="contract-1">Independent</label>
                                                            <input
                                                                type="radio"
                                                                name="contract"
                                                                id="contract-2"
                                                                value="Corp to Corp"
                                                                checked={resumeData.contract_type === 'Corp to Corp'}
                                                                onChange={handleContractTypeChange}
                                                            />
                                                            <label htmlFor="contract-2">Corp to Corp</label>
                                                        </div>
                                                    </div>

                                                    {resumeData.contract_type && (

                                                        <div className="CRrow1">
                                                            <label>Independent/C2C Company Name<span
                                                                className="CRlabelcol">:</span></label>
                                                            <div className="salary-range" ref={independentC2CCompanyNameRef}>
                                                                <div className="C2C">
                                                                    <input
                                                                        className="C2Cclass"
                                                                        type="text"
                                                                        placeholder="Independent/C2C Company Name"
                                                                        value={resumeData.independent_c2c_company_name}
                                                                        onChange={handleC2CCompanyNameChange}
                                                                    />
                                                                    {errors.independent_c2c_company_name && (
                                                                        <div className="text-danger">{errors.independent_c2c_company_name}</div>)
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {/*---------------------------Salary Expectations-------------*/}
                                    <div className="salary-CRsec">
                                        <div className="salary-PR">
                                            <label>Salary Expectations ($)<span className="CRlabelcol">:</span><span
                                                className="required-field">*</span></label>
                                            <div className="salary-range">
                                                <div className="min-salary" ref={salaryExpectationsRef}>
                                                    <input
                                                        className="min-salary-PR"
                                                        type="text"
                                                        placeholder="Expected Annual Salary"
                                                        value={resumeData.salary_expectations}
                                                        onChange={handleSalaryExpectationsChange}
                                                    />
                                                    {errors.salary_expectations && (
                                                        <div className="text-danger">{errors.salary_expectations}</div>)
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="salary-PR">
                                            <label>Expected Hourly Rate ($)<span className="CRlabelcol">:</span><span
                                                className="required-field">*</span></label>
                                            <div className="salary-range">
                                                <div className="min-salary" ref={expectedHourlyRateRef}>
                                                    <input
                                                        className="min-salary-PR"
                                                        type="text"
                                                        placeholder="Expected Hourly Rate"
                                                        value={resumeData.expected_hourly_rate}
                                                        onChange={handleExpectedHourlyRateChange}
                                                    />
                                                    {errors.expected_hourly_rate && (
                                                        <div className="text-danger">{errors.expected_hourly_rate}</div>)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {errors.salary_expectations_or_expected_hourly_rate && (
                                        <div className="text-danger" style={{textAlign:"center"}}>{errors.salary_expectations_or_expected_hourly_rate}</div>
                                    )}
                                </section>


                            </section>


                            <div className="checkbox-container" ref={canPostRef}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={isCheckboxChecked}
                                        onChange={handleCheckboxChange}
                                    />
                                    <span className="pj-checkbox-text">I confirm all details are accurate.</span>
                                </label>

                                {errors.can_post && (
                                    <div className="text-danger">{errors.can_post}</div>)
                                }
                            </div>

                            <div style={{position: "relative"}}>
                                <img
                                    src={rectangelImage}
                                    alt="Image Line"
                                    className="rectangel-image"
                                />
                                <hr className="hr-style"/>
                            </div>
                            <div className="crudbtnCR gap-1">
                                <div className="crudbtnlft-CR d-flex gap-3">
                                    <button>Cancel</button>
                                    {/*<button><a href="postjobpreview.html"> Preview</a></button>*/}
                                    <button onClick={previewClickBtn}>Preview</button>
                                    <button>Save For Later</button>
                                </div>
                                <div className="crudbtnrlt-CR">
                                    <button onClick={PostNow}>Post Now</button>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            {loading && (
                <div className={styles.loadingOverlay}>
                    <div className={styles.loadingSpinner}></div>
                </div>
            )}
            <Modal show={openConfirmModel} onHide={() => setOpenConfirmModel(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Switch</Modal.Title>
                </Modal.Header>
                <Modal.Body>{confirmMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" size="sm" onClick={handleConfirmUSBasedChange}>Confirm</Button>
                    <Button variant="secondary" size="sm" onClick={() => setOpenConfirmModel(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

            {/*<Modal*/}
            {/*    show={openConfirmModel}*/}
            {/*    onHide={() => setOpenConfirmModel(false)}*/}
            {/*    size="sm"*/}
            {/*    centered*/}
            {/*    */}
            {/*>*/}
            {/*    <Modal.Body>*/}
            {/*        <p>{confirmMessage}</p>*/}
            {/*    </Modal.Body>*/}
            {/*    <Modal.Footer className="d-flex justify-content-between">*/}
            {/*        <Button variant="success" size="sm" onClick={handleConfirmUSBasedChange}>*/}
            {/*            Confirm*/}
            {/*        </Button>*/}
            {/*        <Button variant="danger" size="sm" onClick={() => setOpenConfirmModel(false)}>*/}
            {/*            Cancel*/}
            {/*        </Button>*/}
            {/*    </Modal.Footer>*/}
            {/*</Modal>*/}
            {fetchError &&  <ErrorHandlingComponent error={fetchErrorMessage} />}

        </div>

    )
}

export default PostResume;