import React from "react";


export const JobAnalytics = ({
                                 jobSeeker
                             })=>{

    return(
        <div className=" analytics-rd">
            <h2>Job Analytics</h2>
            <div className="dashjobicon-rd ">
                <p>No of Jobs Applied </p>
                <p>{jobSeeker?.job_seeker?.job_applications.length} </p>
            </div>

            {/*<div className="dashjobicon-rd ">*/}
            {/*    <p>No of Recruiters Sent New Jobs{" "}</p>*/}
            {/*    <p> 0 </p>*/}
            {/*</div>*/}
        </div>
    )
}