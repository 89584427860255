import React from 'react';
import PageHeader from "./PageHeader";
import '../src/assets/css/staticpages.css'; // Import the CSS file
import {BsArrowLeft, BsBriefcaseFill} from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import colors from "./colors";
import {SiApplenews} from "react-icons/si";

const Press = () => {
    const navigate = useNavigate();

    return (
        <>
            <PageHeader />
            <div className="sp-about-us-content">
                <div className="sp-background-style">
                    <div className="AJbackbtn">
                        <button onClick={() => navigate('/')}><BsArrowLeft /> Back</button>
                    </div>
                    <h1>
                        <SiApplenews className="mb-1" style={{ color: colors.primaryColor }} /> Press
                    </h1>
                    <div className="content-container">
                        <div className="sp-text-content">
                            <p>
                                Stay up-to-date with the latest news, updates, and press releases from Rezmaze. Our commitment to transparency and excellence in the professional world is reflected in our press coverage.
                            </p>

                            <p>
                                <strong>Press Releases:</strong>
                            </p>

                            <p>
                                Here you can find our official press releases covering important announcements, partnerships, and significant milestones in our journey.
                            </p>

                            <p>
                                <strong>Media Coverage:</strong>
                            </p>

                            <p>
                                Discover what the media is saying about Rezmaze. Explore articles, interviews, and features that highlight our contributions to the job market and professional development.
                            </p>

                            <p>
                                <strong>Company Updates:</strong>
                            </p>

                            <p>
                                Get insights into our latest company updates, product launches, and innovations. We're constantly evolving, and this is where you can see our progress.
                            </p>

                            <p>
                                <strong>Stay Informed:</strong>
                            </p>

                            <p>
                                Subscribe to our press notifications to receive timely updates directly in your inbox. Be the first to know about our news and achievements.
                            </p>

                            <p>
                                <strong>Contact Us:</strong>
                            </p>

                            <p>
                                If you are a member of the media or a journalist seeking more information about Rezmaze, our team is here to assist you. Contact our press relations team for inquiries and interviews.
                            </p>

                            <p>
                                Thank you for your interest in Rezmaze. We're dedicated to being at the forefront of professional development and job opportunities, and we appreciate your support.
                            </p>

                            <p>
                                Explore our press section to dive into the latest updates and stay connected with our journey.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Press;
