import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import style from '../src/assets/css/LoadingComponent.module.css';
import { BsArrowLeft } from 'react-icons/bs';
import headerIcon2 from '../src/assets/headericone2.svg';
import {AiTwotoneHeart} from "react-icons/ai";
import {BASE_URL} from "./config";
import {Button, Modal} from "react-bootstrap";
import axios from "axios";
import JobSeekerHeader from "./JobSeekerHeader";
import Helper from "./Helper";
import {ErrorHandlingComponent} from "./ErrorHandlingComponent";

function FavoriteJobs() {
    const location = useLocation();
    const navigate = useNavigate();
    const { defaultfrom } = location.state || {}; // Get the passed state data
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem("access_token");
    const [favoriteJobs, setFavoriteJobs] = useState([]);
    const jobSeekerId = localStorage.getItem("jobSeekerId");
    const [fetchError, setFetchError] = useState(false);
    const [fetchErrorMessage, setFetchErrorMessage] = useState(false);

    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const fetchFavoriteJobs = async () => {
        setLoading(true);
        try {
            // Fetch favorite resumes using the provided endpoint and payload
            const response = await fetch(`${BASE_URL}/job_ad/get_favorite`, {
                method: "POST",
                headers: {
                    ...headers,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    job_seeker_id: jobSeekerId, // Update with your employer_id
                }),
            });

            const data = await response.json();
            if (data.status === 200) {
                setFavoriteJobs(data.favorite_job);
            }
            else if (data.status==404) {
                setFavoriteJobs([]);
            }
        } catch (error) {
            setLoading(false)
            setFetchError(true)
            setFetchErrorMessage(error)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFavoriteJobs();
    }, []);

    const handleJobClick = (jobId) => {
        const from="/fav-jobs";
        navigate('/post_a_job_preview', { state: { jobId,from} });

    };


    const handleConfirmDeleteJob = async (jobId) => {
        setLoading(true);
        const url = `${BASE_URL}/fav_job/${jobId}`
        const method = 'DELETE';
        const payload = {
            job_ad_id: jobId,
            job_seeker_id: parseInt(jobSeekerId)
        };
        try {
            const response = await axios({
                method: method,
                url: url,
                headers: headers,
                data:payload
            });

            const data = response.data;
            if(data.status==200){
                fetchFavoriteJobs();

            }
        } catch (error) {
            setLoading(false)
            setFetchError(true)
            setFetchErrorMessage(error)
        }
    };

    const redirectionScreen = ()=>{
        navigate(-1);
    }

    return (
        <div>
            {loading && (
                <div className={style.loadingOverlay}>
                    <div className={style.loadingSpinner}></div>
                </div>
            )}
            <JobSeekerHeader />
            <div className="container">
                <div className=".job-JB-main">
                    <div className="JBmain">
                        <div className=" JAbackbtn mb-">
                                <button onClick={redirectionScreen}>
                                    <BsArrowLeft /> Back
                                </button>
                            </div>

                        <div className="RDheadtitle fvthead ">
                                <h2>Favorite Jobs</h2>
                        </div>
                      <div className="table-responsive">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Job Title</th>
                                <th>Location</th>
                                <th>Relocation</th>
                                <th>Interview</th>
                                <th>Job Mode</th>
                                <th>Compensation</th>
                                <th>Posted On</th>
                                <th>Action</th>

                            </tr>
                            </thead>
                            <tbody>
                            {favoriteJobs.map((job) => (
                                <tr key={job.id}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleJobClick(job.job_ad.id)}
                                >
                                    <td>{job.job_ad.job_title}</td>
                                    <td>{job.job_ad.location}</td>
                                    <td>{job.job_ad.relocation}</td>
                                    <td>{job.job_ad.interview}</td>
                                    <td>{job.job_ad.job_mode}</td>
                                    <td>{job.job_ad.compensation}</td>
                                    <td>{Helper.changeDateFormat(job?.job_ad?.created_at)}</td>

                                    <td>
                                        <div className="editjobsicon">
                                            {/*<i*/}
                                            {/*    className="fa-solid fa-file"*/}
                                            {/*    style={{ cursor: "pointer" }}*/}
                                            {/*    data-bs-toggle="tooltip"*/}
                                            {/*    onClick={() => handleJobClick(job.job_ad.id)}*/}
                                            {/*    data-bs-placement="top"*/}
                                            {/*    title="View Job"*/}
                                            {/*></i>*/}

                                            {/* <i
                                                className="fa-sharp fa-solid fa-trash"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleDeleteJobClick(job.job_ad.id)}
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                title="Delete"
                                            ></i> */}
                                         {/* <img
                                                onClick={() => handleDeleteJobClick(job.job_ad.id)}
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                title="Delete"
                                                 src={headerIcon2}
                                               
                                             /> */}
                                            <AiTwotoneHeart
                                                title="Unfavorite"
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Stop event propagation
                                                    handleConfirmDeleteJob(job.job_ad.id);
                                                }}
                                                style={{ color: '#e54545', cursor: "pointer" }}
                                                size={24}
                                            />


                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            </div>
            {fetchError &&  <ErrorHandlingComponent error={fetchErrorMessage} />}
        </div>
    )
}

export default FavoriteJobs;
