import React,{useState} from "react";
import {Link} from "react-router-dom";
import {DropdownButton, Dropdown} from 'react-bootstrap';
import {GiHamburgerMenu} from "react-icons/gi";

export const ScreenLink = ({handleEmployee, handleJoSeeker, handleAboutScroll,videoResume,socialMediaCapabilities})=>{

    return( <div className="d-flex gap-4 justify-content-end align-items-center">
        {/*<Link to="#" className="nav_link active_link">HOME</Link>*/}

        {/*<Link to="#" className="nav_link" onClick={handleAboutScroll}>WHY REZMAZE</Link>*/}
        {/*<Link to="#" className="nav_link">CONTACT US</Link>*/}
            <Link className="nav_link">
                <Dropdown>

                    <Dropdown.Toggle
                        style={{
                            backgroundColor: 'transparent',
                            border: 'transparent',
                            padding: '0px',
                            color: '#1DA1F2',
                        }}
                    >
                        <span className="nav_link">WHATS NEW</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ zIndex: 9999 }}>
                        <Dropdown.Item href="" style={{ color: '#1DA1F2' }} onClick={videoResume}>
                            VIDEO RESUME
                        </Dropdown.Item>
                        <Dropdown.Item href="" style={{ color: '#1DA1F2' }} onClick={socialMediaCapabilities}>
                            SOCIAL MEDIA CAPABILITIES
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Link>
        <Link className="nav_link">
            <Dropdown>

                <Dropdown.Toggle
                    style={{
                        backgroundColor: 'transparent',
                        border: 'transparent',
                        padding: '0px',
                        color: '#1DA1F2',
                    }}
                >
                    <span className="nav_link">LOGIN</span>
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ zIndex: 9999 }}>
                    <Dropdown.Item href="" style={{ color: '#1DA1F2' }} onClick={handleEmployee}>
                        EMPLOYER
                    </Dropdown.Item>
                    <Dropdown.Item href="" style={{ color: '#1DA1F2' }} onClick={handleJoSeeker}>
                        JOB SEEKER
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Link>
        <style>
            {`
              .dropdown-toggle::after {
                color: #003580;
              }
        
              .dropdown-menu {
                border: none;
                background-color: #f8f9fa;
              }
        
              .dropdown-item {
                color: #003580;
                padding: 10px 20px;
                // transition: background-color 0.3s ease;
              }
        
              .dropdown-item:hover {
                background-color: #e9ecef;
              }
            `}
        </style>
    </div>
    )
}


export const TabScreen = ({handleEmployee, handleJoSeeker, handleAboutScroll,videoResume,socialMediaCapabilities}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <>
            <Dropdown data-bs-theme="dark" show={isDropdownOpen} onToggle={toggleDropdown}>
                <Dropdown.Toggle
                    id="dropdown-button-dark-example1"
                    variant="secondary"
                    className="d-flex align-items-center"
                >
                    <GiHamburgerMenu size={20} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1" active>
                        <Link to="/" className="nav_link">
                            HOME
                        </Link>
                    </Dropdown.Item>


                    <Dropdown>
                        <Dropdown.Toggle variant="none" id="login-dropdown" className="nav_link">
                            WHATS NEW
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={videoResume}>VIDEO RESUME</Dropdown.Item>
                            <Dropdown.Item onClick={socialMediaCapabilities}>SOCIAL MEDIA CAPABILITIES</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown.Item href="#">
                        {/*<Link to="#" className="nav_link" onClick={handleAboutScroll}>*/}
                        {/*    WHY REZMAZE*/}
                        {/*</Link>*/}
                    </Dropdown.Item>
                    <Dropdown.Divider />

                    <Dropdown>
                        <Dropdown.Toggle variant="none" id="login-dropdown" className="nav_link">
                            LOGIN
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={handleEmployee}>EMPLOYER</Dropdown.Item>
                            <Dropdown.Item onClick={handleJoSeeker}>JOB SEEKER</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Dropdown.Menu>
            </Dropdown>
            <style>
                {`
              .dropdown-toggle::after {
               display:none;
              }
            `}
            </style>

        </>
    );
}
