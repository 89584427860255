import React from "react";


export const JobExperience = ({
                                  resumeCounter,
                                  filter,
                                  handleExperienceChange
                              })=>{

    return (
        <div className="resumeupdate work-authoriz work-authorizbord">
            <h2>Years of Experience</h2>

            {resumeCounter && resumeCounter?.experienceCount.map((item) => (
                <div className="work-experience" key={item.experience_range}>
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                checked={
                                    filter.years_of_experience_id.some(id => id === item.id)
                                }
                                onClick={() => handleExperienceChange(item.id)}
                            />
                            {item.experience_range}
                        </label>
                    </div>
                    <p></p>
                    {/*<p>{item.count}</p>*/}
                </div>
            ))}
        </div>
    )
}