export let BASE_URL = "";
//Live
export let RECAPTCHA_KEY = "6LedNs8pAAAAAGRuNp84IbDOogE0rXOu6ts23TLp";

//Local
// export let RECAPTCHA_KEY = "6Lc0YvoUAAAAAPOkwSv0dHHZy0I7A_kEFXHWLArN";

// let BASE_URL = "";
// const Storage_URL="http://localhost:8000/storage/";
//
// export default BASE_URL = "http://localhost/rezmaze_backend/api";
// export const STORAGE_URL = "http://localhost/rezmaze_backend/storage/app/public/";
// export const Resume_File_Path ="http://localhost/rezmaze_backend/public/resume-viewer";

// const Storage_URL ="https://rezmazebackend.globalconsultingpk.com/storage/"
//
// export default BASE_URL = "https://rezmazebackend.globalconsultingpk.com/api";
// export const STORAGE_URL = "https://rezmazebackend.globalconsultingpk.com/storage/app/public/";
// export const Resume_File_Path ="https://rezmazebackend.globalconsultingpk.com/public/resume-viewer";

export default BASE_URL = "https://backend.rezmaze.com/api";
export const STORAGE_URL = "https://backend.rezmaze.com/storage/app/public/";
export const Resume_File_Path ="https://backend.rezmaze.com/public/resume-viewer";

// export default BASE_URL = "https://rezmazebackend.globaltech.consulting/api";
// export const STORAGE_URL = "https://rezmazebackend.globaltech.consulting/storage/app/public/";
// export const Resume_File_Path ="https://rezmazebackend.globaltech.consulting/public/resume-viewer";
export const IS_MOBILE_VERSION=true;