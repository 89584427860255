// PageHeader.js

import React, {useRef} from 'react';
import logoImage from "./assets/logo4.svg";
import footerlogo from "./assets/footerlogo.png";
import messagefooter from "./assets/messagefooter.png";
import {FaFacebookF, FaLinkedinIn, FaTwitter} from "react-icons/fa6";
import playStore from "./assets/googlePlay.png";
import appStore from "./assets/appStore.png";
import {useNavigate} from "react-router-dom";
import TermsAndConditions from "./TermsAndConditions";
import appConfig from "./config/appConfig";

const Footer = () => {
    const aboutRef = useRef();
    const handleAboutScroll = () => {
        aboutRef.current?.scrollIntoView({behavior: 'smooth'});
    };
    const navigate = useNavigate();

    const careers = (event) => {
        if (event.ctrlKey || event.metaKey) {
            // If Ctrl key (Windows/Linux) or Command key (Mac) is pressed, open in a new tab
            window.open('/careers', '_blank');
        } else {
            scrollToTop();
            // If not, navigate to the "About Us" page
            navigate('/careers');
        }
    };
    const press = (event) => {
        if (event.ctrlKey || event.metaKey) {
            // If Ctrl key (Windows/Linux) or Command key (Mac) is pressed, open in a new tab
            window.open('/press', '_blank');
        } else {
            scrollToTop();
            navigate('/press');
        }
    };
    const socialMediaCapabilities = (event) => {
        if (event.ctrlKey || event.metaKey) {
            // If Ctrl key (Windows/Linux) or Command key (Mac) is pressed, open in a new tab
            window.open('/social-media-capabilities', '_blank');
        } else {
            scrollToTop();
            navigate('/social-media-capabilities');
        }
    };
    const videoResumeGuide = (event) => {
        if (event.ctrlKey || event.metaKey) {
            // If Ctrl key (Windows/Linux) or Command key (Mac) is pressed, open in a new tab
            window.open('/video-resume-guide', '_blank');
        } else {
            scrollToTop();
            navigate('/video-resume-guide')
        }
    };
    const pricing = (event) => {
        if (event.ctrlKey || event.metaKey) {
            // If Ctrl key (Windows/Linux) or Command key (Mac) is pressed, open in a new tab
            window.open('/pricing', '_blank');
        } else {
            scrollToTop();
            navigate('/pricing')
        }
    };
    const helpDesk  = (event) => {
        if (event.ctrlKey || event.metaKey) {
            // If Ctrl key (Windows/Linux) or Command key (Mac) is pressed, open in a new tab
            window.open('/help-desk', '_blank');
        } else {
            scrollToTop();
            navigate('/help-desk')
        }
    };
    const support  = (event) => {
        if (event.ctrlKey || event.metaKey) {
            // If Ctrl key (Windows/Linux) or Command key (Mac) is pressed, open in a new tab
            window.open('/support', '_blank');
        } else {
            scrollToTop();
            navigate('/support')
        }
    };
    const privacyPolicy  = (event) => {
        if (event.ctrlKey || event.metaKey) {
            // If Ctrl key (Windows/Linux) or Command key (Mac) is pressed, open in a new tab
            window.open('/privacy-policy', '_blank');
        } else {
            scrollToTop();
            navigate('/privacy-policy')
        }
    };
    const termAndCondition  = (event) => {
        if (event.ctrlKey || event.metaKey) {
            // If Ctrl key (Windows/Linux) or Command key (Mac) is pressed, open in a new tab
            window.open('/term-condition', '_blank');
        } else {
            scrollToTop();
            navigate('/term-condition')
        }
    };
    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };
    const handleAboutUsClick = (event) => {
        if (event.ctrlKey || event.metaKey) {
            // If Ctrl key (Windows/Linux) or Command key (Mac) is pressed, open in a new tab
            window.open('/about-us', '_blank');
        } else {
            scrollToTop();
            navigate('/about-us');
        }
    };

    return (



        <div className="footermain-lp">
            <div className="footer-lp">
                <div className="footerlower-lp">
                    <div className="footercol1-lp">
                        <img src={footerlogo} alt="Rezmaze"/>
                        <div className="footercontact-lp">
                            <img src={messagefooter} alt="Rezmaze"/>
                            {/*   <TbMessage style={{color: '#e54545'}} size={44}></TbMessage>*/}
                            <div>
                                <h3 className='mb-0'>Contact us at</h3>
                                <p >support@rezmaze.com</p>
                            </div>

                        </div>

                        <div className="footersocialicon-lp">
                            <h3 className='mb-0'>Follow us on:</h3>
                            <div className='social-content'>
                                <FaFacebookF role='button'  size={24} title='Facebook' className='facebookIcon' ></FaFacebookF>
                            </div>
                            <div className='social-content'>
                                <FaTwitter role='button' style={{}} title='Twitter' size={24} className='twitterIcon'></FaTwitter>
                            </div>
                            <div className='social-content'>
                                <FaLinkedinIn role='button' style={{}} title='Linkedin' size={24} className='linkedinIcon'></FaLinkedinIn>
                            </div>

                        </div>
                    </div>
                    <div className="footercol2-lp">
                        <h3>Company</h3>
                        {/*<p onClick={handleAboutScroll}>Why Rezmaze</p>*/}
                        <a href='about-us' > <p>About Us</p></a>
                        <p onClick={careers}>Careers</p>
                        <p onClick={press}>Press</p>
                    </div>
                    <div className="footercol3-lp">
                        <h3>Product</h3>
                        {/*<p>Features</p>*/}
                        <p onClick={pricing}>Pricing</p>
                        {/*<p>News</p>*/}
                        <p onClick={helpDesk}>Help desk</p>
                        <p onClick={support}>Support</p>
                    </div>
                    <div className="footercol3-lp">
                        <h3>Features</h3>
                        <a href='video-resume-guide'><p>Video Resume</p></a>
                        <p onClick={socialMediaCapabilities}>Social Media Capabilities</p>
                    </div>
                    <div>
                        <h2>Get the Rezmaze app</h2>
                        <p>Unlock your tech potential</p>
                        <div className='app_content'>
                            <a href='https://play.google.com/store/games?hl=en&gl=US&pli=1' target='_blank'>
                                <img className='play_img' src={playStore} alt='playstore'/>
                            </a>
                            <a href='https://www.apple.com/app-store/' target='_blank'>
                                <img className='app_img' src={appStore} alt='appstore'/>
                            </a>
                        </div>
                    </div>

                </div>
            </div>

    <div className="below_main_footer">
        <div className="footer-lp below_main_footer_content">

            <small>Copyright ©2024 Rezmaze. All Rights Reserved</small>

            <div className='d-flex gap-3'>
                <p className='text-light mb-0'  role="button" onClick={privacyPolicy}>Privacy Policy</p>
                <p className='text-light mb-0' role="button" onClick={termAndCondition}>Terms & Conditions</p>
                <p className='text-light mb-0'>Version {appConfig.version}</p>  {/* Dynamically added version number */}

            </div>
        </div>
    </div>
        </div>
    );
};

export default Footer;
