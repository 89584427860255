class Validator {
    static JobValidate(jobData) {

        const newErrors = {};
        // Validate 'employer_id' field
        const employer_id = parseInt(jobData?.employer_id, 10);
        if (!jobData?.employer_id || !Number.isInteger(employer_id) || employer_id < 0 || employer_id > 4294967295) {
            newErrors.employer_id = 'Employer ID is required and must be a valid integer between 0 and 4294967295';
        }
// Validate 'job_status_id' field
        const job_status_id = parseInt(jobData?.job_status_id, 10);
        if (!jobData?.job_status_id || !Number.isInteger(job_status_id) || job_status_id < 0 || job_status_id > 4294967295) {
            newErrors.job_status_id = 'Job Status ID is required and must be a valid integer between 0 and 4294967295';
        }
// Validate 'code' field
        if (!jobData?.code || typeof jobData?.code !== 'string' || jobData?.code.length > 20) {
            newErrors.code = 'Code is required and must be a string with a maximum length of 20 characters';
        }
// Validate 'job_creation_date' field
        if (!jobData?.job_creation_date || !Date.parse(jobData?.job_creation_date)) {
            newErrors.job_creation_date = 'Job Creation Date is required and must be a valid date';
        }
// Validate 'job_title' field
        if (!jobData?.job_title || typeof jobData?.job_title !== 'string' || jobData?.job_title.length > 255) {
            newErrors.job_title = 'Job Title is required and must be a string with a maximum length of 255 characters';
        }
// Validate 'location' field
        if (!jobData?.location || typeof jobData?.location !== 'string' || jobData.location?.length > 255) {
            newErrors.location = 'Please select Location option';
        }
// Validate 'work_authorization' field
        if (!jobData?.work_authorization || !Array.isArray(jobData?.work_authorization) || jobData?.work_authorization.length === 0) {
            newErrors.work_authorization = 'Please select at least one Work Authorization option';
        } else {
            // Validate each element in 'work_authorization' array
            jobData?.work_authorization.forEach((item, index) => {
                if (!item || !Number.isInteger(item) || item <= 0) {
                    newErrors[`work_authorization.${index}`] = `Invalid value in Work Authorization array at index ${index}`;
                }
            });
        }
// Validate 'job_type' field
        if (!jobData?.job_type || typeof jobData?.job_type !== 'string' || jobData?.job_type.length > 255) {
            newErrors.job_type = 'Job Type is required and must be a string with a maximum length of 255 characters';
        }
// Validate 'contract_type_id' field
        const contract_type_id = parseInt(jobData?.contract_type_id, 10);
        if (jobData?.job_type === 'Contract' && (!contract_type_id || !Number.isInteger(contract_type_id))) {
            newErrors.contract_type_id = 'Contract Type is required when Job Type is Contract';
        }
// Validate 'interview' field
        if (!jobData?.interview || typeof jobData?.interview !== 'string' || jobData?.interview.length > 255) {
            newErrors.interview = 'Interview is required and must be a string with a maximum length of 255 characters';
        }
// Parse the value to an integer
        const noOfRoundsInterview = parseInt(jobData?.no_of_rounds_interview, 10);
        if (!jobData?.no_of_rounds_interview || !Number.isInteger(noOfRoundsInterview) || noOfRoundsInterview < 1 || noOfRoundsInterview > 5) {
            newErrors.no_of_rounds_interview = 'Number of Rounds in Interview is required and must be an integer between 1 and 5';
        }
// Validate 'job_mode' field
        if (!jobData?.job_mode || typeof jobData?.job_mode !== 'string' || jobData?.job_mode.length > 255) {
            newErrors.job_mode = 'Job Mode is required and must be a string with a maximum length of 255 characters';
        }
// Validate 'compensation' field
        if (!jobData?.compensation || typeof jobData?.compensation !== 'string' || jobData?.compensation.length > 255) {
            newErrors.compensation = 'Compensation is required and must be a string with a maximum length of 255 characters';
        }
// Validate 'compensation_rate' field
        const compensation_rate = (jobData.compensation_rate);
        if (jobData?.compensation_rate  &&  !Number(compensation_rate)  ||( compensation_rate < 0 || compensation_rate > 1000000)) {
            newErrors.compensation_rate = 'Compensation Rate must be a valid number between 0 and 1000000';
        }
// Validate 'bonus' field
        if (!jobData?.bonus || typeof jobData?.bonus !== 'string' || jobData?.bonus.length > 255) {
            newErrors.bonus = 'Bonus is required and must be a string with a maximum length of 255 characters';
        }
// Validate 'bonus_percentage' field
        const bonus_percentage = (jobData?.bonus_percentage);
        if (jobData?.bonus === 'Yes' && (jobData?.bonus_percentage && !Number(bonus_percentage) ||bonus_percentage < 0 || bonus_percentage > 100)) {
            newErrors.bonus_percentage = 'Bonus Percentage must be a valid number  between 0 and 100';
        }
// Validate 'description' field
        if (!jobData?.description || typeof jobData?.description !== 'string' || jobData?.description.length < 500) {
            newErrors.description = 'Description is required and must be a string with a minimum length of 500 characters';
        }
// Validate 'years_of_experience_id' field
        const years_of_experience_id = parseInt(jobData?.years_of_experience_id, 10);
        if (!jobData?.years_of_experience_id || !Number.isInteger(years_of_experience_id)) {
            newErrors.years_of_experience_id = 'Years of Experience is required';
        }
// Validate 'required_certification' field
        if (!jobData?.required_certification || typeof jobData?.required_certification !== 'string' || jobData?.required_certification.length > 255) {
            newErrors.required_certification = 'Required Certification is required and must be a string with a maximum length of 255 characters';
        }
// Validate 'certification_type' field
        if (jobData?.certification_type !== null && (typeof jobData?.certification_type !== 'string' || jobData?.certification_type.length > 255)) {
            newErrors.certification_type = 'Certification Type must be a string with a maximum length of 255 characters';
        }
// Validate 'can_post' field
        if (jobData?.can_post !== 1) {
            newErrors.can_post = 'The checkbox must be checked.';
        }
        return newErrors;
    }
    static ValidateResume(resumeData) {
        const newErrors = {};

        // Validate 'job_seeker_id' field
        const jobSeekerId = parseInt(resumeData.job_seeker_id, 10);
        if (!resumeData.job_seeker_id || !Number.isInteger(jobSeekerId) || jobSeekerId < 0 || jobSeekerId > 4294967295) {
            newErrors.job_seeker_id = 'Job Seeker ID is required and must be a valid integer between 0 and 4294967295';
        }

        // Validate 'us_based' field
        if (!resumeData.us_based || typeof resumeData.us_based !== 'string' || resumeData.us_based.length > 50) {
            newErrors.us_based = 'US Based is required and must be a string with a maximum length of 50 characters';
        }

        // Validate 'preferred_job_title' field
        if (!resumeData.preferred_job_title || typeof resumeData.preferred_job_title !== 'string' || resumeData.preferred_job_title.length > 255) {
            newErrors.preferred_job_title = 'Preferred Job Title is required and must be a string with a maximum length of 255 characters';
        }

        // Validate 'resume_code' field
        if (!resumeData.resume_code || typeof resumeData.resume_code !== 'string' || resumeData.resume_code.length > 255) {
            newErrors.resume_code = 'Resume Code is required and must be a string with a maximum length of 255 characters';
        }

        // Validate 'first_name' field
        if (resumeData.first_name && (typeof resumeData.first_name !== 'string' || resumeData.first_name.length > 50)) {
            newErrors.first_name = 'First Name must be a string with a maximum length of 50 characters';
        }

        // Validate 'last_name' field
        if (resumeData.last_name && (typeof resumeData.last_name !== 'string' || resumeData.last_name.length > 50)) {
            newErrors.last_name = 'Last Name must be a string with a maximum length of 50 characters';
        }

        // Validate 'address' field
        if (resumeData.address && (typeof resumeData.address !== 'string' || resumeData.address.length > 255)) {
            newErrors.address = 'Address must be a string with a maximum length of 255 characters';
        }

        // Validate 'location' field
        if (resumeData?.us_based === 'Yes') {
            if (!resumeData?.location || typeof resumeData?.location !== 'string' || resumeData?.location.length > 255) {
                newErrors.location = 'Please select Location option';
            }
        }

        // Validate 'country_id' field
        if (resumeData?.us_based === 'No') {
            const countryId = parseInt(resumeData?.country_id, 10);
            if (!resumeData?.country_id || !Number.isInteger(countryId)) {
                newErrors.country_id = 'Please select Country option.';

            }
        }

        // Validate 'city' field
        if (resumeData?.city && (typeof resumeData?.city !== 'string' || resumeData?.city.length > 255)) {
            newErrors.city = 'City must be a string with a maximum length of 255 characters';
        }

        // Validate 'state' field
        if (resumeData?.state && (typeof resumeData?.state !== 'string' || resumeData?.state.length > 255)) {
            newErrors.state = 'State must be a string with a maximum length of 255 characters';
        }

        // Validate 'mobile' field
        if (resumeData?.mobile && (typeof resumeData?.mobile !== 'string' || resumeData?.mobile.length > 255)) {
            newErrors.mobile = 'Mobile must be a string with a maximum length of 255 characters';
        }

        // Validate 'home' field
        if (resumeData?.home && (typeof resumeData?.home !== 'string' || resumeData?.home.length > 255)) {
            newErrors.home = 'Home must be a string with a maximum length of 255 characters';
        }

        // Validate 'office' field
        if (resumeData?.office && (typeof resumeData?.office !== 'string' || resumeData?.office.length > 255)) {
            newErrors.office = 'Office must be a string with a maximum length of 255 characters';
        }
// Validate 'resume_availability' field
        if (resumeData?.resume_availability && !['Yes', 'No'].includes(resumeData?.resume_availability)) {
            newErrors.resume_availability = 'Invalid value for Resume Availability';
        }

        // Validate 'resume_availability_file_path' field
        // (Assuming resumeData.resume_availability_file_path is a string)

        // Update the validation for 'resume_availability_file_path' field
        if (resumeData?.resume_availability === 'Yes') {
            // Validate when resume availability is Yes
            if (!resumeData?.resume_availability_file_path) {
                // newErrors.resume_availability_file_path = 'Resume Availability File Path is required when resume availability is Yes.';
            }
        }



// Use newErrors as needed in your code

        // (Assuming resumeData.resume_availability_video_file is a string)


        // Validate 'work_authorization_id' field
        const workAuthorizationId = parseInt(resumeData?.work_authorization_id, 10);
        if (!resumeData?.work_authorization_id || !Number.isInteger(workAuthorizationId)) {
            newErrors.work_authorization_id = 'Please select Work Authorization option';
        }

        // Validate 'relocation' field
        if (resumeData?.relocation && !['Yes', 'No'].includes(resumeData?.relocation)) {
            newErrors.relocation = 'Invalid value for Relocation';
        }

        // Validate 'relocation_option' field
        if (resumeData?.relocation_option && (typeof resumeData?.relocation_option !== 'string' || resumeData?.relocation_option.length > 50)) {
            newErrors.relocation_option = 'Relocation Option must be a string with a maximum length of 50 characters';
        }
        // Validate preferred location fields
        ['preferred_location1', 'preferred_location2', 'preferred_location3'].forEach((fieldName) => {
            if (resumeData?.[fieldName] && (typeof resumeData?.[fieldName] !== 'string' || resumeData?.[fieldName].length > 255)) {
                newErrors[fieldName] = `${fieldName.replace('_', ' ')} must be a string with a maximum length of 255 characters`;
            }
        });
        const locationFields = ['city1', 'state1', 'city2', 'state2', 'city3', 'state3'];

        // Validate each city and state field
        locationFields.forEach((fieldName) => {
            if (resumeData?.[fieldName] && (typeof resumeData?.[fieldName] !== 'string' || resumeData?.[fieldName].length > 255)) {
                newErrors[fieldName] = `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)} must be a string with a maximum length of 255 characters`;
            }
        });
        // Validate 'preferred_job_type' field
        if (!resumeData?.preferred_job_type || !['Full Time', 'Contract', '1099','Any'].includes(resumeData?.preferred_job_type)) {
            newErrors.preferred_job_type = 'Preferred Job Type is required and must be one of: Full Time, Contract, 1099';
        }

        // Validate 'contract_type' field
        if (resumeData?.contract_type && !['Independent', 'Corp to Corp'].includes(resumeData?.contract_type)) {
            newErrors.contract_type = 'Contract Type must be one of: Independent, Corp to Corp';
        }

        // Validate 'independent_c2c_company_name' field
        if (resumeData?.independent_c2c_company_name && (typeof resumeData?.independent_c2c_company_name !== 'string' || resumeData?.independent_c2c_company_name.length > 255)) {
            newErrors.independent_c2c_company_name = 'Independent C2C Company Name must be a string with a maximum length of 255 characters';
        }

        // Check if either 'salary_expectations' or 'expected_hourly_rate' is present
        if (!resumeData.salary_expectations && !resumeData.expected_hourly_rate) {
            newErrors.salary_expectations_or_expected_hourly_rate = 'Salary Expectations or Expected Hourly Rate is required';
            // newErrors.expected_hourly_rate = 'Salary Expectations or Expected Hourly Rate is required';
        } else {
            // Validate 'salary_expectations' field if present
            if (resumeData.salary_expectations) {
                const salary_expectations = resumeData.salary_expectations;
                if (!Number(salary_expectations) || (salary_expectations < 0 || salary_expectations > 1000000)) {
                    newErrors.salary_expectations = 'Salary Expectations must be a valid number between 1 and 1000000';
                }
            }

            // Validate 'expected_hourly_rate' field if present
            if (resumeData.expected_hourly_rate) {
                const expected_hourly_rate = resumeData.expected_hourly_rate;
                if (!Number(expected_hourly_rate) || (expected_hourly_rate < 0 || expected_hourly_rate > 1000000)) {
                    newErrors.expected_hourly_rate = 'Expected Hourly Rate must be a valid number between 1 and 1000000';
                }
            }
        }

//         // Validate 'salary_expectations' field
//         const salary_expectations = resumeData.salary_expectations;
//         if (!salary_expectations) {
//             newErrors.salary_expectations = 'Salary Expectations is required';
//         } else if (!Number(salary_expectations) || (salary_expectations < 0 || salary_expectations > 1000000)) {
//             newErrors.salary_expectations = 'Salary Expectations must be a valid number between 1 and 1000000';
//         }
//
// // Validate 'expected_hourly_rate' field
//         const expected_hourly_rate = resumeData.expected_hourly_rate;
//         if (!expected_hourly_rate) {
//             newErrors.expected_hourly_rate = 'Expected Hourly Rate is required';
//         } else if (!Number(expected_hourly_rate) || (expected_hourly_rate < 0 || expected_hourly_rate > 1000000)) {
//             newErrors.expected_hourly_rate = 'Expected Hourly Rate must be a valid number between 1 and 1000000';
//         }


        // Validate 'yearsof_experience_id' field
        const yearsof_experience_id = parseInt(resumeData?.yearsof_experience_id, 10);
        if (resumeData?.yearsof_experience_id && !Number.isInteger(yearsof_experience_id)) {
            newErrors.yearsof_experience_id = 'Years of Experience ID must be a valid integer';
        }

        // Validate 'can_post' field
        if (resumeData?.can_post !== 1) {
            newErrors.can_post = 'The checkbox must be checked.';
        }

        // Validate 'skills' field
        if (!resumeData?.skills || !Array.isArray(resumeData?.skills) || resumeData?.skills.length < 5 || resumeData?.skills.length > 15) {
            newErrors.skills = 'Please enter at least 5 and up to 15 skills.';

        } else {
            // Validate each skill in the 'skills' array
            resumeData?.skills.forEach((skill, index) => {
                if (!skill?.skill_set_id || !Number.isInteger(skill?.skill_set_id)) {
                    newErrors[`skills.${index}.skill_set_id`] = `Skill Set ID at index ${index} must be a valid integer`;
                }

                if (skill?.skill_set_level && typeof skill?.skill_set_level !== 'string') {
                    newErrors[`skills.${index}.skill_set_level`] = `Skill Set Level at index ${index} must be a string`;
                }
            });
        }
        console.log(newErrors)
        return newErrors
    }
}
export default Validator;