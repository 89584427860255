import React, { useState } from "react";
import logoImage from "../src/assets/logo4.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import "../src/assets/css/header.css";
import "../src/assets/css/styles.css";
import UserFetch from "./UserFetch";
import { useDispatch } from "react-redux";
import {addUser, setResumeStatus} from "./store/slices/UserSlice";
import { useEffect } from "react";
import BASE_URL from "./config";
import axios from "axios";
import styles from "./assets/css/LoadingComponent.module.css";
import {useMediaQuery} from "react-responsive";
import {MainHeader, MiniHeader, MobileHeader} from "./components/Header/Header";
import { seeker_variable } from "./components/Header/variables"
import {ErrorHandlingComponent} from "./ErrorHandlingComponent";
import {ToastContainer, toast} from 'react-toastify';

function JobSeekerHeader() {
  const [notifications, setNotifications] = useState([]);
  const [serverTime, setServerTime] = useState('')
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [count, setCount] = useState(0)
  const token = localStorage.getItem("access_token"); // Get the token from localStorage
  const isTablet = useMediaQuery({ query: '(max-width: 930px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const [fetchError, setFetchError] = useState(false);
  const [fetchErrorMessage, setFetchErrorMessage] = useState(false);
  const headers = {
    Authorization: `Bearer ${token}`, // Set the Authorization header with the token
  };
  const resumeStatus = useSelector((state) =>  state.user.resumeStatus);
  const uploadAResume = () => {
    // console.log(job_seeker_data);
    if (job_seeker_data) {
      if (job_seeker_data.resumes.length == 0 || !resumeStatus) navigate("/post-resume");
      else {
        var resumeId = job_seeker_data.resumes[0].id;
        var from='/job_seeker_dashboard';
        navigate("/edit_resume", { state: { resumeId,from } });
      }
    }
  };

  const job_seeker_data = useSelector((state) => {
    return state.user.user;
  });

  useEffect(() => {
    // Fetch notifications from the API
    axios
        .get(`${BASE_URL}/notifications`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if(response?.data?.notifications){
            setNotifications(response?.data?.notifications);
            setServerTime(response?.data?.server_time);
          }

        })
        .catch((error) => {
          setFetchError(true)
          setFetchErrorMessage(error)
        });
  }, [token]); // Include any dependencies that trigger the fetch

  useEffect(()=>{

  }, [count])
  useEffect(  () => {
    if (count === 0) {

     axios
        .get(`${BASE_URL}/get-user-data`, {
          headers,
        })
        .then((res) => {
          setCount(1);
          if (res?.data?.user?.resumes?.length > 0) {
              dispatch(setResumeStatus(true))
          } else {
            dispatch(setResumeStatus(false))
          }
        })
         .catch((err) => {
           setFetchError(true)
           setFetchErrorMessage(err)
         });
    }
  }, []);
  if (!job_seeker_data) {

    UserFetch()
      .then((response) => {
        const user_data = response.data.user;
        // console.log(user_data, "user data");
        dispatch(addUser(user_data));
      })
      .catch((error) => {
        console.error("Error fetching user:", error);
      });
  }


  const signInClick = () => {
    localStorage.clear(); // Clear local storage
    navigate("/jobseeker_login");
  };
  const viewProfileClick = () => {
    navigate("/my-profile-view");
  };
  const jobSeach = () => {
    navigate("/search_job");
  };
  const handleLogoClick = () => {
    navigate("/job_seeker_dashboard");
  };
  const handleProfileClick = () => {
    navigate("/create_profile");
  };
  const viewAllAppliedJobsHandler = () => {
    const defaultfrom = "/job_seeker_dashboard";
    navigate("/applied-jobs", {
      state: { defaultfrom } // Pass the favoriteResumes state
    });
  };

  const showJobSearchHandler = ()=>{
    const defaultfrom = "/job_seeker_dashboard";
    navigate("/search_job", {
      state: { defaultfrom } // Pass the favoriteResumes state
    });
  }

  const showFavoriteHandler = ()=>{
    const defaultfrom = "/job_seeker_dashboard";
    navigate("/fav-jobs", {
      state: { defaultfrom } // Pass the favoriteResumes state
    });
  }

  return (

    <div className="nav bg-white ">
      {count === 0 && (<div className={styles.loadingOverlay}>
        <div className={styles.loadingSpinner}></div>
      </div>)}
      <ToastContainer/>

      {/* {console.log(buttonCheck, resumeCheck, "buttonCheck")} */}
      <div className="container-fluid">
        <div className="navpad">
          <div className="d-flex justify-content-between align-items-center">
            <div className="logosearch ">
              <a href="javascript:void(0)" onClick={handleLogoClick}>
                {" "}
                <img src={logoImage} />
              </a>
            </div>
            {isTablet?

                (isMobile?(
                    <MobileHeader
                        user_data={job_seeker_data}

                        notifications={notifications}
                        serverTime={serverTime}
                        viewProfileClick={viewProfileClick}
                        signInClick={signInClick}
                        showJobSearchHandler={showJobSearchHandler}
                        handleProfileClick={handleProfileClick}
                        showFavoriteHandler={showFavoriteHandler}
                        viewAllAppliedJobsHandler={viewAllAppliedJobsHandler}
                        variables={seeker_variable}

                    />
                ):(
                    <MiniHeader
                        user_data={job_seeker_data}

                        notifications={notifications}
                        serverTime={serverTime}
                        viewProfileClick={viewProfileClick}
                        signInClick={signInClick}
                        showJobSearchHandler={showJobSearchHandler}
                        handleProfileClick={handleProfileClick}
                        showFavoriteHandler={showFavoriteHandler}
                        viewAllAppliedJobsHandler={viewAllAppliedJobsHandler}
                        uploadAResume={uploadAResume}
                        resumeStatus={resumeStatus}
                        jobSeach={jobSeach}
                        variables={seeker_variable}
                    />
                )
              )

                :(
                <MainHeader
                    user_data={job_seeker_data}
                    notifications={notifications}
                    serverTime={serverTime}
                    viewProfileClick={viewProfileClick}
                    signInClick={signInClick}
                    showJobSearchHandler={showJobSearchHandler}
                    handleProfileClick={handleProfileClick}
                    showFavoriteHandler={showFavoriteHandler}
                    viewAllAppliedJobsHandler={viewAllAppliedJobsHandler}
                    uploadAResume={uploadAResume}
                    resumeStatus={resumeStatus}
                    jobSeach={jobSeach}
                    variables={seeker_variable}
                />
            )}

          </div>
        </div>
      </div>
      {fetchError &&  <ErrorHandlingComponent error={fetchErrorMessage} />}

    </div>
  );
}
export default JobSeekerHeader;
