import React from "react";

export const JobAnalytics = ({
                                 employer,
                                 contactType
                             })=>{
    return (
        <div className=" analyticsv1">
            <h2> Jobs</h2>
            <div className="dashjobiconv1 ">
                <p>No of Jobs Purchased </p>
                <p> {employer?.employer_data?.subscription?.package?.jobs} </p>
            </div>
            <div className="dashjobiconv1 ">
                <p>No of Jobs Available </p>
                <p>  {(employer?.employer_data?.subscription?.remaining_jobs)} </p>
            </div>
            {contactType && contactType?.statusCount?.map((status)=>(
                <div className="dashjobiconv1 ">
                    <p>{status.status}</p>
                    <p> {status?.count} </p>
                </div>
            ))}
        </div>
    )
}