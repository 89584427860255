import React from 'react';
import PageHeader from "./PageHeader";
import '../src/assets/css/staticpages.css'; // Import the CSS file
import { BsArrowLeft, BsChatDots, BsChatDotsFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import colors from "./colors";

const Support = () => {
    const navigate = useNavigate();

    return (
        <>
            <PageHeader />
            <div className="sp-about-us-content">
                <div className="sp-background-style">
                    <div className="AJbackbtn">
                        <button onClick={() => navigate('/')}><BsArrowLeft /> Back</button>
                    </div>
                    <h1>
                        <BsChatDotsFill className="mb-1" style={{ color: colors.primaryColor }} /> Support
                    </h1>
                    <div className="content-container">
                        <div className="sp-text-content">
                            <p>
                                Welcome to Rezmaze Support, where we are dedicated to providing assistance and solutions to ensure a seamless experience with our platform.
                            </p>

                            <p>
                                <strong>How We Can Help:</strong>
                            </p>

                            <ul>
                                <li>
                                    <BsChatDots className="mb-1" style={{ color: colors.primaryColor }} /> <strong>Technical Support:</strong> Contact our technical support team for assistance with any platform-related issues or technical problems you may encounter.
                                </li>
                                <li>
                                    <BsChatDots  className="mb-1" style={{ color: colors.primaryColor }} /> <strong>Account Assistance:</strong> If you have questions or need help with your Rezmaze account, our support representatives are here to assist you.
                                </li>
                                <li>
                                    <BsChatDots className="mb-1" style={{ color: colors.primaryColor }} /> <strong>Feedback and Suggestions:</strong> We value your feedback and suggestions. Reach out to us to share your thoughts and help us improve our platform.
                                </li>
                            </ul>

                            <p>
                                <strong>Contacting Support:</strong>
                            </p>

                            <p>
                                You can get in touch with our support team through the following channels:
                            </p>

                            <ul>
                                <li>
                                    <strong>Live Chat:</strong> Use our live chat feature for real-time assistance during business hours.
                                </li>
                                <li>
                                    <strong>Email:</strong> Send us an email at support@rezmaze.com, and we'll get back to you as soon as possible.
                                </li>
                                <li>
                                    <strong>Phone:</strong> Call our support hotline at (555) 123-4567 for immediate assistance.
                                </li>
                            </ul>

                            <p>
                                Our dedicated support team is here to provide timely and effective solutions to any issues you may encounter. We are committed to your success and satisfaction with Rezmaze.
                            </p>

                            <p>
                                Explore our Support section to connect with our support team and get the help you need.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Support;
