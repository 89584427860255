import React from 'react';
import PdfTextHighlighter from './PdfTextHighlighter';
import pdfFile from './pdf/Muhammad Usman.pdf';
import HighlightedIframe from "./PdfTextHighlighter";

const SamplePdf = () => {
    const wordsToHighlight = ['Muhammad', 'Usman', 'PHP', 'Laravel', 'zoho'];

    return (
        <div className="App">
            <h1>PDF Text Highlighter</h1>
            <HighlightedIframe src={pdfFile} wordsToHighlight={wordsToHighlight} /> {/* Update the prop name to match the component */}
        </div>
    );
};

export default SamplePdf;
