import {MdFavorite} from "react-icons/md";
import React from "react";


export const AllFavJobs = ({
                               viewAllFavJobsHandler,
                               handleRemoveClick,
                               dropdownRef,
                               handleResumeClick,
                               handleCompanyImg,
                               favoriteResumes
                           })=>{

    return (
        <div className="analytics">
            <div className="resume JSleftheading">
                <h2> Favorite Jobs</h2>
                {/* <p><a href="#">View All</a></p> */}
                {/*{favoriteResumes && favoriteResumes.length > 3 && (*/}
                <p style={{cursor:"pointer"}} onClick={viewAllFavJobsHandler}>View All <i className="fas fa-chevron-right"></i></p>
                {/*)}*/}

            </div>
            {/*-------------- Favourite Resume 1 ------------*/}
            <div>
                {favoriteResumes && favoriteResumes.length > 0 ? (
                    favoriteResumes.map((job, index) => (
                        <div className="favourite" key={index}>
                            <div className="fvtresum">
                                <div className="searchresum-img">
                                    {handleCompanyImg(job.job_ad)}

                                </div>
                                <div className="rsfvtresumtxt">
                                    <h6
                                        onClick={() => handleResumeClick(job?.job_ad?.id)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {job?.job_ad?.job_title}
                                    </h6>
                                    <p>
                                        <a href="javascript:void(0)">{job?.job_ad?.location}</a>


                                    </p>
                                </div>
                            </div>
                            <div className="dropdown" ref={dropdownRef}>
                                <button
                                    className="dropbtn"
                                    onClick={() => handleRemoveClick(job?.job_ad?.id)}
                                >

                                    <MdFavorite
                                        role="button"
                                        size={24}
                                        style={{  color: '#e54545' }}
                                    ></MdFavorite>

                                </button>

                            </div>
                        </div>
                    ))
                ) : (
                    <p className="basic_text">No favorite jobs found.</p>
                )}

            </div>

        </div>
    )
}