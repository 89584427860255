import React from 'react';
import PageHeader from "./PageHeader";
import Footer from "./Footer";
import '../src/assets/css/staticpages.css'; // Import the CSS file
import { BsArrowLeft, BsBriefcaseFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import colors from "./colors";

const Careers = () => {
    const navigate = useNavigate();

    // Sample data for current job openings
    const jobOpenings = [
        {
            title: "Front-end Developer",
            location: "Remote",
            department: "Engineering",
        },
        {
            title: "Marketing Specialist",
            location: "New York, NY",
            department: "Marketing",
        },
        {
            title: "Customer Support Representative",
            location: "Los Angeles, CA",
            department: "Customer Support",
        },
    ];

    return (
        <>
            <PageHeader />
            <div className="sp-about-us-content">
                <div className="sp-background-style">
                    <div className="AJbackbtn">
                        <button onClick={() => navigate('/')}><BsArrowLeft /> Back</button>
                    </div>
                    <h1>
                        <BsBriefcaseFill className="mb-1" style={{ color: colors.primaryColor }} /> Careers at Rezmaze
                    </h1>
                    <div className="content-container">
                        <div className="sp-text-content">
                            <p>
                                Join our team and embark on a rewarding career journey with Rezmaze! We are a leading job portal, much like LinkedIn, dedicated to connecting job seekers with valuable opportunities while offering a unique social media experience.
                            </p>

                            <p>
                                <strong>Why Choose a Career with Rezmaze:</strong>
                            </p>

                            <ul>
                                <li>
                                    <BsBriefcaseFill className="mb-1" style={{ color: colors.primaryColor }} />
                                    <strong> Growth Opportunities:</strong>
                                    <br />
                                    At Rezmaze, we believe in the growth of our team members. We offer a supportive and dynamic work environment where you can expand your skills and advance your career. Whether you're just starting your career or looking to climb the corporate ladder, we have opportunities for you.
                                </li>
                                <li>
                                    <BsBriefcaseFill className="mb-1" style={{ color: colors.primaryColor }} />
                                    <strong> Innovation:</strong>
                                    <br />
                                    Join a company that values innovation and creativity. We're at the forefront of technology and industry trends, and we encourage fresh ideas and perspectives. Your innovative thinking can shape the future of our platform and make a real impact.
                                </li>
                                <li>
                                    <BsBriefcaseFill className="mb-1" style={{ color: colors.primaryColor }} />
                                    <strong> Impact:</strong>
                                    <br />
                                    Be a part of a mission-driven organization that empowers individuals to achieve their career aspirations. Your work at Rezmaze will make a positive impact on people's lives. Every day, you'll contribute to the success of job seekers and professionals worldwide.
                                </li>
                                <li>
                                    <BsBriefcaseFill  className="mb-1" style={{ color: colors.primaryColor }} />
                                    <strong> Career Development:</strong>
                                    <br />
                                    We are committed to your professional growth. Rezmaze provides learning and development opportunities to help you excel in your role and reach your career goals. We offer mentorship programs, training resources, and a clear path for advancement within the company.
                                </li>
                            </ul>

                            <p>
                                <strong>Current Openings:</strong>
                            </p>

                            <ul>
                                {jobOpenings.map((job, index) => (
                                    <li key={index}>
                                        <BsBriefcaseFill className="mb-1" style={{ color: colors.primaryColor, }} />
                                        <strong>{' '+ job.title}</strong> - {job.location} - {job.department}
                                    </li>
                                ))}
                            </ul>

                            <p>
                                <strong>Our Work Environment:</strong>
                            </p>

                            <p>
                                At Rezmaze, we believe in fostering a collaborative and inclusive work environment. We value diversity and respect the unique perspectives and contributions of every team member. Our culture is built on teamwork, respect, and a commitment to excellence. We offer flexible work options and a supportive work-life balance.
                            </p>

                            <p>
                                <strong>Employee Benefits:</strong>
                            </p>

                            <p>
                                Joining our team comes with a range of benefits, including competitive compensation, health and wellness programs, and opportunities for professional development. We understand that happy, healthy, and motivated employees are essential to our success.
                            </p>

                            <p>
                                <strong>Join Our Team:</strong>
                            </p>

                            <p>
                                Your journey to a fulfilling career starts here. Join us in shaping the future of professional development and job opportunities. We are excited to welcome individuals who are passionate about making a difference in the world of careers and professional growth.
                            </p>

                            <p>
                                <strong>Connect with Us:</strong>
                            </p>

                            <p>
                                Join the Rezmaze community to stay updated on the latest job opportunities and career advice. Follow us on our social media profiles to connect with professionals and job seekers like you.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Careers;
