import React, {useState} from "react";

import './assets/css/header.css';
import '../src/assets/css/login.css';
import logoImage from '../src/assets/logo2.png';
import emailImage from '../src/assets/emailplaceholder.svg';
import {useNavigate} from "react-router-dom";
import styles from "./assets/css/LoadingComponent.module.css"
import axios from "axios";
import {BASE_URL} from "./config";
import {ErrorHandlingComponent} from "./ErrorHandlingComponent";
import {BsArrowLeft} from "react-icons/bs";
import {toast, ToastContainer} from "react-toastify";

function ForgotPassword () {


    const [email, setEmail] = useState('')
    const [errorMessage, setErrorMessage]= useState('')
    const [status, setStatus]= useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [fetchError, setFetchError] = useState(false);
    const [fetchErrorMessage, setFetchErrorMessage] = useState(false);

    const token = localStorage.getItem('access_token'); // Get the token from localStorage
    const headers = {
        Authorization: `Bearer ${token}`, // Set the Authorization header with the token
    };
    const handleLogoClick = () => {
        navigate("/employer-login");

    }

    const signInClick = () => {
        navigate(-1);
    };

    const handleSubmit = ()=>{
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        console.log(email, 'em')
        if (!email) {
            setErrorMessage('Email is required');
            return false

        }
        if (!emailPattern.test(email)) {
            setErrorMessage('Please enter valid email');
            return false;
        }
        setLoading(true)
        axios
            .post(`${BASE_URL}/forgot-password`, {email},{
                headers,
            })
            .then((res) => {
                if(res.data.status === 200){
                    if(res.data.error){
                        setErrorMessage(res.data.message)
                        setStatus(false)
                        toast.success('Password reset email has been sent successfully!', {
                            position: toast.POSITION.TOP_CENTER
                        });
                        setEmail('');

                    }else{
                        setErrorMessage(res.data.message)
                        setStatus(true)
                    }

                }else if(res.data.status === 404){
                    setErrorMessage(res.data.message)
                    setStatus(false)
                }
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                setFetchError(true)
                setFetchErrorMessage(err)
            });

    }

    const handleChange = (e)=>{
        setEmail(e.target.value)
        setErrorMessage('')
    }

    return (
            <div>

                {loading && (
                    <div className={styles.loadingOverlay}>
                        <div className={styles.loadingSpinner}></div>
                    </div>
                )
                }
                <ToastContainer/>
                {/*---------------------------------------------------------------*/}
                {/*                         NAVBAR Start                          */}
                {/*---------------------------------------------------------------*/}
                <div className="nav bg-white ">
                    <div className="container-fluid">
                        <div className="row navpad">
                            <div className="col-12 d-flex">
                                <div className="col-lg-4 logosearch ">
                                    <a href="javascript:void(0)"> <img src={logoImage} /></a>
                                </div>
                                <div className="col-8  d-flex justify-content-end align-items-center">
                                    <div className="postbtn">
                                        <button type="submit"><a href="javascript:void(0)" onClick={signInClick}> LOGIN </a></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*---------------------------------------------------------------*/}
                {/*                         NAVBAR END                            */}
                {/*---------------------------------------------------------------*/}
                {/*---------------------------------------------------------------*/}
                {/*                        MAin Body Start                        */}
                {/*---------------------------------------------------------------*/}
                <div className="mainbg">
                    <div className="form">
                        <div className=" backbtn mb-2">
                            <button onClick={() => {
                                navigate(-1)
                            }}>
                                <BsArrowLeft/> Back
                            </button>
                        </div>
                        <div className="formdata">
                            <h2>Forgot Password?</h2>
                            <p>Enter your email to reset your password</p>
                            <div className="emailinfo">
                                <img src={emailImage} alt="" />
                                <input
                                    type="text"
                                    placeholder="Email"
                                    onChange={handleChange}
                                />
                            </div>
                            <p></p>
                            <b><h5 className={status?"text-success":"text-danger"}>{errorMessage}</h5></b>
                            {/*--------------------------------Sign Up BTN ------------------*/}
                            {!status &&(
                            <div className="formbtn">
                                <button onClick={handleSubmit}><a href="javascript:void(0)" >Send</a></button>
                            </div>
                            )}
                        </div>
                    </div>
                </div>
                {fetchError &&  <ErrorHandlingComponent error={fetchErrorMessage} />}

            </div>
        );
    }
export default ForgotPassword;
