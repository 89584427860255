import React, { useState } from "react";
import Picker from "emoji-picker-react";

export const Emoji = ({setShowPicker, setUserInput}) => {

    const onEmojiClick = (event, emojiObject) => {

        setUserInput((prevInput) => prevInput + event.emoji);
        setShowPicker(false);
    };

    return (
        <div style={{position: 'absolute', zIndex:'1'}}>
            <Picker pickerStyle={{ width: "100%"}} onEmojiClick={onEmojiClick} />
        </div>

    );
}


