import React, {useState, useRef, useEffect} from 'react';
import {Link} from 'react-router-dom';
import '../src/assets/css/LandingPage.css';
import logoImage from "./assets/logo4.svg";
// import Landpage_img from "./assets/landingimages.jpeg";
import search_icon from "./assets/land_page_search.png";
import aboutus from "./assets/whyresume.gif";
import aboutus2 from "./assets/about_us2.jpg";
import pin_icon from "./assets/land_page_pin.png";
import facebookfooter from "./assets/facebookfooter.svg";
import twitterfooter from "./assets/twitterfooter.svg";
import linkedinfooter from "./assets/linkedinfooter.svg";
import footerlogo from "./assets/footerlogo.png";
import messagefooter from "./assets/messagefooter.png";
import {useNavigate} from 'react-router-dom';
import {useMediaQuery} from "react-responsive";
import {ScreenLink, TabScreen} from "./components/LandingPageHeader/LandingPageLink";
import axios from "axios";
import {BASE_URL} from "./config";
import playStore from "./assets/googlePlay.png"
import appStore from "./assets/appStore.png"
import {FaFacebookSquare } from "react-icons/fa";
import { AiFillLinkedin} from "react-icons/ai";
import {FaSquareTwitter} from "react-icons/fa6";
import {FaLinkedinIn, FaFacebookF, FaTwitter} from "react-icons/fa6";
import {AiFillTwitterCircle} from "react-icons/ai";
import {BiLogoFacebookCircle} from "react-icons/bi";
import {TiSocialLinkedinCircular} from "react-icons/ti";
import {GrContact} from "react-icons/gr";
import {TbMessage} from "react-icons/tb";

import Select from "react-select";
import {Col, Container, Image, Row} from "react-bootstrap";
import Footer from "./Footer";
import JobSeekerHeader from "./JobSeekerHeader";
import EmployerHeader from "./EmployerHeader";
import {Faq} from "./components/FAQ/Faq";
import {Card} from 'react-bootstrap';
// import createResumeGif from "./assets/create_resume.gif"
import createResumeGif from "./assets/create_resume.gif"

import hiredJobGif from "./assets/apply_job.gif"
import connectSocialGif from "./assets/content_social.gif"
import mobilesImg from "./assets/complete-banner.png"
import {ErrorHandlingComponent} from "./ErrorHandlingComponent";
import PasswordModal from "./PasswordModal";
import colors from "./colors";
import LoginRegisterModal from "./LoginRegisterModal";


function LandingPage() {
    const navigate = useNavigate();
    const [showPasswordModal, setShowPasswordModal] = useState(false); // State to control modal visibility
    const [showLoginModal, setShowLoginModal] = useState(false);

    const userableType = localStorage.getItem('userable_type');
    useEffect(() => {
        if (userableType === 'App\\Models\\JobSeeker') {
            navigate("/job_seeker_dashboard");
        } else if (userableType === 'App\\Models\\Employer') {
            navigate('/employer_dashboard');
        } else {
            setShowPasswordModal(true);
        }
    }, []); // Empty dependency array ensures useEffect runs only once

    const handleClosePasswordModal = (success) => {
        // Close modal and update state based on password success
        setShowPasswordModal(false); // Close the modal
        if (success) {
            // Proceed with landing page content
            // You can put your landing page content here or render it conditionally based on modal success
        }
    };

    //nsm
    const handleShowLoginModal = () => {
        setShowLoginModal(true);
    };
    //nsm
    const handleCloseLoginModal = () => {
        setShowLoginModal(false);
    };
    
    

    const [searchJobList, setSearchJobList] = useState([])
    const [locationList, setLocationList] = useState([])
    const [jobTitle, setJobTitle] = useState('')
    const [location, setLocation] = useState('')

    const aboutRef = useRef();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' })
    const [fetchError, setFetchError] = useState(false);
    const [fetchErrorMessage, setFetchErrorMessage] = useState(false);

    const handleEmployee = () => {
        navigate('/employer-login');
    }
    const handleJoSeeker = () => {
        navigate('/jobseeker_login');
    }
    const videoResume = () => {
        navigate('/video-resume-guide');
    }
    const socialMediaCapabilties = () => {
        navigate('/social-media-capabilities');
    }
    const handlesearchTitle = (e)=>{
        let job_title = e.target.value;
        setJobTitle(e.target.value)
        if(job_title.length > 0){
            axios
                .post(`${BASE_URL}/search_preferred_job_title`, {job_title},
                    {headers: { 'Content-Type': 'application/json'}
                    })
                .then((res)=>{
                    if(res?.data?.status === 200){

                        setSearchJobList(res?.data?.search_jobs?.original)
                    }else if(res?.data?.status === 404){
                        setSearchJobList([])
                    }
                })
                .catch(err=>{
                    setFetchError(true)
                    setFetchErrorMessage(err)
                })
        }else{
            setSearchJobList([])
        }
    }

    function handleInputChange(e) {
        setLocation(e.target.value)
        if (e.target.value.length > 2) {
            fetchZipCodes(e.target.value);
        }else{
            setLocationList([])
        }
    }
    const fetchZipCodes = (query) => {
        const isZipCode = /^\d+$/.test(query); // Check if the query is a number

        fetch(`${BASE_URL}/zipcode_filter_outer/${query}`, {
            headers: { 'Content-Type': 'application/json'},
        })
            .then((response) => response.json())
            .then((data) => {

                if(data.status === 200){
                    const zipcodes = data.zipcodes.original;
                    console.log(zipcodes, 'zip')
                    setLocationList(data.zipcodes.original)
                }

            })
            .catch((error) => {
                setFetchError(true)
                setFetchErrorMessage(error)
            });
    };

    const handleAboutScroll = () => {
        aboutRef.current?.scrollIntoView({behavior: 'smooth'});
    };
    const handleSearch = () => {//nsm
        // Check if user is logged in (assuming token is saved in localStorage)
    const token = localStorage.getItem('jobSeekerId');//access_token');

    if (!token) {
        // Show Login Popup if not logged in
        handleShowLoginModal();
    } else {

        // Navigate to the other view and pass jobTitle and location as route parameters
        navigate('/Outer_job_search', { state: { jobTitle, location } });
    }
    }

    const navigateToSubscription=()=> {
        navigate('/Request_For_Subscription');
    }

    return (

        <div className='page-wrapper'>
            {/*<PasswordModal isOpen={showPasswordModal} onClose={handleClosePasswordModal} />*/}

            {/* Conditionally render JobSeekerHeader or EmployerHeader */}
            {userableType === 'App\\Models\\JobSeeker' ? (
                <JobSeekerHeader />
            ) : userableType === 'App\\Models\\Employer' ? (
                <EmployerHeader />
            ) : (
                // Add default header or any other logic based on your requirements

                <div className="nav-landing-page">
                    <div className="container-fluid">
                        <div className="navpad d-flex justify-content-between align-items-center header_custom">

                            <div className="logosearch">
                                <a href="javascript:void(0)">
                                    <img src={logoImage} alt="Logo"/>
                                </a>
                            </div>
                            {!isTabletOrMobile?
                                (<ScreenLink handleAboutScroll={handleAboutScroll} handleEmployee={handleEmployee} handleJoSeeker={handleJoSeeker} videoResume={videoResume} socialMediaCapabilities={socialMediaCapabilties}/>)
                                :(<TabScreen handleAboutScroll={handleAboutScroll} handleEmployee={handleEmployee} handleJoSeeker={handleJoSeeker} videoResume={videoResume} socialMediaCapabilities={socialMediaCapabilties} />)
                            }
                        </div>
                    </div>
                </div>

            )}


            <div className="landing-page">
                <div className="left_content mt-2">
                    <h1>Discover the ideal career you truly deserve.</h1>
                    <div className="search_content">

                        <div className="search_title">
                            <img src={search_icon} alt="search_icon"/>
                            <input placeholder="Keyword" name='jobtitle' list='jobtitle' value={jobTitle} onChange={handlesearchTitle}/>
                            {searchJobList.length > 0 &&(
                                <datalist id="jobtitle">
                                    {searchJobList.map((item, key) => (
                                        <option key={key} value={item.name ? item.name : item.job_title} />
                                    ))}
                                </datalist>
                            )}
                        </div>


                        <div className="select_content">
                            <img src={pin_icon} alt="pin icon"/>
                            <input name='location' value={location} onChange={handleInputChange} list='locationList' placeholder="Location (zip, city, state)"/>
                            {locationList.length > 0 &&(
                                <datalist id="locationList">
                                    {locationList.map((zipcode, key) => (
                                        <option key={key} value={`${zipcode.city}, ${zipcode.state}, ${zipcode.zipcode}`} />
                                    ))}
                                </datalist>
                            )}
                        </div>
                        {/*<button className="btn_search">Search</button>*/}


                    </div>

                    <p className='mb-0'>Search keywords e.g. <span>Java Developer</span></p>
                    <button className="btn_search" onClick={handleSearch}>Search</button>
                </div>
                <div style={{
                    marginLeft: '60%',
                    marginTop: '-15rem',
                    width: '50%',
                    backgroundColor: '#dfdfdf',
                    borderRadius: '10px',
                    opacity:0.8,
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    padding: '1rem'
                }}>
                    <section className="text-center">
                        <div className="alert alert-success" role="alert" style={{
                            backgroundColor: '#dfdfdf',
                            border: 'none'
                        }}>
                            <h4 className="alert-heading" style={{ color: colors.secondaryColor }}>Early Bird Offer!</h4>
                            <p>Basic Package free for six (6) months</p>
                            <hr />
                            <p className="mb-0">6000 Views</p>
                            <p className="mb-0">50 Job Slots</p>
                        </div>
                        <div className="btn_container">
                            <button className="btn_1" onClick={navigateToSubscription}>Explore More</button>
                        </div>
                    </section>
                </div>

                {/*<section className="my-5 text-right">*/}
                {/*    <div className="alert alert-success" role="alert">*/}
                {/*        <h4 className="alert-heading">Limited Time Offer!</h4>*/}
                {/*        <p>Sign up for our basic package today and get 20% off your first year! Offer valid till [Date].</p>*/}
                {/*        <hr />*/}
                {/*        <p className="mb-0">Hurry, offer ends in 3 days!</p>*/}
                {/*    </div>*/}
                {/*    <button className="btn btn-primary btn-lg mt-3" onClick={navigateToSubscription}>Explore More</button>*/}
                {/*</section>*/}
                <div className="btn_container">
                    <button className="btn_1" onClick={handleEmployee}>Employer</button>
                    <button className="btn_2" onClick={handleJoSeeker}>Job Seeker</button>
                </div>

            </div>


            <div className="container">
                {/* Clear and Compelling Value Proposition */}
                {/*<section className="my-5 text-center">*/}
                {/*    <div className="container">*/}
                {/*        <h1 className="display-4 mb-4">Basic Package</h1>*/}
                {/*        <p className="lead mb-5">Ideal for individuals or small businesses seeking to establish a strong online presence.</p>*/}
                {/*        <div className="row justify-content-center">*/}
                {/*            <div className="col-md-8">*/}
                {/*                <ul className="list-group">*/}
                {/*                    <li className="list-group-item"><strong>Annual Views:</strong> 6000</li>*/}
                {/*                    <li className="list-group-item"><strong>Annual Jobs:</strong> 50</li>*/}
                {/*                    <li className="list-group-item"><strong>Users:</strong> 1</li>*/}
                {/*                    <li className="list-group-item"><strong>Price:</strong> Free for 6 months</li>*/}
                {/*                </ul>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}


                {/*<section className="my-5 text-center bg-light py-5">*/}
                {/*    <div className="container">*/}
                {/*        <h1 className="display-4 mb-4 text-primary">Unlock Your Online Potential</h1>*/}
                {/*        <p className="lead mb-5">Embark on your digital journey with our Basic Package, tailored for individuals and small businesses.</p>*/}
                {/*        <div className="row justify-content-center">*/}
                {/*            <div className="col-md-8">*/}
                {/*                <ul className="list-group">*/}
                {/*                    <li className="list-group-item"><strong>Annual Views:</strong> 6000</li>*/}
                {/*                    <li className="list-group-item"><strong>Annual Jobs:</strong> 50</li>*/}
                {/*                    <li className="list-group-item"><strong>Users:</strong> 1</li>*/}
                {/*                    <li className="list-group-item"><strong>Price:</strong> Free for 6 months</li>*/}
                {/*                </ul>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}


                {/*<section className="my-5 text-center">*/}
                {/*    <div className="container">*/}
                {/*        <h1 className="display-4 mb-4 text-dark">Elevate Your Online Presence</h1>*/}
                {/*        <p className="lead mb-5">Take the first step towards success with our Basic Package, meticulously crafted for individuals and small businesses.</p>*/}
                {/*        <div className="row justify-content-center">*/}
                {/*            <div className="col-md-8">*/}
                {/*                <ul className="list-group">*/}
                {/*                    <li className="list-group-item"><strong>Annual Views:</strong> 6000</li>*/}
                {/*                    <li className="list-group-item"><strong>Annual Jobs:</strong> 50</li>*/}
                {/*                    <li className="list-group-item"><strong>Users:</strong> 1</li>*/}
                {/*                    <li className="list-group-item"><strong>Price:</strong> Free for 6 months</li>*/}
                {/*                </ul>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}


                {/*<section className="my-5 text-center bg-dark text-white py-5">*/}
                {/*    <div className="container">*/}
                {/*        <h1 className="display-4 mb-4">Unleash Your Online Potential</h1>*/}
                {/*        <p className="lead mb-5 text-color">Seize the opportunity with our Basic Package, designed to empower individuals and small businesses on their digital journey.</p>*/}
                {/*        <div className="row justify-content-center">*/}
                {/*            <div className="col-md-8">*/}
                {/*                <ul className="list-group">*/}
                {/*                    <li className="list-group-item"><strong>Annual Views:</strong> 6000</li>*/}
                {/*                    <li className="list-group-item"><strong>Annual Jobs:</strong> 50</li>*/}
                {/*                    <li className="list-group-item"><strong>Users:</strong> 1</li>*/}
                {/*                    <li className="list-group-item"><strong>Price:</strong> Free for 6 months</li>*/}
                {/*                </ul>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}

                {/*<section className="my-5 text-center">*/}
                {/*    <div className="alert alert-success" role="alert">*/}
                {/*        <h4 className="alert-heading">Limited Time Offer!</h4>*/}
                {/*        <p>Sign up for our basic package today and get 20% off your first year! Offer valid till [Date].</p>*/}
                {/*        <hr />*/}
                {/*        <p className="mb-0">Hurry, offer ends in 3 days!</p>*/}
                {/*    </div>*/}
                {/*            <button className="btn btn-primary btn-lg mt-3" onClick={navigateToSubscription}>Explore More</button>*/}
                {/*</section>*/}

                {/*<section className="my-5 position-relative">*/}
                {/*    <div className="container text-center text-md-start">*/}
                {/*        <h1 className="display-4 mb-4">Basic Package</h1>*/}
                {/*        <p className="lead mb-5">Ideal for individuals or small businesses seeking to establish a strong online presence.</p>*/}
                {/*        <div className="row justify-content-center">*/}
                {/*            <div className="col-md-8">*/}
                {/*                <ul className="list-group">*/}
                {/*                    <li className="list-group-item"><strong>Annual Views:</strong> 6000</li>*/}
                {/*                    <li className="list-group-item"><strong>Annual Jobs:</strong> 50</li>*/}
                {/*                    <li className="list-group-item"><strong>Users:</strong> 1</li>*/}
                {/*                    <li className="list-group-item"><strong>Price:</strong> Free for 6 months</li>*/}
                {/*                </ul>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <button className="btn btn-primary btn-lg mt-3" onClick={navigateToSubscription}>Explore More</button>*/}
                {/*    </div>*/}
                {/*    <div className="overlay-text">*/}
                {/*        <h2 className="text-white text-center">Limited Time Offer!</h2>*/}
                {/*        <p className="text-white text-center">Sign up today and get 20% off your first year! Offer ends soon.</p>*/}
                {/*    </div>*/}
                {/*</section>*/}



            </div>

            {/*------ info section -------*/}
            <div className='info-section'>
                <div className="info-section-content">
                {/*<Card style={{backgroundColor:"transparent",border:'none'}} >*/}
                    <Card.Body className="lp-card-body">
                        <div className="lp-card-container">
                            <Card.Body className="lp-card-inner">
                                <Card.Title className='text-center'><strong>Create an Account/Upload Resume</strong></Card.Title>
                            </Card.Body>
                            <Card.Body>
                                <Card.Img className="card-image" variant="top" src={createResumeGif} />
                            </Card.Body>
                        </div>
                        <div className="lp-card-container">
                            <Card.Body className="lp-card-inner">
                                <Card.Title className='text-center'><strong>Connect with Employers/Jobseekers using our Social Media platform</strong></Card.Title>
                            </Card.Body>
                            <Card.Body>
                                <Card.Img  className="card-image"  variant="top" src={connectSocialGif} />
                            </Card.Body>
                        </div>
                        <div className="lp-card-container">
                            <Card.Body className="lp-card-inner">
                                <Card.Title className='text-center'><strong>Apply for Jobs and get hired!!</strong></Card.Title>
                            </Card.Body>
                            <Card.Body>
                                <Card.Img className="card-image"  variant="top" src={hiredJobGif} />
                            </Card.Body>
                        </div>
                    </Card.Body>



                    {/*</Card>*/}
                </div>
            </div>

 {/* ------------------------- About US section -------------------------------- */}
            <div className="aboutussec-lp" ref={aboutRef}>
             {/*<div className="aboutlft">*/}
             <img src={aboutus} alt="aboutus"/>
                 <div className="aboutrt">
                     <h3>Why Rezmaze?</h3>
                     <p>Rezmaze is a leading online platform that connects job seekers and employers throughout the United States and Globally. With its user-friendly interface and robust features, the rezmaze offers a comprehensive range of job opportunities across various industries and career levels. </p>

                     <p>Job seekers can create personalized profiles, upload resumes, and search for positions based on their preferences, including location, industry, and salary range.</p>
                 <p>Rezmaze strives to streamline the job search process by providing advanced search filters, enabling candidates to narrow down their options and find relevant opportunities quickly. Users can also set up job alerts to receive notifications about new postings that match their criteria, ensuring they stay updated with the latest openings in their desired fields.</p>
                 <p>Rezmaze also offers a unique feature of social media. Jobseekers and Employers can have direct personal interaction.</p>
                     <p>Rezmaze’s core concentration is on simple and aggressive platform. We don’t believe in posting resumes as a time-consuming job and at the time we don’t want employer to spend their valuable time while posting jobs. Both of these processes should be simple, quick, and effective. </p>
                 </div>
{/*</div>*/}


</div>

            {/* ------------------  app image --------------- */}
            <div className="banner_image">
            <div className='mobile_img info-section '>

                <img className='mobile_img_logo' src={mobilesImg}  alt='Mobile Apps looks' />
                <br />
                <div className='app_content1 pt-5'>
                    <a href='https://play.google.com/store/games?hl=en&gl=US&pli=1' target='_blank'>
                    <img className='play_img' src={playStore} alt='playstore'/>
                    </a>
                    <a href='https://www.apple.com/app-store/' target='_blank'>
                    <img className='app_img' src={appStore} alt='appstore'/>
                    </a>
                </div>
            </div>
            </div>

{/*------------------------------- Registration Buttons -------------------------- */}
<div className="regmain-lp">
<div className="reg-lp">
        <button className="btn_1" onClick={handleEmployee}>Employer</button>
        <button className="btn_2" onClick={handleJoSeeker}>Job Seeker</button>
</div>
</div>


            {/*------------------------------- FAQ --------------------------------------------*/}
            {/*<div className='accordion-content'>*/}

            {/*    <h3 className='text-center mt-5'>Frequently Asked Questions (FAQs)</h3>*/}
            {/*    <Faq />*/}

            {/*</div>*/}


            <div className="main-faq">
                <div className='faq-heading'>
                        <h2 >Frequently Asked Questions</h2>
                </div>
                        {/*<Faq />*/}
                <div className='faq-content'>
                 <Faq/>
                </div>
                {fetchError &&  <ErrorHandlingComponent error={fetchErrorMessage} />}

            </div>
{/*-------------------------------- Footer Page ------------------------------  */}


<Footer/>
<LoginRegisterModal show={showLoginModal} onHide={handleCloseLoginModal} />

        </div>
    );
}

export default LandingPage;
