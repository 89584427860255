import {Modal} from "react-bootstrap";
import styles from "../../assets/css/adminpanel.module.css";
import React, {useState} from "react";
import generalInfoImage from "../../assets/generalinfoicone.svg";
import rectangelImage from "../../assets/rectangel.png";
import EditResumeImage from '../../assets/editresume.svg';
import {TbEdit} from "react-icons/tb";
import colors from "../../colors";
import {LuPackagePlus} from "react-icons/lu";
import {GrCircleInformation} from "react-icons/gr";
import {HiOutlineInformationCircle} from "react-icons/hi";

export const AddSubscription =({
                                   showSubModal,
                                   closeSubscriptionModal,
                                   formData,
                                   setFormData,
                                   errors,
                                   setErrors,
                                   handleAddSubscription
})=>{

  const handleInputChange=(e)=>{
      const {name, value} = e.target;
      setFormData(prev =>({
          ...prev,
          [name]: value
      }))
      setErrors({
          start_date:'',
          end_date:'',
          package_name:'',
          package_description:'',
          package_price:'',
          package_feature:'',
      })
  }
  const handlePackageInputChange = (e)=>{

      const {name, value} = e.target;

      setFormData(prev=>({
          ...prev,
          package:{
              ...prev.package,
              [name]:value
          }
      }))

      setErrors({
          start_date:'',
          end_date:'',
          package_name:'',
          package_description:'',
          package_price:'',
          package_feature:'',
      })

  }
    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const day = today.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const [isEditing, setIsEditing] = useState(false);

    const handleEdit = () => {
        setIsEditing(!isEditing);

    };

    return (
        <Modal show={showSubModal}
               onHide={closeSubscriptionModal}
               size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>
                    Add Subscription
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="d-flex align-items-end justify-content-between mt-1 mb-2">
                    <div className="d-flex align-items-end">
                        {/*<img src={generalInfoImage} />*/}
                        <HiOutlineInformationCircle size={24} style={{color:colors.secondaryColor, marginRight:'10px'}}/>

                        <h5 className="mb-1 ml-0" style={{ fontSize: '1.3rem' }}>General Information</h5>
                    </div>
                    <TbEdit
                        size={24}
                        style={{ color: isEditing ? colors.primaryColor : colors.secondaryColor }}
                        onClick={handleEdit}
                    />
                    {/*<img*/}
                    {/*    src={EditResumeImage}*/}
                    {/*    alt="Edit"*/}
                    {/*    onClick={handleEdit}*/}
                    {/*    className={`text-${isEditing ? 'success' : 'danger'}`}*/}
                    {/*/>*/}
                </div>





                <div className='p-2'>

                    <div className="mb-3">
                        <div className='d-flex gap-2'>
                            <div className={styles.modal_input_content}>
                                <label htmlFor="full_name" className="form-label">Full name *</label>
                                <input type="text" onChange={handleInputChange} className="form-control" id="full_name" name='full_name'
                                       placeholder="Please write full name"  value={formData?.full_name}    disabled={!isEditing}/>
                                <small className='text-danger'>{errors?.full_name}</small>
                            </div>
                            <div className={styles.modal_input_content}>
                                <label htmlFor="company_name" className="form-label">Company Name *</label>
                                <input type="text" onChange={handleInputChange} className="form-control" id="company_name" name='company_name'
                                       placeholder="Please write Company Name" value={formData?.company_name} disabled={!isEditing}/>
                                <small className='text-danger'>{errors?.company_name}</small>
                            </div>
                        </div>

                    </div>

                    <div className="mb-3">
                        <div className='d-flex gap-2'>
                            <div className={styles.modal_input_content}>
                                <label htmlFor="email" className="form-label">Email *</label>
                                <input type="text" onChange={handleInputChange} className="form-control" id="email" name='email'
                                       placeholder="Please write email" value={formData?.email} disabled={!isEditing}/>
                                <small className='text-danger'>{errors?.email}</small>
                            </div>
                            <div className={styles.modal_input_content}>
                                <label htmlFor="phone_number" className="form-label">Phone Number *</label>
                                <input type="text" onChange={handleInputChange} className="form-control" id="phone_number" name='phone_number'
                                       placeholder="Please write phone number" value={formData?.phone_number} disabled={!isEditing}/>
                                <small className='text-danger'>{errors?.phone_number}</small>
                            </div>

                        </div>

                    </div>

                    <div className="mb-3">
                        <label htmlFor="company_address" className="form-label">Company Address *</label>
                        <input type="text" onChange={handleInputChange} className="form-control" id="company_address" name='company_address'
                               placeholder="Please write company address" value={formData?.company_address} disabled={!isEditing} />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="location" className="form-label">Location</label>
                        <input type="text" className="form-control" id="location" name='location'
                               placeholder="Please write location" value={formData?.location} disabled/>
                    </div>
                    <div style={{position: 'relative'}}>

                        <img
                            src={rectangelImage}
                            alt="Image Line"
                            className="rectangel-image"
                        />
                        <hr className="hr-style"/>
                    </div>



                    <div className="d-flex align-items-end mt-1 mb-3">
                        {/*<img src={generalInfoImage}/>*/}
                        <LuPackagePlus size={24} style={{color:colors.secondaryColor, marginRight:'10px'}}/>
                        <h5 className=" mb-1 ml-0"  style={{ fontSize: '1.3rem' }}>Package Detail</h5>
                    </div>


                    <div className='mb-3'>
                        <div className='d-flex gap-2'>
                            <div className={styles.modal_input_content}>
                                <label htmlFor="start_date" className="form-label">Start Date *</label>
                                <input type="date" className="form-control" id="start_date" name='start_date'
                                       min={getCurrentDate()}
                                       onChange={handleInputChange}
                                       placeholder="Start Date" value={formData?.start_date} />
                                <small className='text-danger'>{errors?.start_date}</small>
                            </div>
                            <div className={styles.modal_input_content}>
                                <label htmlFor="end_date" className="form-label">End Date *</label>
                                <input type="date" className="form-control" id="end_date" name='end_date'
                                       min={getCurrentDate()}
                                       onChange={handleInputChange}
                                       placeholder="End Date" value={formData?.end_date} />
                                <small className='text-danger'>{errors?.end_date}</small>
                            </div>
                        </div>
                    </div>


                    <div className='mb-3'>
                        <div className='d-flex gap-2'>
                            <div className={styles.modal_input_content}>
                                <label htmlFor="name" className="form-label">Package Name *</label>
                                <input type="text" className="form-control" id="name" name='name'
                                       onChange={handlePackageInputChange}
                                       placeholder="Sliver Package" value={formData?.package?.name} />
                                <small className='text-danger'>{errors?.package_name}</small>
                            </div>
                            <div className={styles.modal_input_content}>
                                <label htmlFor="price" className="form-label">Package Price *</label>
                                <input type="text" className="form-control" id="price" name='price'
                                       onChange={handlePackageInputChange}
                                       placeholder="1000" value={formData?.package?.price} />
                                <small className='text-danger'>{errors?.package_price}</small>
                            </div>
                        </div>
                    </div>

                    {/*<div className='mb-3'>*/}
                    {/*    <label htmlFor="feature" className="form-label">Package Feature *</label>*/}
                    {/*    <input type="text" className="form-control" id="feature" name='feature'*/}
                    {/*           onChange={handlePackageInputChange}*/}
                    {/*           placeholder="Please write feature" value={formData?.package?.feature} />*/}
                    {/*    <small className='text-danger'>{errors?.package_feature}</small>*/}
                    {/*</div>*/}

                    <div className='mb-3'>
                        <label htmlFor="description" className="form-label">Package Description *</label>
                        <input type="text" className="form-control" id="description" name='description'
                               onChange={handlePackageInputChange}
                               placeholder="Please write description" value={formData?.package?.description} />
                        <small className='text-danger'>{errors?.package_description}</small>
                    </div>

                    <div className='mb-3'>
                        <div className='d-flex gap-2'>
                            <div className={styles.modal_input_content}>
                                <label htmlFor="num_of_views" className="form-label">Number of views *</label>
                                <input type="text" onChange={handleInputChange} className="form-control" id="num_of_views" name='num_of_views'
                                       placeholder="Please write number of views" value={formData?.num_of_views} />
                                <small className='text-danger'>{errors?.num_of_views}</small>
                            </div>
                            <div className={styles.modal_input_content}>
                                <label htmlFor="num_of_jobs" className="form-label">Number of Jobs *</label>
                                <input type="text" onChange={handleInputChange} className="form-control" id="num_of_jobs" name='num_of_jobs'
                                       placeholder="Please write number of jobs" value={formData?.num_of_jobs} />
                                <small className='text-danger'>{errors?.num_of_jobs}</small>
                            </div>
                        </div>
                    </div>

                    <div className='mb-3'>
                        <div className='d-flex gap-2'>
                            <div className={styles.modal_input_content}>
                                <label htmlFor="num_of_users" className="form-label">Number of users *</label>
                                <input type="text"onChange={handleInputChange} className="form-control" id="num_of_users" name='num_of_users'
                                       placeholder="Please write number of users" value={formData?.num_of_users} />
                                <small className='text-danger'>{errors?.num_of_users}</small>
                            </div>
                            {/*<div className={styles.modal_input_content}>*/}
                            {/*    <label htmlFor="us_based" className="form-label">Located US</label>*/}
                            {/*    <input type="text" className="form-control" id="us_based" name='us_based'*/}
                            {/*           placeholder="Please write number of jobs" value={formData?.us_based} disabled/>*/}
                            {/*</div>*/}
                        </div>
                    </div>


                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-primary' onClick={handleAddSubscription}>Approve</button>
                <button className='btn btn-danger' onClick={closeSubscriptionModal}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}